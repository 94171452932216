import { useState, useMemo } from "react"

import { getYear } from "date-fns"
import { useNavigate, useParams } from "react-router-dom"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { FilterListRoundedIcon } from "@l2r-front/l2r-icons"
import { RoadDetailInfo } from "@l2r-front/l2r-networks"
import { Typography, useIsMobileDevice } from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { RoadMultiYearGrading } from "../../../roadway/containers/RoadMultiYearGrading"
import { RoadworkSectionPainterModal } from "../../components/RoadworkSectionPainterModal"
import { TECHNIQUES_FILTER, YEARS_FILTER } from "../../constants/filters"
import { useRoadworksStateContext } from "../../contexts/RoadworksContext"
import { useRoadworkCreate } from "../../hooks/mutations/roadwork/useRoadworkCreate"

import { RoadRoadworksList } from "../RoadRoadworksList"
import { TechniquesFilterList } from "../TechniquesFilterList"

import * as Styled from "./RoadworksRoadSidebarContent.styled"

export function RoadworksRoadSidebarContent(props) {

    const { className } = props

    const [displayFilters, setDisplayFilters] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)
    const isMobile = useIsMobileDevice()
    const { t } = useTranslation(I18N_NAMESPACE)
    const { road } = useParams()
    const navigate = useNavigate()
    const { project } = useRoadworksStateContext()
    const mutation = useRoadworkCreate({}, {
        errorSnackbarMessage: t(I18N_NAMESPACE, "containers.roadworkDetail.snackbar.error"),
        onSuccess: (newRoadwork) => navigate(newRoadwork?.uuid),
        successSnackbarMessage: t(I18N_NAMESPACE, "containers.roadworkDetail.snackbar.success"),
    })

    const closeFilters = () => setDisplayFilters(false)
    const toggleFilter = () => setDisplayFilters(displayFilters => !displayFilters)
    const closeModal = () => setModalOpen(false)
    const createRoadwork = (data) => {
        const newRoadwork = {
            ...data,
            project: project?.uuid,
            year: getYear(new Date()),
        }
        mutation.mutate(newRoadwork)
        closeModal()
    }

    const enabledFilters = useMemo(() => [TECHNIQUES_FILTER, YEARS_FILTER], [])

    const DesktopFilterComponent = useMemo(() => <Styled.DisplayedFiltersWrapper>
        <TechniquesFilterList enabledFilters={enabledFilters} />
        <Styled.IconButton onClick={toggleFilter} id="filters-button">
            <FilterListRoundedIcon color="cta-bg/cta-bg-primary" />
        </Styled.IconButton>
    </Styled.DisplayedFiltersWrapper>, [enabledFilters])

    return (
        <>
            {isMobile && <Styled.TechniquesFilters
                enabledFilters={enabledFilters}
                close={closeFilters}
                displayFilters={displayFilters}
                isMobile={isMobile}
            />}
            <Styled.BackdropFilter
                id="backdrop-filters"
                isMobile={isMobile}
                onClick={toggleFilter}
                open={displayFilters}
            />

            <Styled.Header isMobile={isMobile}>
                <RoadMultiYearGrading />
                <div>
                    <Typography variant="H3">
                        {t(I18N_NAMESPACE, "containers.roadworksRoadSidebarContent.location")}
                    </Typography>
                    <RoadDetailInfo road={road} />
                </div>
            </Styled.Header>
            <Styled.Container className={className} id="sidebar-content-container" isMobile={isMobile}>
                {!isMobile ? DesktopFilterComponent
                    : <Styled.MobileFilterButton enabledFilters={enabledFilters} onClick={toggleFilter} />}
                <Styled.Wrapper>
                    {!isMobile && <Styled.TechniquesFilters enabledFilters={enabledFilters} close={closeFilters} displayFilters={displayFilters} />}
                </Styled.Wrapper>
                <RoadRoadworksList openDrawModal={() => setModalOpen(true)} />
            </Styled.Container>
            <RoadworkSectionPainterModal
                open={modalOpen}
                onClose={closeModal}
                onSave={createRoadwork}
                title={t(I18N_NAMESPACE, "components.roadworkSectionPainterModal.create.title")}
                desc={t(I18N_NAMESPACE, "components.roadworkSectionPainterModal.create.desc")}
                cancelButtonLabel={t(I18N_NAMESPACE, "components.roadworkSectionPainterModal.create.cancel")}
                saveButtonLabel={t(I18N_NAMESPACE, "components.roadworkSectionPainterModal.create.save")}
            />
        </>
    )
}