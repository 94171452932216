import { useMemo } from "react"

import { PropTypes } from "@l2r-front/l2r-proptypes"

import { CatalogItemsConditionCount } from "../CatalogItemsConditionCount"

import * as Styled from "./CatalogItemsGrading.styled"

export function CatalogItemsGrading(props) {

    const {
        activeConditionFilters,
        catalogItemsGradingSettings,
        className,
        count,
        onConditionFiltersChanged,
        ...rest
    } = props

    const orderedCount = useMemo(() => {
        return Object.entries(count)
            .sort(([keyA], [keyB]) => keyB - keyA)
    }, [count])

    const totalCount = useMemo(() => Object.values(count).reduce((acc, v) => acc + v, 0), [count])
    const catalogItemsGradingBarItemsCount = Object.values(count).filter(c => c && c > 0).length
    const catalogItemsGradingBarItems = useMemo(() => {
        return orderedCount
            .filter(([_, value]) => !!value)
            .map(([dataKey, dataValue], index) => {
                const color = catalogItemsGradingSettings[dataKey].color
                const width = dataValue / totalCount * 100

                return <Styled.SignsGradingBarItem
                    color={color}
                    key={`signsGradingItem-${dataKey}`}
                    index={index}
                    width={width}
                />
            })
    }, [orderedCount, catalogItemsGradingSettings, totalCount])

    const catalogItemCounts = useMemo(() => orderedCount.map(([dataKey, dataValue]) => {
        const color = catalogItemsGradingSettings[dataKey].color
        const label = catalogItemsGradingSettings[dataKey].label

        return <CatalogItemsConditionCount
            color={color}
            count={dataValue}
            key={`catalogItemCount-${dataKey}`}
            label={label}
            onClick={() => onConditionFiltersChanged(dataKey)}
            selected={activeConditionFilters.includes(parseInt(dataKey))}
        />

    }), [activeConditionFilters, orderedCount, onConditionFiltersChanged, catalogItemsGradingSettings])

    return <div className={className} {...rest}>
        <Styled.Mask>
            <Styled.CatalogItemsGradingBarWrapper itemsCount={catalogItemsGradingBarItemsCount}>
                <Styled.EmptyBarItem />
                {catalogItemsGradingBarItems}
            </Styled.CatalogItemsGradingBarWrapper>
        </Styled.Mask>
        <Styled.CatalogItemsCountsWrapper>
            {catalogItemCounts}
        </Styled.CatalogItemsCountsWrapper>
    </div>
}

CatalogItemsGrading.defaultProps = {
    activeConditionFilters: [],
}

CatalogItemsGrading.propTypes = {
    activeConditionFilters: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.number)]),
    className: PropTypes.string,
    catalogItemsGradingSettings: PropTypes.objectOf(PropTypes.shape({
        backgroundColor: PropTypes.string,
        color: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
    })),
    count: PropTypes.objectOf(PropTypes.number),
    onConditionFiltersChanged: PropTypes.func.isRequired,
}