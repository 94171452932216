import { findTypeByCode } from "../../../utils/findTypeByCode"

import { useVerticalSignTypes } from "../queries/verticalSigning/useVerticalSignTypes"

function getCodes(types) {
    let codes = []

    types.forEach(type => {
        codes.push(type.code)
        codes = codes.concat(getCodes(type.children))
    })

    return codes
}

export function useDescendantCodes(code) {
    const { data: verticalSignTypes } = useVerticalSignTypes()

    if (verticalSignTypes) {
        const vsType = findTypeByCode(code, verticalSignTypes)

        return [code].concat(getCodes(vsType ? vsType.children : verticalSignTypes))
    }

    return []
}