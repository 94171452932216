import { useMemo, useState } from "react"

import { FilterListRoundedIcon } from "@l2r-front/l2r-icons"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { useIsMobileDevice } from "@l2r-front/l2r-ui"

import { NetworkMultiYearGrading } from "../../../roadway/containers/NetworkMultiYearGrading/NetworkMultiYearGrading"

import { NetworkTechniquesList } from "../NetworkTechniquesList"
import { TechniquesFilterList } from "../TechniquesFilterList/TechniquesFilterList"

import * as Styled from "./RoadworksSidebarContent.styled"

export function RoadworksSidebarContent(props) {

    const { className } = props

    const [displayFilters, setDisplayFilters] = useState(false)
    const isMobile = useIsMobileDevice()

    const closeFilters = () => setDisplayFilters(false)
    const toggleFilter = () => setDisplayFilters(displayFilters => !displayFilters)

    const DesktopFilterComponent = useMemo(() => <Styled.DisplayedFiltersWrapper>
        <TechniquesFilterList />
        <Styled.IconButton onClick={toggleFilter} id="filters-button">
            <FilterListRoundedIcon color="cta-bg/cta-bg-primary" />
        </Styled.IconButton>
    </Styled.DisplayedFiltersWrapper>, [])

    return (
        <>
            {isMobile && <Styled.TechniquesFilters
                close={closeFilters}
                displayFilters={displayFilters}
                isMobile={isMobile}
            />}
            <Styled.BackdropFilter
                id="backdrop-filters"
                isMobile={isMobile}
                onClick={toggleFilter}
                open={displayFilters}
            />
            <Styled.Header isMobile={isMobile}>
                <NetworkMultiYearGrading />
            </Styled.Header>
            {isMobile && <Styled.MobileFilterButton onClick={toggleFilter} />}
            <Styled.Container className={className} id="sidebar-content-container" isMobile={isMobile}>
                {!isMobile ? DesktopFilterComponent
                    : <Styled.MobileFilterButton onClick={toggleFilter} />}
                <Styled.Wrapper>
                    {!isMobile && <Styled.TechniquesFilters close={closeFilters} displayFilters={displayFilters} />}
                </Styled.Wrapper>
                <NetworkTechniquesList />
            </Styled.Container>
        </>
    )
}

RoadworksSidebarContent.propTypes = {
    className: PropTypes.string,
}
