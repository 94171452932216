import { useContext } from "react"

import { HorizontalSignDetailsEditStateContext, HorizontalSignDetailsEditDispatchContext } from "./HorizontalSignDetailsEditContext.context"

export const useHorizontalSignDetailsEditStateContext = () => {
    const context = useContext(HorizontalSignDetailsEditStateContext)
    if (context === undefined) {
        throw new Error("useHorizontalSignDetailsEditStateContext must be used within an HorizontalSignDetailsEditStateContext")
    }
    return context
}

export const useHorizontalSignDetailsEditDispatchContext = () => {
    const context = useContext(HorizontalSignDetailsEditDispatchContext)
    if (context === undefined) {
        throw new Error("useHorizontalSignDetailsEditDispatchContext must be used within an HorizontalSignDetailsEditDispatchContext")
    }
    return context
}

export const useHorizontalSigningContext = () => {
    return [useHorizontalSignDetailsEditStateContext(), useHorizontalSignDetailsEditDispatchContext()]
}
