import { PropTypes } from "@l2r-front/l2r-proptypes"

import {
    EVENT_INFOS_CONDITIONS,
    EVENT_INFOS_STATUS,
    EVENT_INFOS_TAGS,
    EVENT_INFOS_TIME,
} from "../../constants/event"

import { IncidentDetailSkeleton } from "../IncidentDetail"
import { IncidentTagsSkeleton } from "../IncidentTags"

import * as Styled from "./IncidentDetails.styled"

export function IncidentDetailsSkeleton(props) {

    const { displayedElements } = props

    return <Styled.Wrapper>
        <Styled.List>
            <Styled.ListItem>
                <Styled.TitleSkeleton variant="H1" />
            </Styled.ListItem>
            {displayedElements.includes(EVENT_INFOS_TAGS) && <IncidentTagsSkeleton tags={2} />}
            {displayedElements.includes(EVENT_INFOS_STATUS) && <IncidentDetailSkeleton detailCount={0} titleVariant="Regular" />}
            {displayedElements.includes(EVENT_INFOS_STATUS) && <IncidentDetailSkeleton detailCount={2} />}
            {displayedElements.includes(EVENT_INFOS_CONDITIONS) && <IncidentDetailSkeleton detailCount={2} />}
            {displayedElements.includes(EVENT_INFOS_TIME) && <IncidentDetailSkeleton detailCount={2} />}
        </Styled.List>
    </Styled.Wrapper>
}

IncidentDetailsSkeleton.propTypes = {
    displayedElements: PropTypes.arrayOf(PropTypes.string),
}