import { useRoadQuery } from "../../../../../common/hooks/queries/useRoadQuery"

import { damagesQueryKeys } from "./queryKeys"


export function useDamagesStats(queryParameters, config = {}) {
    const url = "damages/stats/"
    const queryKey = damagesQueryKeys["stats"](queryParameters)

    return useRoadQuery(
        url,
        queryKey,
        queryParameters,
        config,
    )
}
