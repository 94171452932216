import { useTranslation } from "@l2r-front/l2r-i18n"
import { AddIcon } from "@l2r-front/l2r-icons"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { LightTooltip } from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"

import * as Styled from "./AddRoadworkTaskButton.styled"

export function AddRoadworkTaskButton(props) {

    const {
        canAdd,
        className,
        isLocalizedTechnique,
        onClick,
    } = props

    const { t } = useTranslation(I18N_NAMESPACE)

    if (!canAdd) {
        return null
    }

    return <div className={className} id="task-add-button" role="button">
        <Styled.ButtonWrapper disabled={isLocalizedTechnique} onClick={() => !isLocalizedTechnique && onClick()}>
            <Styled.Typography variant="H3" color={isLocalizedTechnique ? "texts/text-disabled" : "texts/text-primary"}
            >
                {t(I18N_NAMESPACE, "containers.addRoadworkTaskButton.text")}
            </Styled.Typography>
            <LightTooltip
                placement="top"
                title={isLocalizedTechnique && t(I18N_NAMESPACE, "containers.addRoadworkTaskButton.tooltip")}
            >
                <Styled.IconButton color={isLocalizedTechnique ? "cta-bg/cta-bg-disabled" : "colors/vert logiroad/100"}>
                    <AddIcon color={isLocalizedTechnique ? "objects/object-disabled" : "cta-bg/cta-bg-primary"} />
                </Styled.IconButton>
            </LightTooltip>
        </Styled.ButtonWrapper>
    </div>
}

AddRoadworkTaskButton.propTypes = {
    canAdd: PropTypes.bool,
    className: PropTypes.string,
    isLocalizedTechnique: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
}