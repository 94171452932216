import React, { useCallback } from "react"

import { PropTypes } from "@l2r-front/l2r-proptypes"
import { nice } from "@l2r-front/l2r-utils"

import { STATUS_DONE } from "../../constants/status"

import * as Styled from "./RoadworksQuartersScale.styled"

const SPLIT_COUNT = 5.0

export function RoadworksQuartersScale(props) {

    const {
        budget,
        quartersCost,
        maxPercentage,
    } = props

    const ScaleTicks = useCallback(() => {
        const interval = nice(maxPercentage / SPLIT_COUNT)
        const actualSplitCount = Math.ceil(maxPercentage / interval)

        return Array.from({ length: actualSplitCount + 1 })
            .map((_, index) => {
                const tickNumber = index === actualSplitCount ? maxPercentage : Math.round(index * interval)
                const position = tickNumber / maxPercentage * 100

                return <React.Fragment key={`scale-ticks-${index}`}>
                    <Styled.Tick left={position} />
                    <Styled.TickLabel left={position} variant="Small accent">
                        {`${tickNumber}%`}
                    </Styled.TickLabel>
                </React.Fragment>
            })
    }, [maxPercentage])

    const DoneLabel = useCallback(() => {
        const totalDoneCost = quartersCost?.[STATUS_DONE].reduce((acc, value) => acc + parseFloat(value), 0)
        const percentageDone = totalDoneCost ? totalDoneCost / budget * 100 : 0
        const position = percentageDone / maxPercentage * 100

        return <>
            <Styled.DoneTick left={position} />
            <Styled.DoneLabelWrapper left={position} id="roadworks-quarters-done-label">
                <Styled.DoneText variant="H4">{`${Math.round(percentageDone)}%`}</Styled.DoneText>
            </Styled.DoneLabelWrapper>
        </>
    }, [budget, quartersCost, maxPercentage])

    return <Styled.ScaleWrapper id="roadworks-quarters-scale">
        <ScaleTicks />
        <DoneLabel />
    </Styled.ScaleWrapper>
}

RoadworksQuartersScale.propTypes = {
    maxPercentage: PropTypes.number.isRequired,
    quartersCost: PropTypes.quartersCost.isRequired,
}