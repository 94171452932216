import { createContext } from "react"

import { undefinedFunctionWarning } from "@l2r-front/l2r-utils"

import { Tokens } from "../../interfaces/authentication"

export const initialTokens: Tokens = {
    accessToken: null,
    refreshToken: null,
}

export const initialStateContext = {
    tokens: initialTokens,
}

const initialDispatchContext = {
    login: undefinedFunctionWarning("login"),
    logout: undefinedFunctionWarning("logout"),
}

export const AuthenticationStateContext = createContext(initialStateContext)
export const AuthenticationDispatchContext = createContext(initialDispatchContext)