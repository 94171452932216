import { useMemo } from "react"

import { Form, Formik } from "formik"

import { useMyUserContext } from "@l2r-front/l2r-auth"
import { useTranslation } from "@l2r-front/l2r-i18n"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { FormHelperText, useIsMobileDevice } from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"

import { validationSchema } from "./ProfileForm.schema"
import * as Styled from "./ProfileForm.styled"

export const ProfileForm = (props) => {

    const {
        className,
        onSubmit,
    } = props

    const isMobile = useIsMobileDevice()
    const { t } = useTranslation(I18N_NAMESPACE)
    const { user } = useMyUserContext()
    const formikProps = useMemo(() => {
        return {
            validationSchema: validationSchema(t),
            initialValues: {
                avatar: user?.avatar || null,
                firstName: user?.firstName || "",
                lastName: user?.lastName || "",
            },
            onSubmit: async (values, { setStatus }) => {
                try {
                    setStatus(null)
                    onSubmit(values)
                } catch (error) {
                    setStatus(t(I18N_NAMESPACE, "forms.profileForm.errors.unknown"))
                }
            },
        }
    }, [onSubmit, t, user])

    return (
        <Styled.Container isMobile={isMobile}>
            <Styled.Title variant="H2">{t(I18N_NAMESPACE, "forms.profileForm.title")}</Styled.Title>
            <Formik {...formikProps}>
                {(formikState) => {
                    return (
                        <Form className={className}>
                            {formikState.status && (
                                <FormHelperText error>{formikState.status}</FormHelperText>
                            )}
                            <Styled.FormikImageField
                                alt={`${user.firstName} ${user.lastName}`}
                                buttonLabel={t(I18N_NAMESPACE, "forms.profileForm.fields.avatar.buttonLabel")}
                                imageSource={user?.avatar}
                                name="avatar"
                                type="avatar"
                            />
                            <Styled.FormikTextField
                                autoFocus
                                fullWidth
                                variant="outlined"
                                name="firstName"
                                type="firstName"
                                label={t(I18N_NAMESPACE, "forms.profileForm.fields.firstName.label")}
                                labelVariant="H3"
                                margin="normal"
                            />
                            <Styled.FormikTextField
                                fullWidth
                                variant="outlined"
                                type="lastName"
                                name="lastName"
                                label={t(I18N_NAMESPACE, "forms.profileForm.fields.lastName.label")}
                                labelVariant="H3"
                                margin="normal"
                            />
                            <Styled.SubmitWrapper isMobile={isMobile}>
                                <Styled.FormikButtonSubmit isMobile={isMobile}>
                                    {t(I18N_NAMESPACE, "forms.profileForm.actions.submit")}
                                </Styled.FormikButtonSubmit>
                            </Styled.SubmitWrapper>
                        </Form>
                    )
                }}
            </Formik>
        </Styled.Container>
    )
}

ProfileForm.propTypes = {
    className: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
}