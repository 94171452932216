import { QueryClient } from "@tanstack/react-query"

export const client = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
            retryOnMount: false,
            staleTime: Infinity,
            gcTime: 5 * 60 * 1000,
            refetchInterval: false,
            refetchOnMount: true,
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
        },
        mutations: {
            retry: false,
        },
    },
})
