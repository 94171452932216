import { useTranslation } from "@l2r-front/l2r-i18n"
import { SidebarContent } from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"

import { RoadwaySidebarContent } from "../RoadwaySidebarContent"

export function RoadwaySidebar() {

    const { t } = useTranslation([I18N_NAMESPACE])

    return (
        <SidebarContent id="roadway-sidebar" title={t(I18N_NAMESPACE, "containers.roadwaySidebar.title")}>
            <RoadwaySidebarContent />
        </SidebarContent>
    )
}
