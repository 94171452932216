import { useMemo } from "react"

import { useMeasure } from "react-use"

import { IncidentCharts } from "../../containers/IncidentCharts"
import { IncidentVideo } from "../../containers/IncidentVideo"

import * as Styled from "./IncidentDetailsPage.styled"


export const IncidentDetailsPage = (props) => {
    const {
        ...wrapperProps
    } = props

    const [ref, { width, height }] = useMeasure()

    const elementHeight = useMemo(() => {
        return height / 2
    }, [height])

    return (
        <Styled.PageWrapper ref={ref} {...wrapperProps}>
            <IncidentVideo height={elementHeight} />
            <Styled.Wrapper height={elementHeight} width={width}>
                <IncidentCharts />
            </Styled.Wrapper>
        </Styled.PageWrapper>
    )
}

