import { useMemo } from "react"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { ListRoundedIcon, MapRoundedIcon } from "@l2r-front/l2r-icons"
import { MobileModulePage } from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { PARAM_KEY_VERTICAL_SIGNING } from "../../../../common/constants/urlParams"
import { VerticalSigningMapLayersRouterMobile } from "../../routers/VerticalSigningMapLayersRouter"

import * as Styled from "./MobileVerticalSigningPage.styled"

export function MobileVerticalSigningPage() {

    const { t } = useTranslation(I18N_NAMESPACE)

    const roadwayListTabs = useMemo(() => ([
        {
            element: <Styled.VerticalSigningMobileContent />,
            icon: <ListRoundedIcon />,
            id: "tab-notations",
            label: t(I18N_NAMESPACE, "pages.verticalSigning.tabs.signs"),
        },
        {
            element: <Styled.Map >
                <VerticalSigningMapLayersRouterMobile />
            </Styled.Map>,
            icon: <MapRoundedIcon />,
            id: "tab-map",
            label: t(I18N_NAMESPACE, "pages.verticalSigning.tabs.map"),
            fullScreen: true,
        },
    ]), [t])

    return (
        <MobileModulePage paramKey={PARAM_KEY_VERTICAL_SIGNING} tabsConfig={roadwayListTabs} />
    )
}