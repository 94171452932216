import { useNavigate } from "react-router-dom"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { NETWORK_ACCESS_NOT_AUTHORIZED, useNetworksStateContext } from "@l2r-front/l2r-networks"
import { Typography } from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../constants/i18n"

import { MessagePage } from "../MessagePage"

import * as Styled from "./LoadingNetworkPage.styled"

export const LoadingNetworkPage = () => {
    const { isError: networksError, isLoading: networksLoading, networks, networkAccessStatus } = useNetworksStateContext()
    const { t } = useTranslation(I18N_NAMESPACE)
    const navigate = useNavigate()

    if (networksLoading) {
        return <MessagePage displayAppBar>
            <Styled.Skeleton message={t(I18N_NAMESPACE, "pages.messages.loadingNetworks")} />
        </MessagePage>
    }

    if (networksError || !networks) {
        return <MessagePage displayAppBar>
            <Typography>{t(I18N_NAMESPACE, "pages.messages.loadingError")}</Typography>
        </MessagePage>
    }

    if (networkAccessStatus === NETWORK_ACCESS_NOT_AUTHORIZED) {
        return <MessagePage displayAppBar>
            <Styled.MessageWrapper>
                <Typography>{t(I18N_NAMESPACE, "pages.messages.notAuthorized")}</Typography>
                <Styled.MessageButton onClick={() => navigate("/")} variant="outlined">
                    {t(I18N_NAMESPACE, "pages.messages.button.return")}
                </Styled.MessageButton>
            </Styled.MessageWrapper>
        </MessagePage>
    }

    if (!networks.length) {
        return <MessagePage displayAppBar>
            <Typography>{t(I18N_NAMESPACE, "pages.messages.noNetwork")}</Typography>
        </MessagePage>
    }
}
