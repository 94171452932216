import { useMemo } from "react"

import { FormikToggleButtonGroup, Typography } from "@l2r-front/l2r-ui"

import { useRoadworksProject } from "../../hooks/queries/useRoadworksProjectQuery"

import * as Styled from "./RoadworkYearSelector.styled"

export const RoadworkYearSelector = (props) => {

    const { data: project, isLoading: isLoadingProject } = useRoadworksProject()

    const yearOptions = useMemo(() => {
        if (!project?.roadworksYears) {
            return []
        }

        const currentYear = (new Date()).getFullYear()
        const minYear = currentYear - 1
        const maxYear = Math.max(...project.roadworksYears)
        const options = []
        for (let year = minYear; year <= maxYear; year++) {
            options.push(
                {
                    value: year,
                    component: <Typography variant="Small">{year}</Typography>,
                },
            )
        }

        return options
    }, [project?.roadworksYears])

    if (!project?.roadworksYears || isLoadingProject) {
        return <Styled.Skeleton />
    }

    return <FormikToggleButtonGroup
        id="roadwork-year-selector"
        {...props}
        enforceValue
        exclusive
        fullWidth
        options={yearOptions}
    />
}