import { useMemo } from "react"

import { useNetworksStateContext } from "@l2r-front/l2r-networks"
import { PropTypes } from "@l2r-front/l2r-proptypes"

import { YearSelector } from "../../../../common/components/YearSelector"
import { GLOBAL_GRADE_TYPE } from "../../constants/roadInfoConfig"
import { useRoadwayDispatchContext, useRoadwayStateContext } from "../../contexts/RoadwayContext"
import { useRelevantNetworkGradings } from "../../hooks/queries/networkGradings/useRelevantNetworkGradings"

import { WeightedAverageNotationGrade } from "../WeightedAverageNotationGrade/WeightedAverageNotationGrade"

export function RoadwayYearSelector(props) {

    const {
        className,
    } = props

    const {
        selectedNetwork,
    } = useNetworksStateContext()

    const {
        isLoading,
        isError,
        sortedRelevantNetworkGradings,
    } = useRelevantNetworkGradings(selectedNetwork?.slug)

    const { selectedNetworkYearlyStatus } = useRoadwayStateContext()
    const { setSelectedNetworkYearlyStatus } = useRoadwayDispatchContext()

    const yearlyStatusOptions = useMemo(() => {
        if (!sortedRelevantNetworkGradings || isLoading || isError) {
            return []
        }
        return sortedRelevantNetworkGradings.map(networkGrading => {
            return ({
                badgeComponent: <WeightedAverageNotationGrade year={networkGrading.year} notationKey={GLOBAL_GRADE_TYPE} />,
                date: networkGrading.year,
                disabled: false,
                id: networkGrading.uuid,
                value: networkGrading,
            })
        })
    }, [sortedRelevantNetworkGradings, isLoading, isError])

    return (
        <YearSelector
            className={className}
            onClick={setSelectedNetworkYearlyStatus}
            yearlyStatuses={yearlyStatusOptions}
            selectedValue={selectedNetworkYearlyStatus}
        />
    )
}

RoadwayYearSelector.propTypes = {
    className: PropTypes.string,
}