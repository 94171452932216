import { useEffect, useMemo, useCallback } from "react"

import { useMatch, useRoutes } from "react-router-dom"

import { ImageCollectionLayer, ImageSelectedLayer, useImageCollectionDispatchContext } from "@l2r-front/l2r-images"
import { useMapDispatchContext } from "@l2r-front/l2r-map"
import { NetworkReferentialLayer, useNavigateToLinearLocation, useNetworksDispatchContext } from "@l2r-front/l2r-networks"

import { APP_ROAD } from "../../../../common/constants/appRoad"
import { MODULE_ROADWORKS } from "../../../../common/constants/modules"
import { SELECTED_ROADWORK_URL_PARAM, SELECTED_TECHNIQUE_URL_PARAM } from "../../constants/techniques"
import { RoadworkSectionLayer } from "../../containers/RoadworkSectionLayer"
import { RoadworksLayer } from "../../containers/RoadworksLayer"
import { RoadworkTechniquesLayer } from "../../containers/RoadworkTechniquesLayer"
import { useRoadworksDispatchContext } from "../../contexts/RoadworksContext"

import { RoadworkValidator } from "../RoadworkValidator/RoadworkValidator"

export function RoadworksMapLayersRouter() {

    const match = useMatch(`:slugId/${APP_ROAD}/${MODULE_ROADWORKS}/:road/*`)
    const isSegmentView = !!match
    const road = decodeURIComponent(match?.params?.road || "")
    const navigateToLinearLocation = useNavigateToLinearLocation()
    const { setSelectedSegment, setSelectedTechnique, setSelectedRoadwork } = useRoadworksDispatchContext()
    const { setSegmentFeatureIdClicked } = useImageCollectionDispatchContext()
    const { setSearchRoadCallback } = useMapDispatchContext()
    const { setRoadSearched } = useNetworksDispatchContext()

    const processNavigation = useCallback((feature, location) => {
        if (location && location.road && location.road !== road) {
            setSegmentFeatureIdClicked(feature?.properties?.uuid)
            setSelectedSegment(feature)
            setSelectedTechnique(null)
            setSelectedRoadwork(null)
            navigateToLinearLocation(location, ".", { saveMapBoundingBox: !isSegmentView, paramsToRemove: [SELECTED_TECHNIQUE_URL_PARAM, SELECTED_ROADWORK_URL_PARAM] })
        }
    }, [setSegmentFeatureIdClicked,
        setSelectedSegment,
        setSelectedTechnique,
        setSelectedRoadwork,
        navigateToLinearLocation,
        isSegmentView,
        road])

    const onReferentialFeatureClick = useCallback(feature => {
        const linearLocation = JSON.parse(feature.properties.linearLocation)
        if (linearLocation?.[0]?.road !== road) {
            setRoadSearched(null)
            processNavigation(feature, linearLocation?.[0])
        }
    }, [processNavigation, setRoadSearched, road])

    useEffect(() => {
        setSearchRoadCallback((searchedRoad) => {
            processNavigation(null, { road: searchedRoad })
        })

        return () => setSearchRoadCallback(null)
    }, [
        setSearchRoadCallback,
        processNavigation])

    const roadworksMapRoutes = useMemo(() => ([
        {
            path: "/",
            element: <>
                <NetworkReferentialLayer onClickOnFeature={onReferentialFeatureClick} />
                <RoadworkTechniquesLayer />
            </>,
        },
        {
            path: "/:road/*",
            element: <>
                <NetworkReferentialLayer onClickOnFeature={onReferentialFeatureClick} />
                <RoadworksLayer />
                <ImageCollectionLayer road={road} />
                <ImageSelectedLayer />
            </>,
        },
        {
            path: "/:road/:roadworkUuid",
            element: <RoadworkValidator>
                <NetworkReferentialLayer onClickOnFeature={onReferentialFeatureClick} />
                <RoadworkSectionLayer />
                <ImageCollectionLayer road={road} />
                <ImageSelectedLayer />
            </RoadworkValidator>,
        },
    ]), [onReferentialFeatureClick, road])

    return useRoutes(roadworksMapRoutes)
}