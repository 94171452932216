import { l2rApiAxiosInstance as axiosInstance } from "@l2r-front/l2r-query"

import { useRoadworksProject } from "../../queries/useRoadworksProjectQuery"

import { useRoadworksMutation } from "./useRoadworksMutation"
import { useOnSuccessRoadwork } from "./utils"

export function useRoadworkUpdate(
    roadwork,
    queryParameters,
    config = {},
) {
    const url = `roadworks/${roadwork?.uuid}/`
    const { data: roadworkProject } = useRoadworksProject()

    const { enabled = true } = config
    const { onSuccess } = useOnSuccessRoadwork(roadwork)

    return useRoadworksMutation(
        url,
        axiosInstance.patch,
        {
            ...queryParameters,
        },
        {
            ...config,
            enabled: roadworkProject && roadwork?.uuid && enabled,
            onSuccess,
        },
    )
}