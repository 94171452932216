import { useEffect, useMemo } from "react"

import { useDateFormatter } from "@l2r-front/l2r-i18n"

import { useImageCollectionStateContext, useImageCollectionDispatchContext } from "../../contexts/ImageCollectionContext"
import { useNearestImageInCollection } from "../../hooks/queries/imageCollection/useNearestImageInCollection"
import { usePhoto } from "../../hooks/queries/imageCollection/usePhoto"

import { PhotoViewerError } from "./PhotoViewer.error"
import * as Styled from "./PhotoViewer.styled"

export const PhotoViewer = (props) => {

    const { initialPosition, date } = props

    const dateFormatter = useDateFormatter()
    const { imageCollection, selectedImageCollectionRoad, displayedImagePosition } = useImageCollectionStateContext()
    const { setDisplayedImagePosition } = useImageCollectionDispatchContext()

    const {
        imageFeature,
        isLoading: isLoadingImageCollections,
        isError: isErrorImageCollection,
    } = useNearestImageInCollection(imageCollection, selectedImageCollectionRoad, initialPosition)

    const {
        data: photoData,
        isLoading: isLoadingPhoto,
        isError: isErrorPhoto,
    } = usePhoto(imageFeature?.properties?.photo__uuid)

    const imageUrl = useMemo(() => {
        if (imageFeature?.properties?.photo__uuid && photoData) {
            const blob = new Blob([photoData], { type: "application/octet-stream" })
            const url = URL.createObjectURL(blob)
            return url
        }
    }, [photoData, imageFeature?.properties?.photo__uuid])

    useEffect(() => {
        if (imageFeature
            && displayedImagePosition?.lat !== imageFeature.geometry.coordinates[1]
            && displayedImagePosition?.lng !== imageFeature.geometry.coordinates[0]) {
            setDisplayedImagePosition({ lat: imageFeature.geometry.coordinates[1], lng: imageFeature.geometry.coordinates[0] })
        } else if (isLoadingPhoto && !imageFeature) {
            setDisplayedImagePosition(null)
        }
    }, [imageFeature, displayedImagePosition, setDisplayedImagePosition, isLoadingPhoto])

    const isLoading = isLoadingImageCollections || (isLoadingPhoto && !!imageFeature)
    const isError = isErrorImageCollection || isErrorPhoto

    if (isLoading) {
        return <Styled.Skeleton />
    }

    if (isError || (!isLoading && !imageFeature)) {
        return <PhotoViewerError />
    }

    return <>
        <Styled.Image id="photo-viewer" src={imageUrl} />
        <Styled.DateContainer>
            {date && <Styled.DateLabel>{dateFormatter(new Date(date), "MMMM y")}</Styled.DateLabel>}
        </Styled.DateContainer>
    </>
}