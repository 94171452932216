import { useMemo } from "react"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { useNetworksStateContext } from "@l2r-front/l2r-networks"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { ColoredBadge } from "@l2r-front/l2r-ui"

import { GradeBadge } from "../../../../common/components/GradeBadge"
import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { useRelevantNetworkGradings } from "../../hooks/queries/networkGradings/useRelevantNetworkGradings"
import { getPreviousNetworkGrading } from "../../utils/networkGradingsUtils"

import * as Styled from "./WeightedAverageNotationGrade.styled"


export function WeightedAverageNotationGrade(props) {

    const {
        id,
        year,
        canSearchInPast,
        mustBeSurvey,
        notationKey,
    } = props

    const {
        selectedNetwork,
    } = useNetworksStateContext()

    const {
        isLoading,
        isError,
        sortedRelevantNetworkGradings,
    } = useRelevantNetworkGradings(selectedNetwork?.slug)

    const { t } = useTranslation(I18N_NAMESPACE)

    const grade = useMemo(() => {
        if (sortedRelevantNetworkGradings) {
            if (!canSearchInPast && !mustBeSurvey) {
                const networkGrading = sortedRelevantNetworkGradings.find(ng => ng.year === year)
                return networkGrading?.gradesWeightedAverage?.[notationKey]
            } else {
                const networkGrading = getPreviousNetworkGrading(sortedRelevantNetworkGradings, year, mustBeSurvey)
                return networkGrading?.gradesWeightedAverage?.[notationKey]
            }
        }

        return null
    }, [sortedRelevantNetworkGradings, canSearchInPast, mustBeSurvey, notationKey, year])

    if (isLoading || (!sortedRelevantNetworkGradings && !isError)) {
        return <Styled.Skeleton />
    }

    if (isError || sortedRelevantNetworkGradings === null) {
        return <ColoredBadge
            id={id}
            backgroundColor="surfaces/surface-error"
            text={t(I18N_NAMESPACE, "containers.weightedAverageNotationGrade.error")}
            textVariant="H3"
        />
    }

    return <GradeBadge id={id} grade={grade} />
}

WeightedAverageNotationGrade.defaultProps = {
    canSearchInPast: false,
    mustBeSurvey: false,
}

WeightedAverageNotationGrade.propTypes = {
    year: PropTypes.number.isRequired,
    canSearchInPast: PropTypes.bool,
    mustBeSurvey: PropTypes.bool,
    notationKey: PropTypes.string.isRequired,
}