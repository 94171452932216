import { useCallback, useMemo } from "react"

import { useDateFormatter, useTranslation } from "@l2r-front/l2r-i18n"
import { ChipList } from "@l2r-front/l2r-ui"
import { slugify } from "@l2r-front/l2r-utils"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { useSvraiStateContext } from "../../../../common/contexts/SvraiContext"
import { EVENT_INFOS_DATE } from "../../constants/event"
import { incidentDetailsSettings } from "../../constants/incidentDetailsSettings"
import { timeIcons } from "../../constants/timeIcons"
import { eventFiltersConfig } from "../../utils/eventFiltersConfig"

import { IncidentStatusIcon } from "../IncidentStatusIcon"

const MAX_DISPLAYED_FILTERS = 6

export const ActiveFiltersList = (props) => {

    const {
        activeFilters,
        setFilters,
    } = props

    const { t } = useTranslation(I18N_NAMESPACE)
    const dateFormatter = useDateFormatter()
    const { initialFilters } = useSvraiStateContext()

    const displayedFilters = useMemo(() => {
        return Object.entries(activeFilters || {})
            .map(([filterKey, filterValue]) => {
                const values = eventFiltersConfig[filterKey].getValueList(filterKey, filterValue, t, I18N_NAMESPACE, dateFormatter, initialFilters)

                return values.map(value => {
                    if (value.key === "status") {
                        return {
                            ...value,
                            icon: <IncidentStatusIcon color="main" status={value.value} id={`icon-${slugify(value.value)}`} />,
                        }
                    }
                    if (filterKey === EVENT_INFOS_DATE) {
                        const Icon = timeIcons[value.name]
                        return {
                            icon: <Icon />,
                            ...value,
                        }
                    }
                    const Icon = incidentDetailsSettings[filterKey].icon
                    return {
                        ...value,
                        icon: <Icon />,
                    }
                })
            })
            .flat()
    }, [activeFilters, dateFormatter, initialFilters, t])

    const deleteTag = useCallback((filterIndex) => {
        const filter = displayedFilters[filterIndex]
        const filterKey = filter.key
        const filterName = filter.name
        const filterValue = filterKey === EVENT_INFOS_DATE ? activeFilters[filterKey] : [...activeFilters[filterKey]]
        const newFilterValue = eventFiltersConfig[filterKey].removeValue(filterValue, filter, filterName, initialFilters, activeFilters.date)
        setFilters({
            ...activeFilters,
            [filterKey]: newFilterValue,
        })
    }, [displayedFilters, activeFilters, initialFilters, setFilters])

    return (
        displayedFilters.length > 0 ? <ChipList
            color="cta-bg/cta-bg-primary"
            onDelete={deleteTag}
            values={displayedFilters}
            maxDisplayedChips={MAX_DISPLAYED_FILTERS}
            unfoldable
            variant="outlined"
        />
            : null
    )
}