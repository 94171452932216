import { useCallback, useState } from "react"

import { AccountMenu } from "../AccountMenu"
import { MyAvatar } from "../MyAvatar"

import * as Styled from "./AvatarWithAccountMenu.styled"

export const AvatarWithAccountMenu = () => {

    const [accountMenuAnchorEl, setAccountMenuAnchorEl] = useState(null)

    const openAccountMenu = useCallback((event) => {
        setAccountMenuAnchorEl(event.currentTarget)
    }, [setAccountMenuAnchorEl])

    const closeAccountMenu = useCallback(() => {
        setAccountMenuAnchorEl(null)
    }, [setAccountMenuAnchorEl])

    return <>
        <Styled.IconButton onClick={openAccountMenu} size="small" id="avatar-button">
            <MyAvatar />
        </Styled.IconButton>
        <AccountMenu
            anchorEl={accountMenuAnchorEl}
            onClose={closeAccountMenu}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        />
    </>
}