import { PropTypes } from "@l2r-front/l2r-proptypes"
import { ColoredBadge, useTheme } from "@l2r-front/l2r-ui"

import { getLegendItemForGrade } from "../../../modules/roadway/constants/getNotationConfig"
import { getDisplayedFigures } from "../../utils/getDisplayedFigures"

export function GradeBadge(props) {

    const {
        id,
        className,
        figuresCount,
        grade,
    } = props

    const theme = useTheme()

    const displayedGrade = getDisplayedFigures(grade, figuresCount)

    if (!grade && grade !== 0) {
        return <ColoredBadge
            id={id}
            backgroundColor="objects/object-grey"
            text="-"
        />
    }

    const legendItemForGrade = getLegendItemForGrade(grade, theme)
    return <ColoredBadge
        id={id}
        backgroundColor={theme.palette[legendItemForGrade.color].main}  
        className={className}
        text={displayedGrade}
        textColor={legendItemForGrade.textColor}  
    />
}

GradeBadge.defaultProps = {
    figuresCount: 2,
}

GradeBadge.propTypes = {
    className: PropTypes.string,
    grade: PropTypes.number,
    figuresCount: PropTypes.number,
}