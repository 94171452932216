import { useMemo } from "react"

import { useGeoServerFeaturesList } from "@l2r-front/l2r-geodata"
import { Typography } from "@l2r-front/l2r-ui"
import { formatMetersToDistance } from "@l2r-front/l2r-utils"

import { useNetworksStateContext } from "../../contexts/NetworksContext"
import { networksQueryKeys } from "../../hooks"

import * as Styled from "./RoadDetailLength.styled"

export const RoadDetailLength = (props) => {

    const { road } = props

    const { selectedNetwork } = useNetworksStateContext()

    const queryParams = {
        layer: selectedNetwork.referential.layerSection,
        linear_location_road: road,
    }
    const { data: roadSegments, isLoading, isError } = useGeoServerFeaturesList(
        networksQueryKeys.list(queryParams),
        queryParams,
        {
            enabled: !!road && !!selectedNetwork.referential.layerSection,
        })

    const roadLength = useMemo(() => {
        if (!roadSegments?.length) {
            return "-"
        }

        const lengthInMeters = roadSegments.reduce((lengthAcc, segment) => {
            const segmentLength = segment.linear_locations.reduce((linearAcc, linearLocation) => {
                return linearAcc + Math.abs(linearLocation.end - linearLocation.start)
            }, 0)
            return lengthAcc + segmentLength
        }, 0)

        return formatMetersToDistance(lengthInMeters)
    }, [roadSegments])

    if (isError) {
        return null
    }

    if (isLoading) {
        return <Styled.Skeleton />
    }

    return (
        <Typography id="road-detail-length" variant="Regular">{roadLength}</Typography>
    )
}