import { useCallback, useMemo } from "react"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { StatusIcon } from "@l2r-front/l2r-icons"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { CustomSelector, FormikSelect, MenuItem, Divider } from "@l2r-front/l2r-ui"
import { slugify } from "@l2r-front/l2r-utils"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { INCIDENT_STATUS_ERROR, INCIDENT_STATUS_LIST } from "../../constants/event"
import { incidentStatusSettings } from "../../constants/incidentStatusSettings"
import { getReportValuesFromStatus } from "../../utils/statusHelpers"

import * as Styled from "./ReportStatusFilter.styled"

export const ReportStatusFilter = (props) => {

    const { t } = useTranslation([I18N_NAMESPACE])
    const {
        className,
        id,
    } = props

    const getOptionsFromList = useCallback((list) => {
        if (list.length) {
            return list.map((status, index) => {
                return <MenuItem
                    id={`status-${slugify(status.value)}`}
                    key={`status-closed-${index}`}
                    value={status.value}
                >
                    {status.name}
                </MenuItem>
            })
        }
        return null
    }, [])

    const closedStatus = useMemo(() => {
        return INCIDENT_STATUS_LIST.filter(v => INCIDENT_STATUS_ERROR !== v)
            .map(status => ({
                name: t(I18N_NAMESPACE, `${incidentStatusSettings[status].label}`),
                value: status,
            })).filter(status => {
                return getReportValuesFromStatus(status.value)?.queryParams?.report_is_closed
            })
    }, [t])

    const unclosedStatus = useMemo(() => {
        return INCIDENT_STATUS_LIST.filter(v => INCIDENT_STATUS_ERROR !== v)
            .map(status => ({
                name: t(I18N_NAMESPACE, `${incidentStatusSettings[status].label}`),
                value: status,
            })).filter(status => {
                return !getReportValuesFromStatus(status.value)?.queryParams?.report_is_closed
            })
    }, [t])

    return <FormikSelect
        id={id}
        select={CustomSelector}
        className={className}
        label={t(I18N_NAMESPACE, "containers.incidentFilters.inputs.status.label")}
        name="status"
        icon={StatusIcon}
        title={t(I18N_NAMESPACE, "containers.incidentFilters.inputs.status.label")}
        displayEmpty
    >
        <MenuItem
            key={`status-${t(I18N_NAMESPACE, "containers.incidentFilters.inputs.status.none")}`}
            value={null}
            id="status-none"
        >
            {t(I18N_NAMESPACE, "containers.incidentFilters.inputs.status.none")}
        </MenuItem>
        <Styled.ListSubHeader variant="H4">
            {t(I18N_NAMESPACE, "containers.incidentFilters.inputs.status.selectsSeparators.unclosed")}
        </Styled.ListSubHeader>
        <Divider />
        {getOptionsFromList(unclosedStatus)}
        <Styled.ListSubHeader variant="H4">
            {t(I18N_NAMESPACE, "containers.incidentFilters.inputs.status.selectsSeparators.closed")}
        </Styled.ListSubHeader>
        <Divider />
        {getOptionsFromList(closedStatus)}
    </FormikSelect>
}

ReportStatusFilter.propTypes = {
    className: PropTypes.string,
    id: PropTypes.string,
}