import { useCallback, useMemo } from "react"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { FolderIcon } from "@l2r-front/l2r-icons"
import { useNetworksContext } from "@l2r-front/l2r-networks"
import { Select } from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../constants/i18n"

import * as Styled from "./NetworkSelector.styled"

export function NetworkSelector(props) {
    const [networksState, networksDispatch] = useNetworksContext()
    const { networks, selectedNetwork } = networksState
    const { swapToNetwork } = networksDispatch
    const { t } = useTranslation(I18N_NAMESPACE)

    const changeSelectedNetworkSlug = useCallback((event) => {
        const newNetworkSlug = event.target.value
        if (newNetworkSlug !== selectedNetwork.slug) {
            swapToNetwork(newNetworkSlug)
        }
    }, [selectedNetwork, swapToNetwork])

    const networksList = useMemo(() => {
        if (!networks) {
            return null
        }

        return networks.map(network => {
            return <Styled.MenuItem key={network.slug} value={network.slug}>{network.name}</Styled.MenuItem>
        })
    }, [networks])

    return (
        <Styled.Select
            title={t(I18N_NAMESPACE, "containers.networkSelector.label")}
            value={selectedNetwork ? selectedNetwork.slug : ""}
            icon={FolderIcon}
            onChange={changeSelectedNetworkSlug}
            {...props}
        >
            {networksList}
        </Styled.Select>
    )
}

NetworkSelector.propTypes = Select.propTypes
