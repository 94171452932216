import { useRoadQuery } from "../../../../../common/hooks/queries/useRoadQuery"

import { networkConditionsQueryKeys } from "./queryKeys"

export function useNetworkConditions(project, queryParameters, config = {}) {
    const url = "network_conditions/"

    const parameters = {
        project,
        ...queryParameters,
    }
    const { enabled = true } = config
    const queryKey = networkConditionsQueryKeys["list"](parameters)

    return useRoadQuery(
        url,
        queryKey,
        parameters,
        {
            ...config,
            enabled: !!project && enabled,
        },
    )
}