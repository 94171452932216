import {
    Backdrop as MuiBackdrop,
    IconButton as UiIconButton,
    styled,
    styledForMobile,
    SIDEBAR_SPACING,
    SIDEBAR_SPACING_MOBILE,

} from "@l2r-front/l2r-ui"

import { MobileFilterButton as L2RMobileFilterButton } from "../../containers/MobileFilterButton"

import { TechniquesFilters as L2RTechniquesFilters } from "../TechniquesFilters"

export const Container = styledForMobile("div")(({ isMobile, theme }) => ({
    backgroundColor: theme.palette["surfaces/surface-variation"].main,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    overflow: "hidden",
    position: "relative",
    padding: isMobile ? theme.spacing(4, SIDEBAR_SPACING_MOBILE, 0, SIDEBAR_SPACING_MOBILE) : theme.spacing(4, SIDEBAR_SPACING, 0, SIDEBAR_SPACING),
    marginBottom: !isMobile && theme.spacing(-5),
}))

export const IconButton = styled(UiIconButton)(({ theme }) => ({
    marginLeft: "auto",
    height: "fit-content",
    zIndex: theme.zIndex.appBar + 2,
}))

export const TechniquesFilters = styledForMobile(L2RTechniquesFilters)(({ isMobile, theme }) => ({
    backgroundColor: theme.palette["surfaces/surface-primary"].main,
    boxShadow: theme.shadows[27],
    left: 0,
    marginLeft: !isMobile && theme.spacing(-8),
    marginRight: !isMobile && theme.spacing(-8),
    position: "absolute",
    right: 0,
    zIndex: theme.zIndex.appBar + 1,
}))

export const Header = styledForMobile("div")(({ theme, isMobile }) => ({
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(5),
    padding: isMobile ? theme.spacing(0, 4, 6, 4) : theme.spacing(0, 8, 6, 8),
}))

export const DisplayedFiltersWrapper = styledForMobile("div")(() => ({
    display: "flex",
}))

export const MobileFilterButton = styled(L2RMobileFilterButton)(({ theme }) => ({
    bottom: theme.spacing(6),
    position: "absolute",
    right: theme.spacing(8),
}))

export const BackdropFilter = styledForMobile(MuiBackdrop)(({ isMobile, theme }) => ({
    backgroundColor: !isMobile && "transparent",
    cursor: "pointer",
    height: "100%",
    position: "absolute",
    width: "100%",
    zIndex: theme.zIndex.mobileStepper,
}))

export const Wrapper = styled("div")({
    display: "flex",
    flexDirection: "column",
    position: "relative",
})