import i18n from "i18next"
import { initReactI18next } from "react-i18next"

const initI18n = (languageDetector, backend, options) => {
    i18n.use(languageDetector)
    if (backend) {
        i18n.use(backend)
    }
    i18n.use(initReactI18next)
        .init({
            debug: process.env.NODE_ENV === "development",
            fallbackLng: "en",
            interpolation: {
                escapeValue: false, // not needed for react as it escapes by default
            },
            partialBundledLanguages: true,
            react: {
                useSuspense: true,
            },
            ...options,
        })

    return i18n
}

export { initI18n }
