import { useMemo, useCallback } from "react"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { LocalSeeIcon, StreetViewIcon } from "@l2r-front/l2r-icons"
import { useIsMobileDevice, Typography } from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../constants/i18n"
import { IMAGE_COLLECTION_TYPE_PANO, IMAGE_COLLECTION_TYPE_STREETVIEW } from "../../constants/imageCollectionsTypes"

import * as Styled from "./ImageTypeSwitch.styled"

export function ImageTypeSwitch(props) {

    const {
        className,
        id,
        currentImageCollectionType,
        onChange,
        imageCollectionType,
    } = props

    const { t } = useTranslation(I18N_NAMESPACE)
    const isMobile = useIsMobileDevice()

    const checked = useMemo(() => {
        return !currentImageCollectionType || currentImageCollectionType === IMAGE_COLLECTION_TYPE_STREETVIEW
    }, [currentImageCollectionType])

    const handleChange = useCallback((event) => {
        if (event.target.checked !== checked) {
            onChange(event.target.checked ? IMAGE_COLLECTION_TYPE_STREETVIEW : imageCollectionType)
        }
    }, [checked, onChange, imageCollectionType])

    return (
        <Styled.Container id={id} className={className}>
            <LocalSeeIcon color="cta-bg/cta-bg-primary" />
            {!isMobile &&
                <Typography>
                    {t(I18N_NAMESPACE, (imageCollectionType === IMAGE_COLLECTION_TYPE_PANO) ? "components.imageTypeSwitch.pano" : "components.imageTypeSwitch.photo")}
                </Typography>}
            <Styled.Switch checked={checked} onChange={handleChange} />
            <StreetViewIcon />
            {!isMobile &&
                <Typography>
                    {t(I18N_NAMESPACE, "components.imageTypeSwitch.streetview")}
                </Typography>}
        </Styled.Container>
    )
}