import { useMemo } from "react"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { ListRoundedIcon, MapRoundedIcon } from "@l2r-front/l2r-icons"
import { MobileModulePage } from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { PARAM_KEY_ROADWORKS_LIST } from "../../constants/mobileParamKeys"
import { RoadworksMapLayersMobile } from "../../routers/RoadworksMapLayersRouter"

import * as Styled from "./MobileRoadworksPage.styled"

export function MobileworksPage() {

    const { t } = useTranslation(I18N_NAMESPACE)

    const roadworksListTabs = useMemo(() => ([
        {
            element: <Styled.WorksPanel />,
            icon: <ListRoundedIcon />,
            id: "tab-lists",
            label: t(I18N_NAMESPACE, "pages.works.tabs.works"),
        },
        {
            element: <Styled.Map>
                <RoadworksMapLayersMobile />
            </Styled.Map>,
            icon: <MapRoundedIcon />,
            id: "tab-map",
            label: t(I18N_NAMESPACE, "pages.works.tabs.map"),
            fullScreen: true,
        },
    ]), [t])

    return (
        <MobileModulePage paramKey={PARAM_KEY_ROADWORKS_LIST} tabsConfig={roadworksListTabs} />
    )
}