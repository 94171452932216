import { FormHelperText as MuiFormHelperText } from "@mui/material"
import { styled } from "@mui/material/styles"

import { ModeEditIcon } from "@l2r-front/l2r-icons"

import { styledForMobile } from "../../utils/styledForMobile"

import { Avatar as UiAvatar } from "../Avatar"


export const Wrapper = styled("div")({
    alignItems: "end",
    display: "flex",
    flexDirection: "row",
})

export const Avatar = styledForMobile(UiAvatar)(({ theme, isMobile }) => ({
    border: isMobile ? 0 : `4px solid ${theme.palette["surfaces/surface-primary"].main}`,
    cursor: "pointer",
    height: isMobile ? 70 : 160,
    fontSize: isMobile ? 34 : 80,
    marginRight: isMobile ? theme.spacing(8) : 0,
    width: isMobile ? 70 : 160,
}))

export const EditIcon = styled(ModeEditIcon)(({ theme }) => ({
    marginRight: theme.spacing(4),
}))

export const HiddenInput = styled("input")({
    opacity: 0,
    width: 0,
    height: 0,
})

export const FormHelperText = styled(MuiFormHelperText)(({ theme }) => ({
    marginBottom: theme.spacing(5),
}))