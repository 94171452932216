import { findTypeByCode } from "../../utils/findTypeByCode"

import { useHorizontalSignTypes } from "../queries/horizontalSigning/useHorizontalSignTypes"

function getCodes(types) {
    let codes = []

    types.forEach(type => {
        codes.push(type.code)
        codes = codes.concat(getCodes(type.children))
    })

    return codes
}

export function useDescendantCodes(code) {
    const { data: horizontalSignTypes } = useHorizontalSignTypes()

    if (horizontalSignTypes) {
        const vsType = findTypeByCode(code, horizontalSignTypes)

        return [code].concat(getCodes(vsType ? vsType.children : horizontalSignTypes))
    }

    return []
}