import { useTranslation } from "@l2r-front/l2r-i18n"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { useTheme } from "@l2r-front/l2r-ui"
import { getContrastTextColor } from "@l2r-front/l2r-utils"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"

import * as Styled from "./YearGradingBadge.styled"

export function YearGradingBadgeError(props) {

    const { className } = props
    const { t } = useTranslation(I18N_NAMESPACE)
    const theme = useTheme()
    const errorColor = theme.palette["texts/text-error"].main

    return <Styled.ErrorWrapper
        className={className}
        id="year-grading-badge-error"
    >
        <Styled.Text
            color={getContrastTextColor(errorColor)}
            variant="H4"
        >
            {t("common", "errors.error")}
        </Styled.Text>
    </Styled.ErrorWrapper>
}

YearGradingBadgeError.propTypes = {
    className: PropTypes.string,
}