import { useCallback, useEffect, useMemo, useState } from "react"

import { useLocalStorage, useVisibilityChange } from "@uidotdev/usehooks"
import decode from "jwt-decode"
import { useTimeoutFn } from "react-use"

import { PropTypes } from "@l2r-front/l2r-proptypes"

import { useRefreshTokenMutation } from "../../hooks/mutations/useRefreshTokenMutation"
import { useLogout } from "../../hooks/useLogout"

import { initialStateContext, AuthenticationStateContext, AuthenticationDispatchContext } from "./AuthenticationContext.context"

const REFRESH_TIMEDELTA = 1000 * 60 * 1 // 1 minute

export const AuthenticationContextProvider = (props) => {
    const {
        children,
    } = props

    const [localState, setLocalState] = useLocalStorage("authentication", initialStateContext)
    const [isRefreshing, setIsRefreshing] = useState(false)
    const documentVisible = useVisibilityChange()
    const logout = useLogout()

    const login = useCallback((response) => {
        const { access, refresh } = response
        setLocalState({
            accessToken: access,
            refreshToken: refresh,
        })
    }, [setLocalState])

    const {
        mutateAsync: refreshTokenMutation,
    } = useRefreshTokenMutation()

    const refresh = useCallback(async () => {
        if (!isRefreshing) {
            setIsRefreshing(true)
            try {
                const response = await refreshTokenMutation({ refresh: localState.refreshToken })
                const { access, refresh } = response
                setLocalState({
                    accessToken: access,
                    refreshToken: refresh,
                })
                setIsRefreshing(false)
            } catch {
                setIsRefreshing(false)
                logout()
            }
        }
    }, [isRefreshing, localState, refreshTokenMutation, setLocalState, logout])

    const refreshTokenExpirationTimestamp = useMemo(() => {
        if (!localState.refreshToken) {
            return 0
        }
        const { exp: expirationTimestampInSeconds } = decode(localState.refreshToken)
        return expirationTimestampInSeconds * 1000
    }, [localState])

    const accessTokenExpirationTimestamp = useMemo(() => {
        if (!localState.accessToken) {
            return 0
        }
        const { exp: expirationTimestampInSeconds } = decode(localState.accessToken)
        return expirationTimestampInSeconds * 1000
    }, [localState])

    const refreshTokenTimeoutDelay = useMemo(() => {
        if (!localState.refreshToken) {
            return Infinity
        }

        const nowTimestamp = Date.now()
        return accessTokenExpirationTimestamp - nowTimestamp - REFRESH_TIMEDELTA
    }, [accessTokenExpirationTimestamp, localState])

    const isAuthenticated = useMemo(() => {
        return !!localState.accessToken
    }, [localState.accessToken])

    const [_, cancelRefreshTimeout, resetRefreshTimeout] = useTimeoutFn(refresh, refreshTokenTimeoutDelay)

    useEffect(() => {
        if (documentVisible) {
            const nowTimestamp = Date.now()

            if (!localState.refreshToken || !localState.accessToken) {
                return
            }

            if (refreshTokenExpirationTimestamp < nowTimestamp && !isRefreshing) {
                logout()
                return
            }

            if (accessTokenExpirationTimestamp <= Date.now()) {
                refresh()
                resetRefreshTimeout()
            }
        }
    }, [documentVisible, localState, refreshTokenExpirationTimestamp, isRefreshing, accessTokenExpirationTimestamp, logout, refresh, resetRefreshTimeout])

    useEffect(() => {
        if (!localState.refreshToken) {
            cancelRefreshTimeout()
        }
    }, [cancelRefreshTimeout, localState])

    const state = useMemo(() => {
        return ({
            ...localState,
            isAuthenticated,
            isRefreshing,
        })
    }, [localState, isAuthenticated, isRefreshing])

    const dispatchValue = useMemo(() => {
        return {
            login,
            logout,
        }
    }, [login, logout])

    return <AuthenticationStateContext.Provider value={state} >
        <AuthenticationDispatchContext.Provider value={dispatchValue}>
            {children}
        </AuthenticationDispatchContext.Provider>
    </AuthenticationStateContext.Provider>
}

AuthenticationContextProvider.propTypes = {
    children: PropTypes.node,
}