import { useMemo } from "react"

import { useDateFormatter, useTranslation } from "@l2r-front/l2r-i18n"
import { FilterAltIcon } from "@l2r-front/l2r-icons"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { useSvraiStateContext } from "../../../../common/contexts/SvraiContext"
import { eventFiltersConfig } from "../../utils/eventFiltersConfig"

import * as Styled from "./MobileFilterButton.styled"

export function MobileFilterButton(props) {

    const {
        onClick,
    } = props

    const { filters, initialFilters } = useSvraiStateContext()
    const { t } = useTranslation(I18N_NAMESPACE)
    const dateFormatter = useDateFormatter()

    const filterCount = useMemo(() => {
        return Object.entries(filters || {}).map(([filterKey, filterValue]) => {
            return eventFiltersConfig[filterKey].getValueList(filterKey, filterValue, t, I18N_NAMESPACE, dateFormatter, initialFilters)

        }).flat().length
    }, [dateFormatter, filters, initialFilters, t])

    return <Styled.Wrapper>
        <Styled.Fab color="objects/object-lightgrey" size="small" onClick={onClick}>
            <FilterAltIcon color="cta-bg/cta-bg-primary" id="incidents-filters-button" />
            {filterCount ? <Styled.FilterCount id="filter-count">
                <Styled.Typography variant="Small">{filterCount}</Styled.Typography>
            </Styled.FilterCount> : null}
        </Styled.Fab>
    </Styled.Wrapper>
} 