import { useMemo } from "react"

import { useMatch } from "react-router-dom"

import { MapLegend } from "@l2r-front/l2r-map"

import { useVerticalSigningLegend } from "../../../modules/verticalSigning/hooks/useVerticalSigningLegend"
import { APP_CITY } from "../../constants/appCity"
import { MODULE_VERTICAL_SIGNING } from "../../constants/modules"


export function CityMapLegend() {

    const matchVerticalSigning = useMatch(`:slug/${APP_CITY}/${MODULE_VERTICAL_SIGNING}/*`)

    const verticalSigningLegend = useVerticalSigningLegend()

    const displayedLegend = useMemo(() => {

        if (matchVerticalSigning) {
            return verticalSigningLegend
        }

        return null

    }, [matchVerticalSigning, verticalSigningLegend])

    if (displayedLegend) {
        return <MapLegend displayedLegends={displayedLegend} />
    }

    return []
}