import { CloseIcon as Close } from "@l2r-front/l2r-icons"
import {
    Button as UiButton,
    IconButton as UiIconButton,
    CustomSelector,
    Paper,
    styledForMobile,
    styled,
    Typography,
} from "@l2r-front/l2r-ui"

const MAX_HEIGHT_DESKTOP = 450
const MAX_HEIGHT_MOBILE = 250
const MIN_WIDTH = 236
const MAX_WIDTH_DESKTOP = 350

export const Button = styledForMobile(UiButton)(({ theme }) => ({
    backgroundColor: theme.palette["surfaces/surface-primary"].main,
    borderRadius: theme.spacing(2),
    height: "auto",
    minWidth: 0,
    padding: theme.spacing(2),
    textTransform: "capitalize",

    "&:hover": {
        backgroundColor: theme.palette["objects/object-lightgrey"].main,
    },
}))

export const LegendWrapper = styledForMobile(Paper)(({ isMobile, theme }) => ({
    display: "flex",
    flexDirection: "column",
    position: "relative",
    borderRadius: theme.spacing(3),
    padding: theme.spacing(6),
    maxHeight: isMobile ? MAX_HEIGHT_MOBILE : MAX_HEIGHT_DESKTOP,
    minWidth: MIN_WIDTH,
    maxWidth: isMobile ? `calc(100% - ${theme.spacing(12)})` : MAX_WIDTH_DESKTOP,
}))

export const LegendHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: theme.spacing(9),
    marginBottom: theme.spacing(3),
}))

export const OverflowWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
    overflowY: "auto",
}))

export const Color = styled("div")(({ color, theme }) => ({
    backgroundColor: color,
    borderRadius: theme.spacing(1),
    height: 24,
    flexShrink: 0,
    width: 24,
}))

export const ItemWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: theme.spacing(6),

    "&:last-child": {
        marginBottom: 1,
    },
}))

export const Select = styled(CustomSelector)({
    margin: 0,
    width: "100%",
})

export const IconButton = styled(UiIconButton)(({theme}) => ({
    padding: 0,
    height: 24,
    width: 24,
    marginRight: theme.spacing(-2),
}))

export const CloseIcon = styled(Close)(({ theme }) => ({
    color: theme.palette["objects/object-black"].main,
    height: 16,
    width: 16,
}))

export const TitleWrapper = styled("div")({
    alignItems: "center",
    display: "flex",
    width: "100%",
})

export const LabelWrapper = styled("div")({
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
})

export const Label = styled(Typography)({
    whiteSpace: "pre",
})