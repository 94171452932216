import { useQueryClient } from "@tanstack/react-query"

import { l2rApiAxiosInstance as axiosInstance } from "@l2r-front/l2r-query"

import { horizontalSigningQueryKeys } from "../queries/horizontalSigning/queryKeys"

import { useHorizontalSigningMutation } from "./useHorizontalSigningMutation"

export function useHorizontalSignUpdate(
    signId,
    queryParameters,
    config = {},
) {
    const url = `horizontal_signs/${signId}/`
    const queryClient = useQueryClient()

    return useHorizontalSigningMutation(
        url,
        axiosInstance.patch,
        {
            ...queryParameters,
        },
        {
            ...config,
            onSuccess: (data) => {
                queryClient.invalidateQueries({ queryKey: horizontalSigningQueryKeys.feature(signId) })
                queryClient.invalidateQueries({ queryKey: horizontalSigningQueryKeys.project() })
                queryClient.invalidateQueries({ queryKey: horizontalSigningQueryKeys.layer() })
                queryClient.invalidateQueries({ queryKey: horizontalSigningQueryKeys.stats_categories() })
                queryClient.invalidateQueries({ queryKey: horizontalSigningQueryKeys.stats_conditions() })
                config.onSuccess?.(data)
            },
        },
    )
}