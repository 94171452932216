import { useMemo } from "react"

import { useMatch, useRoutes } from "react-router-dom"

import { useIsMobileDevice } from "@l2r-front/l2r-ui"

import { APP_CITY } from "../../../../common/constants/appCity"
import { MODULE_VERTICAL_SIGNING } from "../../../../common/constants/modules"
import { VerticalSigningSidebar } from "../../containers/VerticalSigningSidebar"
import { VerticalSignPoleDetailsSidebar } from "../../containers/VerticalSignPoleDetailsSidebar/VerticalSignPoleDetailsSidebar"
import { useVerticalSigningStateContext } from "../../contexts/VerticalSigningContext"

import { VerticalSignPoleValidator } from "../VerticalSignPoleValidator"

import * as Styled from "./VerticalSigningSidebarRouter.styled"

export function VerticalSigningSidebarRouter() {
    const { ascendantCodes, descendantCodes } = useVerticalSigningStateContext()
    const isMobile = useIsMobileDevice()

    const sidebarRoutes = useMemo(() => ([
        { path: "/", element: <VerticalSigningSidebar /> },
        { path: "/:road", element: <VerticalSigningSidebar /> },
        { path: "/:road/:poleId", element: <VerticalSignPoleValidator><VerticalSignPoleDetailsSidebar /></VerticalSignPoleValidator> },
    ]), [])

    const matchDetail = useMatch(`:slugId/${APP_CITY}/${MODULE_VERTICAL_SIGNING}/:road/:poleId`)

    const sidebarCount = useMemo(() => {
        const hasAdditionalSidebar = ascendantCodes.length % 2 === 1 || descendantCodes.length > 1
        return matchDetail ? 1 : Math.floor(ascendantCodes.length / 2) + (hasAdditionalSidebar ? 1 : 0)
    }, [ascendantCodes, descendantCodes, matchDetail])

    return <Styled.Sidebar shadowed={!isMobile && sidebarCount >= 2}>{useRoutes(sidebarRoutes)}</Styled.Sidebar>
}
