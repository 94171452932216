import { useCallback } from "react"

import { useHorizontalSigningDispatchContext, useHorizontalSigningStateContext } from "../../contexts/HorizontalSigningContext/HorizontalSigningContext.hooks"

import { HorizontalSignFiltersForm } from "./HorizontalSignFiltersForm"

export function HorizontalSignFiltersFormPost(props) {
    const {
        close,
        ...verticalSignFiltersFormProps
    } = props

    const { setFilters } = useHorizontalSigningDispatchContext()
    const { filters } = useHorizontalSigningStateContext()

    const onSubmit = useCallback((values) => {
        setFilters(values)
        close()
    }, [close, setFilters])

    return <HorizontalSignFiltersForm
        {...verticalSignFiltersFormProps}
        activeFilters={filters}
        close={close}
        onSubmit={onSubmit}
        resetFilters={() => setFilters({ tags: [] })}
    />
}