import LanguageDetector from "i18next-browser-languagedetector"
import HttpApi from "i18next-http-backend"

import { initI18n } from "@l2r-front/l2r-i18n"

const loadPathUrl = new URL("/assets/locales/{{lng}}/{{ns}}.json", process.env.NX_PUBLIC_FRONT_L2R)

export const i18n = initI18n(LanguageDetector, HttpApi, {
    ns: ["common", "l2r-services", "l2r-app-city", "l2r-app-road", "l2r-query", "l2r-app-svrai", "l2r-networks", "l2r-map", "l2r-ui"],
    resources: {},
    backend: {
        loadPath: decodeURI(loadPathUrl.href),
    },
    detection: {
        order: ["navigator"],
    },
})