import { useMemo } from "react"

import { PropTypes } from "@l2r-front/l2r-proptypes"

import { MapStyles } from "../../constants/mapStyles"

import * as Styled from "./StyleSelector.styled"

export const StyleSelector = (props) => {
    const {
        className,
        id,
        onMapStyleChanged,
        mapStyle,
    } = props

    const Icon = useMemo(() => {
        return mapStyle.icon
    }, [mapStyle])

    const handleClick = () => {
        const newMapStyle = selectNext(mapStyle)
        if (onMapStyleChanged) {
            onMapStyleChanged(newMapStyle)
        }
    }

    return <Styled.Button
        background={mapStyle.background}
        className={className}
        id={id}
        onClick={handleClick}
    >
        <Styled.ItemsWrapper>
            <Icon color={mapStyle.color} />
        </Styled.ItemsWrapper>
    </Styled.Button>
}

StyleSelector.propTypes = {
    className: PropTypes.string,
    id: PropTypes.string,
    onMapStyleChanged: PropTypes.func,
    mapStyle: PropTypes.object,
}

StyleSelector.defaultProps = {
    mapStyle: MapStyles.plan,
}

const selectNext = (previousValue) => {
    const previousStyleIndex = Object.values(MapStyles)
        .findIndex(value => value === previousValue)

    const stylesArray = Object.keys(MapStyles)
    const newStyleKey = stylesArray[(previousStyleIndex + 1) % stylesArray.length]

    return MapStyles[newStyleKey]
}