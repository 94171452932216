import { useMemo } from "react"

import { PropTypes } from "@l2r-front/l2r-proptypes"

import { useNetwork } from "../../hooks/queries/useNetwork"

import { ProjectStateContext } from "./ProjectContext.context"

export const ProjectContextProvider = (props) => {
    const { children } = props

    const { data: project, isLoading, isError } = useNetwork()

    const projectStartDate = useMemo(() => {
        if (!project) {
            return null
        }

        return project.firstEventDate ? new Date(project.firstEventDate) : new Date(project.createdAt)
    }, [project])

    const projectEndDate = useMemo(() => {
        if (!project) {
            return null
        }

        return project.lastEventDate ? new Date(project.lastEventDate) : new Date()
    }, [project])

    const stateValue = useMemo(() => {
        return {
            isError,
            isLoading,
            project: project,
            projectStartDate,
            projectEndDate,
            projectTags: project?.tags || [],
        }
    }, [isLoading, isError, project, projectStartDate, projectEndDate])

    return (
        <ProjectStateContext.Provider value={stateValue}>
            {children}
        </ProjectStateContext.Provider>
    )
}

ProjectContextProvider.propTypes = {
    children: PropTypes.node,
}