import { PropTypes } from "@l2r-front/l2r-proptypes"
import { Modal, useIsMobileDevice } from "@l2r-front/l2r-ui"

import { RoadworkSectionPainter } from "../../containers/RoadworkSectionPainter"

import * as Styled from "./RoadworkSectionPainterModal.styled"

export const RoadworkSectionPainterModal = (props) => {

    const {
        className,
        open,
        onClose,
        onSave,
        title,
        desc,
        cancelButtonLabel,
        saveButtonLabel,
    } = props

    const isMobile = useIsMobileDevice()

    return <Modal
        id="linear-edit-modal"
        className={className}
        open={open}
        onClose={onClose}
    >
        <Styled.Box isMobile={isMobile}>
            <Styled.Title variant="H1">{title}</Styled.Title>
            {desc && <Styled.Desc variant="H4">{desc}</Styled.Desc>}
            <Styled.IconButton id="close-button" onClick={onClose}>
                <Styled.CloseIcon />
            </Styled.IconButton>
            <RoadworkSectionPainter
                onClose={onClose}
                onSave={onSave}
                cancelButtonLabel={cancelButtonLabel}
                saveButtonLabel={saveButtonLabel} />
        </Styled.Box>
    </Modal>
}

RoadworkSectionPainterModal.propTypes = {
    className: PropTypes.string,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    desc: PropTypes.string.isRequired,
    cancelButtonLabel: PropTypes.string.isRequired,
    saveButtonLabel: PropTypes.string.isRequired,
}
