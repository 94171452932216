import { useDateFormatter } from "@l2r-front/l2r-i18n"

import { usePhotoQuery } from "../usePhotoQuery"

import { imageCollectionQueryKeys } from "./queryKeys"

export function useListImageCollections(network, year, queryParameters, config = {}) {

    const dateFormatter = useDateFormatter()
    const date = year ? new Date(year, 11, 31, 23, 59) : new Date()
    const apiFormattedDate = dateFormatter(date, "yyyy-MM-d")

    const url = "collections/"
    const parameters = {
        ...queryParameters,
        network: network,
        ...!!apiFormattedDate && { date__lte: apiFormattedDate },
    }
    const { enabled } = config
    const queryKey = imageCollectionQueryKeys["list"]({ network: network, year: year })

    return usePhotoQuery(
        url,
        queryKey,
        parameters,
        {
            ...config,
            enabled: !!network && !!apiFormattedDate && enabled,
        },
    )
}