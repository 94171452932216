import { useEffect } from "react"

import * as Sentry from "@sentry/react"

import { PropTypes } from "@l2r-front/l2r-proptypes"

import { useMyUserQuery } from "../../hooks/queries/useMyUserQuery"

import { useAuthenticationStateContext } from "../AuthenticationContext"

import { initialContext, MyUserContext } from "./MyUserContext.context"


export const MyUserContextProvider = (props) => {
    const {
        children,
    } = props

    const { isAuthenticated, isRefreshing } = useAuthenticationStateContext()
    const { data, isLoading } = useMyUserQuery({ enabled: isAuthenticated && !isRefreshing })

    const value = isAuthenticated ? { user: data, isLoading } : initialContext

    useEffect(() => {
        Sentry.setUser({ email: data?.email })
    }, [data?.email])

    return (
        <MyUserContext.Provider value={value}>
            {children}
        </MyUserContext.Provider>
    )
}

MyUserContextProvider.propTypes = {
    children: PropTypes.node,
}
