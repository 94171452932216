import { useCallback } from "react"

import { useField } from "formik"

import { useTranslation } from "@l2r-front/l2r-i18n"

import { I18N_NAMESPACE } from "../../constants/i18n"
import { MapPointEditor } from "../../containers/MapPointEditor"

import * as Styled from "./FormikMapPointEditor.styled"

const MAX_DISTANCE_FROM_ROAD = 0.04

export const FormikMapPointEditor = (props) => {

    const {
        children,
        positionFieldName,
        roadFieldName,
        ...mapPointEditorProps
    } = props

    const { t } = useTranslation(I18N_NAMESPACE)

    const [_, positionMeta, positionHelpers] = useField({name: positionFieldName})
    const { error: positionError, value: positionValue } = positionMeta
    const { setValue: setPositionValue, setError: setPositionError } = positionHelpers

    const [__, roadMeta, roadHelpers] = useField({name: roadFieldName})
    const { value: roadValue } = roadMeta
    const { setValue: setRoadValue } = roadHelpers

    const handleChange = useCallback((data) => {
        setPositionValue({
            longitude: data.longitude,
            latitude: data.latitude,
        }, false)
        setRoadValue(data.linearLocation, false)

        if (data.distance >= MAX_DISTANCE_FROM_ROAD) {
            setPositionError(t(I18N_NAMESPACE, "forms.mapPointEditor.pointTooFarError"))
        } else {
            setPositionError(undefined)
        }
    }, [setPositionError, setPositionValue, setRoadValue, t])

    return <Styled.Container>
        <MapPointEditor
            {...mapPointEditorProps}
            position={positionValue}
            road={roadValue.road}
            onMove={handleChange}
        >
            {children}
        </MapPointEditor>
        {<Styled.ErrorMessage variant="Small">{positionError ?? ""}</Styled.ErrorMessage>}
    </Styled.Container>
}