import { Typography } from "@mui/material"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { PropTypes } from "@l2r-front/l2r-proptypes"

import { I18N_NAMESPACE } from "../../constants/i18n"

import { HierarchicalListItem } from "../HierarchicalListItem"

import * as Styled from "./HierarchicalList.styled"

export const HierarchicalList = (props) => {
    const {
        className,
        data,
        title,
        onCancel,
        onClick,
        ...listProps
    } = props

    const { t } = useTranslation(I18N_NAMESPACE)

    return <Styled.Container className={className}>
        <Typography variant="H3">{title}</Typography>
        <Styled.List {...listProps}>
            {
                data.map(listItem =>
                    <HierarchicalListItem
                        {...listItem}
                        key={listItem.value}
                        onClick={onClick}
                    />)
            }
        </Styled.List>
        <Styled.ButtonsContainer>
            <Styled.CancelButton
                id="list-cancel-button"
                onClick={onCancel}
                variant="outlined"
            >
                {t(I18N_NAMESPACE, "components.hierarchicalList.cancel")}
            </Styled.CancelButton>
        </Styled.ButtonsContainer>
    </Styled.Container>
}

HierarchicalList.propTypes = {
    className: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
}

