import { useTranslation } from "@l2r-front/l2r-i18n"
import { useTheme } from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../../common/constants/i18n"

import { getHorizontalSigningLegendConfig } from "../constants/horizontalSigningConditions"

export function useHorizontalSigningLegend() {

    const { t } = useTranslation(I18N_NAMESPACE)

    const theme = useTheme()

    return [
        {
            layerName: "horizontalSigning",
            legendData: getHorizontalSigningLegendConfig(t, theme),
            title: t(I18N_NAMESPACE, "containers.horizontalSign.legend.title"),
        },
    ]
}