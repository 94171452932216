import { useCallback, useMemo, useState } from "react"

import { format } from "date-fns"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { ModeEditIcon } from "@l2r-front/l2r-icons"
import { useRoleIsReadOnly } from "@l2r-front/l2r-networks"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { useIsMobileDevice, Typography } from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { HORIZONTAL_SIGNING_CONDITIONS_VALUES } from "../../constants/horizontalSigningConditions"
import { useHorizontalSignType } from "../../hooks/queries/horizontalSigning/useHorizontalSignTypes"

import { HorizontalSignDetailsEditModal } from "../HorizontalSignDetailsEditModal"

import * as Styled from "./HorizontalSignDetails.styled"

export function HorizontalSignDetails(props) {

    const { horizontalSign } = props

    const [modalOpen, setModalOpen] = useState(false)

    const { data: signType } = useHorizontalSignType(horizontalSign.horizontal_sign_type)
    const { t } = useTranslation()
    const isMobile = useIsMobileDevice()
    const readOnly = useRoleIsReadOnly()


    const tagsChips = useMemo(() => {
        return (horizontalSign.tags || []).map(tag => ({ label: tag }))
    }, [horizontalSign])

    const closeModal = useCallback(() => {
        setModalOpen(false)
    }, [])

    const updatedInfo = useMemo(() => {
        if (!horizontalSign.updated_at) {
            return null
        }

        const date = format(new Date(horizontalSign.updated_at), "dd/MM/yyyy")
        const user = horizontalSign.updated_by ?? "Logiroad"
        return <Typography variant="caption">
            {
                t(I18N_NAMESPACE, "containers.horizontalSignDetail.updateInfo", {
                    date,
                    user,
                })
            }
        </Typography>
    }, [horizontalSign, t])

    return <>
        <Styled.Container>
            <Styled.TitleHeader>
                <Typography variant="H3">{t(I18N_NAMESPACE, "containers.horizontalSignDetail.title")}</Typography>
                {!readOnly && <Styled.FilledIconButton id="linear-edit-button" onClick={() => setModalOpen(true)}>
                    <ModeEditIcon />
                </Styled.FilledIconButton>}
            </Styled.TitleHeader>
            <Styled.TextWrapper>
                <Styled.Text isMobile={isMobile}>
                    {t(I18N_NAMESPACE, "containers.horizontalSignDetail.condition.label")}&nbsp;
                </Styled.Text>
                <Styled.StateWrapper
                    color={HORIZONTAL_SIGNING_CONDITIONS_VALUES[horizontalSign.condition].backgroundColor}
                    isMobile={isMobile}
                >
                    <Styled.State
                        color={HORIZONTAL_SIGNING_CONDITIONS_VALUES[horizontalSign.condition].color}
                        variant="H3"
                    >
                        {t(I18N_NAMESPACE, HORIZONTAL_SIGNING_CONDITIONS_VALUES[horizontalSign.condition].label)}
                    </Styled.State>
                </Styled.StateWrapper>
            </Styled.TextWrapper>
            {
                signType?.is_textual && <Styled.TextWrapper>
                    <Styled.Text isMobile={isMobile}>
                        {t(I18N_NAMESPACE, "containers.horizontalSignDetail.panelText.label")}&nbsp;
                    </Styled.Text>
                    <Styled.ContentText
                        color={horizontalSign.content ? "colors/gris/1000" : "texts/text-placeholder"}
                        isMobile={isMobile}
                    >
                        {horizontalSign.content || t(I18N_NAMESPACE, "containers.horizontalSignDetail.panelText.text")}
                    </Styled.ContentText>
                </Styled.TextWrapper>
            }
            <Styled.TextWrapper>
                <Styled.Text isMobile={isMobile}>
                    {t(I18N_NAMESPACE, "containers.horizontalSignDetail.tags")}&nbsp;
                </Styled.Text>
                {tagsChips.length ? <Styled.ChipList isMobile={isMobile} values={tagsChips} />
                    : <Styled.ContentText color={"texts/text-placeholder"} isMobile={isMobile}>-</Styled.ContentText>}
            </Styled.TextWrapper>
            {updatedInfo}
        </Styled.Container>
        <HorizontalSignDetailsEditModal
            signId={horizontalSign?.uuid}
            open={modalOpen}
            onClose={closeModal}
        />
    </>
}

HorizontalSignDetails.propTypes = {
    horizontalSign: PropTypes.horizontalSign.isRequired,
}