import { CloseIcon as Close } from "@l2r-front/l2r-icons"
import {
    Box as UiBox,
    Button,
    IconButton as UiIconButton,
    Typography,
    styled,
    styledForMobile,
} from "@l2r-front/l2r-ui"

import { HorizontalSignDetailsFormPost as L2RHorizontalSignDetailsFormPost } from "../../forms/HorizontalSignDetailsForm"

const POPUP_WIDTH = 514
const POPUP_HEIGHT = 575

export const Box = styledForMobile(UiBox)(({ theme, isMobile }) => ({
    backgroundColor: theme.palette["surfaces/surface-primary"].main,
    borderRadius: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    bottom: isMobile ? "5%" : 0,
    top: isMobile ? "5%" : 0,
    left: isMobile ? "5%" : 0,
    right: isMobile ? "5%" : 0,
    margin: "auto",
    maxHeight: !isMobile ? "90%" : "",
    minHeight: !isMobile ? "55%" : "",
    height: isMobile ? "80%" : POPUP_HEIGHT,
    width: isMobile ? "90%" : POPUP_WIDTH,
    padding: theme.spacing(8, 8, 8, 8),
    position: "absolute",
}))

export const TitleContainer = styled("div")(({ theme }) => ({
    display: "flex",
    marginBottom: theme.spacing(8),
    width: "100%",
}))

export const BackButton = styled(Button)(({ theme }) => ({
    minWidth: "unset",
    width: "fit-content",
    textTransform: "initial",
    padding: 0,
    marginRight: theme.spacing(9),
}))


export const Title = styled(Typography)(() => ({
    textAlign: "left",
}))

export const IconButton = styled(UiIconButton)(({ theme }) => ({
    padding: 0,
    position: "absolute",
    height: 24,
    right: theme.spacing(3),
    top: theme.spacing(3),
    width: 24,
}))

export const CloseIcon = styled(Close)({
    height: 16,
    width: 16,
})

export const HorizontalSignDetailsFormPost = styled(L2RHorizontalSignDetailsFormPost)({
    flex: "1 1 auto",
})