import { useCallback, useMemo, useState } from "react"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { FilterListRoundedIcon } from "@l2r-front/l2r-icons"
import { SidebarContent, useIsMobileDevice } from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { EVENT_TYPE_INCIDENT } from "../../constants/event"
import { usePaginatedEventsQuery } from "../../hooks/queries/events/useEvents"

import { IncidentsSidebarContent } from "../IncidentsSidebarContent"

import * as Styled from "./IncidentsSidebar.styled"

export function IncidentsSidebar(props) {
    const [displayFilters, setDisplayFilters] = useState(false)
    const isMobile = useIsMobileDevice()
    const { t } = useTranslation([I18N_NAMESPACE])

    const toggleFilters = useCallback(() => {
        return setDisplayFilters(previousState => !previousState)
    }, [])

    const { data } = usePaginatedEventsQuery(
        1,
        { type: EVENT_TYPE_INCIDENT },
    )

    const filterComponents = useMemo(() => {
        return (
            <Styled.TitleSideComponentWrapper>
                {Number.isInteger(data?.count) && <Styled.Count
                    id="incidents-count"
                    label={data.count}
                />}
                {!isMobile && <Styled.IconButton onClick={toggleFilters}>
                    <FilterListRoundedIcon color="cta-bg/cta-bg-primary" id="incidents-filters-button" />
                </Styled.IconButton>}
            </Styled.TitleSideComponentWrapper>
        )
    }, [isMobile, data, toggleFilters])

    return (
        <SidebarContent
            id="incidents"
            title={t(I18N_NAMESPACE, "containers.incidentListSidebar.title")}
            titleSideComponent={filterComponents}
        >
            <IncidentsSidebarContent displayFilters={displayFilters} onCloseFilters={toggleFilters} {...props} />
        </SidebarContent>

    )
}
