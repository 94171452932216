import { useCallback, useState } from "react"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { SearchIcon } from "@l2r-front/l2r-icons"
import { useMapStateContext } from "@l2r-front/l2r-map"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { useIsMobileDevice } from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../constants/i18n"
import { useNetworksContext } from "../../contexts/NetworksContext"

import * as Styled from "./RoadSearch.styled"

export function RoadSearch(props) {
    const {
        className,
        disableExpend,
        options,
    } = props

    const isMobile = useIsMobileDevice()
    const { t } = useTranslation(I18N_NAMESPACE)
    const [open, setOpen] = useState(disableExpend)
    const { searchRoadCallback } = useMapStateContext()
    const [networksState, networksDispatch] = useNetworksContext()
    const { roadSearched } = networksState
    const { setRoadSearched } = networksDispatch

    const handleValueChange = useCallback((_, value) => {
        searchRoadCallback?.(value?.label)
        setRoadSearched(value?.label)
    }, [searchRoadCallback, setRoadSearched])

    return (
        <>
            {!open && <Styled.IconButton className={className} id="search-road-button" onClick={() => setOpen(true)}>
                <SearchIcon />
            </Styled.IconButton>
            }
            {open && <Styled.Autocomplete
                className={className}
                id="search-road-select"
                isMobile={isMobile}
                blurOnSelect={true}
                selectOnFocus={false}
                options={options}
                isOptionEqualToValue={(option, value) => {
                    return option.label === value
                }}
                noOptionsText={t(I18N_NAMESPACE, "components.roadSearch.noOptions")}
                popupIcon={null}
                onChange={handleValueChange}
                value={roadSearched}
                renderInput={(params) => {
                    return (
                        <Styled.TextField
                            {...params}
                            color="border/border-primary"
                            size="small"
                            variant="outlined"
                            placeholder={t(I18N_NAMESPACE, "components.roadSearch.placeholder")}
                            InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                    <SearchIcon />
                                ),
                                endAdornment: (
                                    disableExpend ? null : <Styled.CloseIcon onClick={() => setOpen(false)} />
                                ),
                            }}
                        />
                    )
                }}
                componentsProps={{
                    popper: {
                        modifiers: [
                            {
                                name: "offset",
                                options: {
                                    offset: [0, 8],
                                },
                            },
                        ],
                    },
                    paper: {
                        elevation: 3,
                        sx: {
                            borderRadius: 3,
                        },
                    },
                }}
            />
            }
        </>
    )
}

RoadSearch.propTypes = {
    className: PropTypes.string,
    disableExpend: PropTypes.bool,
    options: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string })),
}

RoadSearch.defaultProps = {
    disableExpend: true,
}