import { useCallback, useMemo } from "react"

import { Orientation } from "@visx/axis"
import { curveLinear } from "@visx/curve"
import { Group } from "@visx/group"
import { LinePath, Bar } from "@visx/shape"

import { PropTypes } from "@l2r-front/l2r-proptypes"

import { ChartToolTip } from "../ChartToolTip"
import { ChartToolTipValueMarkers } from "../ChartToolTipValueMarkers"

import * as Styled from "./LineChart.styled"

export const LineChart = (props) => {

    const {
        axisXLabel,
        axisYLabel,
        bottomPadding,
        chartData,
        children,
        handleTooltip,
        height,
        hideTooltip,
        id,
        leftPadding,
        legend,
        tooltipData,
        tooltipLeft,
        xScaleWithZoom,
        yScale,
        yTickValues,
        width,
        zoom,
    } = props

    const colors = useMemo(() => {
        if (chartData) {
            return chartData.dataArray
                .map((value) => {
                    return value.color
                })
        }
        return null
    }, [chartData])

    const chartToolTipData = useMemo(() => {
        if (tooltipData) {
            return tooltipData[id]
        }
        return null
    }, [id, tooltipData])

    const getX = useCallback((d) => {
        return xScaleWithZoom(d.x)
    }, [xScaleWithZoom])

    const getY = useCallback((d) => {
        return yScale(d.y)
    }, [yScale])

    return <Styled.LineChartWrapper id={`${id}-chart`}>
        {(axisXLabel || axisYLabel) && <Styled.AxisWrapper
            height={height}
            width={width}
        >
            {axisXLabel && <Styled.Axis
                key="axis-values"
                label={axisYLabel}
                left={leftPadding}
                orientation={Orientation.left}
                scale={yScale}
                tickValues={yTickValues}
            />}
            {axisYLabel && <Styled.AxisBottom
                key="axis-time"
                left={leftPadding}
                label={axisXLabel}
                orientation={Orientation.bottom}
                scale={xScaleWithZoom}
                top={height - bottomPadding}
            />}
        </Styled.AxisWrapper>}
        <Styled.ChartWrapper
            height={height}
            left={leftPadding}
            width={width - leftPadding}
        >
            <Group>
                {children}
                {chartData.dataArray.map((data, i) => {
                    return <LinePath
                        curve={curveLinear}
                        data={data.data}
                        key={`chart-${i}`}
                        onMouseMove={zoom?.dragMove}
                        onMouseLeave={() => {
                            if (zoom?.isDragging) {
                                zoom?.dragEnd()
                            }
                        }}
                        onMouseUp={zoom?.dragEnd}
                        onMouseDown={zoom?.dragStart}
                        onTouchEnd={zoom?.dragEnd}
                        onTouchMove={zoom?.dragMove}
                        onTouchStart={zoom?.dragStart}
                        stroke={data.color}
                        strokeOpacity={1}
                        strokeWidth={.5}
                        x={getX}
                        y={getY}
                    />
                })}
                {handleTooltip && <Bar
                    fill="transparent"
                    height={height}
                    rx={14}
                    onMouseLeave={hideTooltip}
                    onMouseMove={(event) => handleTooltip(event, xScaleWithZoom)}
                    onTouchMove={(event) => handleTooltip(event, xScaleWithZoom)}
                    onTouchStart={(event) => handleTooltip(event, xScaleWithZoom)}
                    x={0}
                    width={width}
                />}
            </Group>
            {chartToolTipData && colors && <ChartToolTipValueMarkers
                colors={colors}
                height={height - bottomPadding}
                tooltipData={chartToolTipData}
                tooltipLeft={tooltipLeft}
            />}
        </Styled.ChartWrapper>
        {(legend || chartData.title) && <Styled.ChartLegend
            left={leftPadding + 10}
            legend={legend}
            id={`${id}-legend`}
            title={chartData.title}
            top={0}
        />}
        {
            chartToolTipData && (
                <ChartToolTip
                    bottomPadding={bottomPadding}
                    height={height}
                    leftPadding={leftPadding}
                    tooltipData={chartToolTipData}
                    tooltipLeft={tooltipLeft}
                />
            )
        }
    </Styled.LineChartWrapper>
}

LineChart.propTypes = {
    axisXLabel: PropTypes.string,
    axisYLabel: PropTypes.string,
    bottomPadding: PropTypes.number,
    chartData: PropTypes.shape({
        title: PropTypes.string,
        dataArray: PropTypes.arrayOf(PropTypes.shape({
            color: PropTypes.string.isRequired,
            data: PropTypes.arrayOf(PropTypes.shape({
                x: PropTypes.number.isRequired,
                y: PropTypes.number.isRequired,
            }).isRequired,
            ).isRequired,
        }).isRequired,
        ),
    }).isRequired,
    children: PropTypes.node,
    handleTooltip: PropTypes.func,
    height: PropTypes.number,
    hideTooltip: PropTypes.func,
    id: PropTypes.string,
    leftPadding: PropTypes.number,
    legend: PropTypes.func,
    tooltipData: PropTypes.shape({
        data: PropTypes.arrayOf(
            PropTypes.shape({
                top: PropTypes.number.isRequired,
                value: PropTypes.string.isRequired,
            }).isRequired,
        ),
        top: PropTypes.number,
        time: PropTypes.string,
    }),
    tooltipLeft: PropTypes.number,
    xScaleWithZoom: PropTypes.func.isRequired,
    yScale: PropTypes.func.isRequired,
    yTickValues: PropTypes.array,
    width: PropTypes.number,
    zoom: PropTypes.object,
}