import { useParams } from "react-router-dom"

import { PropTypes } from "@l2r-front/l2r-proptypes"
import { useIsMobileDevice } from "@l2r-front/l2r-ui"

import { TAGS_FILTER } from "../../constants/filters"

import * as Styled from "./HorizontalSignsSidebarComponents.styled"

export function HorizontalSignsSidebarComponents(props) {

    const { handleChangeTypeFilter, toggleFilter } = props

    const isMobile = useIsMobileDevice()
    const { road, code } = useParams()

    return <>
        {isMobile && <Styled.MobileFilterButton enabledFilters={[TAGS_FILTER]} onClick={toggleFilter} />}
        {road && <Styled.RoadDetailInfo isMobile={isMobile} road={road} />}
        <Styled.HorizontalSignsGrading isMobile={isMobile} />
        <Styled.HorizontalSignTypeList
            baseHorizontalSignTypeCode={code}
            isMobile={isMobile}
            onChangeFilter={handleChangeTypeFilter}
        />
    </>
}

HorizontalSignsSidebarComponents.propTypes = {
    handleChangeTypeFilter: PropTypes.func,
    toggleFilter: PropTypes.func,
}