import { useCallback, useEffect, useMemo, useState } from "react"

import _ from "lodash"
import { useMatch } from "react-router-dom"

import { useImageCollectionDispatchContext } from "@l2r-front/l2r-images"
import { useNetworksStateContext, useNetworksDispatchContext } from "@l2r-front/l2r-networks"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { useUrlParams } from "@l2r-front/l2r-utils"

import { MODULE_ROADWAY } from "../../../../common/constants/modules"
import { NETWORK_ASSESMENT_TYPE_URL_PARAM, NETWORK_GRADING_URL_PARAM, NETWORK_GRADING_PREFIX_PARAM, DAMAGES_URL_PREFIX_PARAM } from "../../../../common/constants/urlParams"
import { GLOBAL_GRADE_TYPE, GRADES_TYPES } from "../../constants/roadInfoConfig"
import { useDamagesStats } from "../../hooks/queries/damages/useDamagesStats"
import { useNetworkConditionForYearlyStatus } from "../../hooks/queries/networkConditions/useNetworkConditionForYearlyStatus"
import { useRelevantNetworkGradings } from "../../hooks/queries/networkGradings/useRelevantNetworkGradings"
import { useSurveysQuery } from "../../hooks/queries/surveys/useSurveys"

import { initialState, RoadwayDispatchContext, RoadwayStateContext } from "./RoadwayContext.context"

export const RoadwayContextProvider = (props) => {
    const { children } = props
    const [roadwayState, setRoadwayState] = useState(initialState)
    const { getParams, getParam, setParam, deleteParam } = useUrlParams()
    const searchParams = getParams()
    const matchRoadway = useMatch(`:slug/road/${MODULE_ROADWAY}/*`)
    const { setImageCollectionCurrentYear } = useImageCollectionDispatchContext()
    const { setSelectedLinearLocations } = useNetworksDispatchContext()

    const {
        selectedNetwork,
    } = useNetworksStateContext()

    const {
        data: surveys,
    } = useSurveysQuery()

    const {
        isLoading: isLoadingNetworkGradings,
        isError: isErrorNetworkGradings,
        sortedRelevantNetworkGradings: displayedYearlyStatuses,
    } = useRelevantNetworkGradings(selectedNetwork?.slug)

    const {
        isLoading: isLoadingNetworkCondition,
        isError: isErrorNetworkCondition,
        networkCondition,
    } = useNetworkConditionForYearlyStatus(roadwayState.selectedNetworkYearlyStatus)
    const damagesTypes = networkCondition?.damageTypes

    const {
        data: damagesStats,
        isLoading: isDamagesStatsLoading,
        isError: isDamagesStatsError,
    } = useDamagesStats(
        { network_condition: roadwayState?.selectedNetworkYearlyStatus?.uuid },
        { enabled: !!roadwayState?.selectedNetworkYearlyStatus?.uuid && !!roadwayState.selectedNetworkYearlyStatus?.damageTypes?.length },
    )

    const setSelectedNetworkYearlyStatus = useCallback((yearlyStatus) => {
        setRoadwayState(value => ({
            ...value,
            selectedNetworkYearlyStatus: yearlyStatus,
        }))
        setImageCollectionCurrentYear(yearlyStatus?.year)
    }, [setImageCollectionCurrentYear])

    const setSelectedNetworkAssesmentType = useCallback((type) => {
        if (GRADES_TYPES.includes(type) || (type || "").includes(DAMAGES_URL_PREFIX_PARAM)) {
            setRoadwayState(value => ({
                ...value,
                selectedNetworkAssesmentType: type,
            }))
        }
    }, [])

    const setSelectedSegment = useCallback((segment = {}) => {
        const segmentUuid = segment?.properties?.uuid || segment?.uuid || null
        setRoadwayState(value => ({
            ...value,
            selectedSegmentUuid: segmentUuid,
        }))
        setSelectedLinearLocations(segment)
    }, [setSelectedLinearLocations])

    const getSurveyForNetworkGrading = useCallback((networkGrading) => {
        if (!networkGrading || !surveys || !surveys.length) {
            return null
        }

        return surveys.find((survey) => {
            return survey.uuid === networkGrading.survey
        })
    }, [surveys])

    useEffect(() => {
        if (matchRoadway) {
            const currentNetworkAssesmentType = getParam(NETWORK_ASSESMENT_TYPE_URL_PARAM)
            if (currentNetworkAssesmentType) {
                setSelectedNetworkAssesmentType(currentNetworkAssesmentType)
            } else {
                setParam(NETWORK_ASSESMENT_TYPE_URL_PARAM, `${NETWORK_GRADING_PREFIX_PARAM}${GLOBAL_GRADE_TYPE}`)
            }
        }
    }, [matchRoadway, searchParams, getParam, setParam, setSelectedNetworkAssesmentType])

    useEffect(() => {
        if (matchRoadway && !roadwayState.selectedNetworkYearlyStatus && displayedYearlyStatuses && displayedYearlyStatuses.length) {
            const searchParamsNetworkGrading = getParam(NETWORK_GRADING_URL_PARAM)
            const displayedYearlyStatus = searchParamsNetworkGrading ? displayedYearlyStatuses.find(yearlyStatus => yearlyStatus.uuid === searchParamsNetworkGrading) : null

            if (searchParamsNetworkGrading && displayedYearlyStatus) {
                setSelectedNetworkYearlyStatus(displayedYearlyStatus)
            } else {
                const displayedYearlyStatus = displayedYearlyStatuses.findLast(yearlyStatus => (yearlyStatus.yearRelative === 0))
                setSelectedNetworkYearlyStatus(displayedYearlyStatus)
            }
        }
    }, [matchRoadway, displayedYearlyStatuses, getParam, roadwayState.selectedNetworkYearlyStatus, setSelectedNetworkYearlyStatus])

    useEffect(() => {
        if (matchRoadway) {
            const searchParamsNetworkAssesmentType = getParam(NETWORK_ASSESMENT_TYPE_URL_PARAM) || ""
            if (!GRADES_TYPES.includes(searchParamsNetworkAssesmentType) && !searchParamsNetworkAssesmentType.includes(DAMAGES_URL_PREFIX_PARAM)) {
                deleteParam(NETWORK_ASSESMENT_TYPE_URL_PARAM)
            }
            const searchParamsNetworkGrading = getParam(NETWORK_GRADING_URL_PARAM)

            if (roadwayState.selectedNetworkAssesmentType && !(isLoadingNetworkCondition || isErrorNetworkCondition)) {
                const isDamagesAssesment = roadwayState.selectedNetworkAssesmentType.startsWith(DAMAGES_URL_PREFIX_PARAM)
                const damageCode = roadwayState.selectedNetworkAssesmentType.replace(DAMAGES_URL_PREFIX_PARAM, "")
                const noDamages = !damagesTypes || !damagesTypes.length
                const damageNotInList = !noDamages && !damagesTypes.find(d => d.code === damageCode)

                if (roadwayState.selectedNetworkYearlyStatus && isDamagesAssesment && (noDamages || damageNotInList)) {
                    if (noDamages) {
                        setSelectedNetworkAssesmentType(`${NETWORK_GRADING_PREFIX_PARAM}${GLOBAL_GRADE_TYPE}`)
                    }
                } else if (searchParamsNetworkAssesmentType !== roadwayState.selectedNetworkAssesmentType) {
                    setParam(NETWORK_ASSESMENT_TYPE_URL_PARAM, roadwayState.selectedNetworkAssesmentType)
                }
            }

            if (roadwayState.selectedNetworkYearlyStatus?.uuid && searchParamsNetworkGrading !== roadwayState.selectedNetworkYearlyStatus.uuid) {
                setParam(NETWORK_GRADING_URL_PARAM, roadwayState.selectedNetworkYearlyStatus.uuid)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchRoadway, roadwayState.selectedNetworkYearlyStatus, roadwayState.selectedNetworkAssesmentType, isLoadingNetworkCondition, isErrorNetworkCondition])

    const stateValue = useMemo(() => {
        return {
            damagesStats,
            isDamagesStatsLoading,
            isDamagesStatsError,
            isErrorNetworkGradings,
            isLoadingNetworkGradings,
            surveys,
            networkGradings: displayedYearlyStatuses,
            selectedNetworkYearlyStatus: roadwayState.selectedNetworkYearlyStatus,
            selectedNetworkAssesmentType: roadwayState.selectedNetworkAssesmentType,
            selectedSegmentUuid: roadwayState.selectedSegmentUuid,
        }
    }, [damagesStats,
        displayedYearlyStatuses,
        isDamagesStatsLoading,
        isDamagesStatsError,
        isErrorNetworkGradings,
        isLoadingNetworkGradings,
        surveys,
        roadwayState.selectedNetworkAssesmentType,
        roadwayState.selectedNetworkYearlyStatus,
        roadwayState.selectedSegmentUuid])

    const dispatchValue = useMemo(() => {
        return {
            getSurveyForNetworkGrading: getSurveyForNetworkGrading,
            setSelectedNetworkAssesmentType: setSelectedNetworkAssesmentType,
            setSelectedNetworkYearlyStatus: setSelectedNetworkYearlyStatus,
            setSelectedSegment: setSelectedSegment,
        }
    }, [setSelectedNetworkYearlyStatus,
        setSelectedNetworkAssesmentType,
        getSurveyForNetworkGrading,
        setSelectedSegment])

    return (
        <RoadwayStateContext.Provider value={stateValue}>
            <RoadwayDispatchContext.Provider value={dispatchValue}>
                {children}
            </RoadwayDispatchContext.Provider>
        </RoadwayStateContext.Provider>
    )
}

RoadwayContextProvider.propTypes = {
    children: PropTypes.node,
}