import { useAuthenticatedQuery } from "@l2r-front/l2r-auth"

import { useNetworksStateContext } from "../contexts/NetworksContext"

import { networksQueryKeys } from "./queryKeys"

export function useNetworkRoads(config = {}) {
    const { selectedNetwork } = useNetworksStateContext()

    const url = `/network/networks/${selectedNetwork.slug}/roads/`
    const queryKey = networksQueryKeys.roads(selectedNetwork.slug)

    return useAuthenticatedQuery(
        url,
        queryKey,
        {},
        config,
    )
}