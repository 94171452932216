import { createContext } from "react"

export const initialState = {
    onBack: null,
    typeSelectionDisplayed: false,
}

const initialDispatch = {}

export const HorizontalSignDetailsEditStateContext = createContext(initialState)
export const HorizontalSignDetailsEditDispatchContext = createContext(initialDispatch)