import { useCallback } from "react"

import { createFilterOptions } from "@mui/material/Autocomplete"
import { useFormikContext } from "formik"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { SearchIcon } from "@l2r-front/l2r-icons"
import { PropTypes } from "@l2r-front/l2r-proptypes"

import { I18N_NAMESPACE } from "../../constants/i18n"

import * as Styled from "./TagsAutocomplete.styled"

const TAG_MAX_CHARS = 25

export const TagsAutocomplete = (props) => {
    const {
        className,
        name,
        tags,
        existingTags,
        onChange,
        variant,
    } = props

    const { t } = useTranslation(I18N_NAMESPACE)

    const { setFieldValue } = useFormikContext()

    const filter = createFilterOptions({
        trim: true,
    })

    const setValue = useCallback((fieldName, newValue) => {
        const tagsValues = newValue.map(value => {
            if (value && value.inputValue) {
                return value.inputValue
            }
            return value
        })
        setFieldValue(fieldName, tagsValues)
        onChange?.(tagsValues)
    }, [setFieldValue, onChange])

    return <Styled.Autocomplete
        id="tags-select"
        className={className}
        label={t(I18N_NAMESPACE, "containers.tagsAutocomplete.label")}
        options={existingTags}
        noOptionsText={t(I18N_NAMESPACE, "containers.tagsAutocomplete.noOption")}
        defaultValue={tags}
        name={name}
        popupIcon={<SearchIcon />}
        disableClearable
        setFieldValue={setValue}
        value={tags}
        variant={variant}
        filterSelectedOptions
        filterOptions={(options, params) => {
            const filtered = filter(options, params)

            const inputValue = params.inputValue?.trim()
            const isExistingInOptions = options.some((option) => {
                const optionValue = typeof option === "string" ? option.toLocaleLowerCase()
                    : option.inputValue?.toLocaleLowerCase()
                return inputValue.toLocaleLowerCase() === optionValue
            })
            const isExistingInTags = tags.some((tag) => {
                const tagValue = tag.toLowerCase()
                return inputValue.toLocaleLowerCase() === tagValue
            })

            if (inputValue !== "" && !isExistingInOptions && !isExistingInTags) {
                filtered.push({
                    inputValue,
                    title: `${t(I18N_NAMESPACE, "containers.tagsAutocomplete.add")} "${inputValue}"`,
                })
            }

            return filtered
        }}
        getOptionLabel={(option) => {
            if (typeof option === "string") {
                return option
            }
            return option.title
        }}
        isOptionEqualToValue={(option, value) => {
            const optionValue = typeof option === "string" ? option.toLocaleLowerCase()
                : option.inputValue?.toLocaleLowerCase()

            return value.toLocaleLowerCase() === optionValue
        }}
        maxLength={TAG_MAX_CHARS}
    />
}

TagsAutocomplete.propTypes = {
    className: PropTypes.string,
    name: PropTypes.string,
    variant: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.string),
    existingTags: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func,
}

TagsAutocomplete.defaultProps = {
    tags: [],
    existingTags: [],
    variant: "outlined",
}