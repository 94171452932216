import { useCallback } from "react"

import { PropTypes } from "@l2r-front/l2r-proptypes"

import { useSvraiDispatchContext, useSvraiStateContext } from "../../../../common/contexts/SvraiContext"
import { PAGE_SEARCH_PARAM_PREFIX } from "../../constants/filters"

import { IncidentFiltersForm } from "./IncidentFiltersForm"

export const IncidentFiltersFormPost = (props) => {

    const {
        close,
        ...incidentFilterFormProps
    } = props

    const { resetFilters, setFilters } = useSvraiDispatchContext()
    const { filters } = useSvraiStateContext()

    const onSubmit = useCallback((values) => {
        const searchParamsUrl = setFilters(values)
        searchParamsUrl.set(PAGE_SEARCH_PARAM_PREFIX, 1)
        close(searchParamsUrl)
    }, [close, setFilters])

    return <IncidentFiltersForm
        {...incidentFilterFormProps}
        activeFilters={filters}
        close={close}
        onSubmit={onSubmit}
        resetFilters={resetFilters}
    />
}

IncidentFiltersFormPost.propTypes = {
    close: PropTypes.func.isRequired,
}