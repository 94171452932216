import {
    useCallback,
    useRef,
    useState,
} from "react"

import { useBoolean } from "react-use"

import { PropTypes } from "@l2r-front/l2r-proptypes"

import { FREE_ROAM_MODE, PAINT_MODE, RUBBER_MODE } from "../../components/StabiloControls"
import { FreeRoamMode } from "../../modes/FreeRoamMode/FreeRoamMode"
import { RubberMode } from "../../modes/RubberMode/RubberMode"
import { StabiloMode } from "../../modes/StabiloMode/StabiloMode"

import { DrawControl } from "./DrawControl"

export const Draw = (props) => {
    const {
        opts,
        onFeaturesChange,
    } = props

    const [drawMode, setDrawMode] = useState(FREE_ROAM_MODE)
    const [isDirty, setIsDirty] = useBoolean(false)
    const drawRef = useRef(null)

    const handleChangeMode = useCallback((_, newMode) => {
        if (newMode && newMode !== drawMode) {
            const currentFeatures = drawRef.current.getAll().features
            setDrawMode(newMode)
            drawRef.current?.changeMode(newMode, { drawFeatures: currentFeatures, hasBeenUpdated: isDirty, ...opts })
        }
    }, [drawMode, setDrawMode, drawRef, isDirty, opts])

    const handleRestore = useCallback(() => {
        drawRef.current?.deleteAll()
        drawRef.current?.trash()
        setIsDirty(false)
        onFeaturesChange(drawRef.current.getAll())
    }, [drawRef, setIsDirty, onFeaturesChange])

    const onAdded = useCallback(() => {
        handleChangeMode(null, PAINT_MODE)
        onFeaturesChange(drawRef.current.getAll())
    }, [handleChangeMode, onFeaturesChange])

    const onUpdate = useCallback(
        () => {
            onFeaturesChange(drawRef.current.getAll())
            setIsDirty(true)
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [drawRef])

    return (
        <DrawControl
            ref={drawRef}
            position="top-left"
            displayControlsDefault={false}
            defaultMode={FREE_ROAM_MODE}
            currentMode={drawMode}
            modes={{
                [FREE_ROAM_MODE]: FreeRoamMode,
                [PAINT_MODE]: StabiloMode,
                [RUBBER_MODE]: RubberMode,
            }}
            isDirty={isDirty}
            onAdded={onAdded}
            onUpdate={onUpdate}
            onModeChange={handleChangeMode}
            onRestore={handleRestore}
        />
    )
}

Draw.propTypes = {
    opts: PropTypes.object.isRequired,
    onFeaturesChange: PropTypes.func.isRequired,
}
