import { useGeoServerFeaturesList } from "@l2r-front/l2r-geodata"

import { useNetworksStateContext } from "../contexts/NetworksContext"

import { networksQueryKeys } from "./queryKeys"


export function useNetworkReferential(queryParameters = {}, config = {}) {
    const { selectedNetwork } = useNetworksStateContext()
    const parameters = {
        ...queryParameters,
        layer: selectedNetwork.referential.layerSection,
        as_geojson: true,
        with_properties: false,
    }
    const queryKey = networksQueryKeys["referential"](selectedNetwork.slug)

    return useGeoServerFeaturesList(queryKey, parameters, config)
}
