import { useCallback } from "react"

import { useChangeTermsAccepted } from "../../hooks/mutations/auth/useChangeTermsAccepted"

import { CguForm } from "./CguForm.form"

export const CguFormPost = (props) => {

    const { className } = props
    const { mutateAsync: updateTermsAcccepted } = useChangeTermsAccepted()

    const post = useCallback((formValue) => {
        updateTermsAcccepted(formValue)
    }, [updateTermsAcccepted])

    return <CguForm post={post} className={className} />
}