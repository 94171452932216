import { getYear } from "date-fns"

import { useRoadworksQuery } from "../../../../common/hooks/queries/useRoadworksQuery"

import { roadworksQueryKeys } from "./queryKeys"

export function useRoadworksQuartersStats(
    config = {},
) {

    const currentYear = getYear(new Date())
    const queryParameters = { year: currentYear }

    const url = "roadworks/stats/quarters/"
    const queryKey = roadworksQueryKeys["quartersStats"](queryParameters)

    return useRoadworksQuery(
        url,
        queryKey,
        queryParameters,
        { ...config, enabled: !!currentYear },
    )
}