import { useSvraiQuery } from "../../../../../common/hooks/queries/useSvraiQuery"

import { eventsQueryKeys } from "./queryKeys"

export function useEventLinestringQuery(
    uuid,
    config = {},
) {
    const url = `events/${uuid}/linestring/`

    return useSvraiQuery(
        url,
        eventsQueryKeys["linestring"](uuid),
        {},
        config,
    )
}