import { useSvraiQuery } from "../../../../../common/hooks/queries/useSvraiQuery"

import { reportsQueryKeys } from "./queryKeys"

export function useReportStepQuery(
    uuid,
    queryParameters,
    config = {},
) {
    const url = `report_steps/${uuid}/`

    return useSvraiQuery(
        url,
        reportsQueryKeys["step"](uuid),
        queryParameters,
        config,
    )
}
