import { PropTypes } from "@l2r-front/l2r-proptypes"

import { useRoadworksDispatchContext } from "../../contexts/RoadworksContext/RoadworksContext.hooks"

import { TechniquesFiltersForm } from "./TechniquesFiltersForm"

export function TechniquesFiltersFormPost(props) {

    const {
        close,
        ...otherProps
    } = props

    const { applyFilters } = useRoadworksDispatchContext()

    const onSubmit = (values) => {
        const filters = {
            ...values,
            techniques: values.techniques.map(t => t.code),
        }
        applyFilters(filters)
        close()
    }

    return <TechniquesFiltersForm {...otherProps} close={close} onSubmit={onSubmit} />
}

TechniquesFiltersFormPost.propTypes = {
    close: PropTypes.func.isRequired,
}