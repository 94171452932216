import { useTranslation } from "@l2r-front/l2r-i18n"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { useChangeEmailMutation } from "../../hooks/mutations/useChangeEmailMutation"

import { ChangeEmailForm } from "./ChangeEmailForm.form"

export const ChangeEmailFormPost = () => {

    const { t } = useTranslation(I18N_NAMESPACE)
    const { mutateAsync: changeEmail } = useChangeEmailMutation({
        successSnackbarMessage: t(I18N_NAMESPACE, "forms.changeEmailForm.snackbar.success"),
        errorSnackbarMessage: t(I18N_NAMESPACE, "forms.changeEmailForm.snackbar.error"),
    })

    return <ChangeEmailForm autoFocus onSubmit={changeEmail} />
}