import { useCallback } from "react"

import { useQueryClient } from "@tanstack/react-query"
import { isEqual } from "lodash"

import { useNetworksStateContext } from "@l2r-front/l2r-networks"

import { useTaskCreate, useTaskDelete, useTaskUpdate } from "../../hooks/mutations/useTaskMutation"
import { roadworksQueryKeys } from "../../hooks/queries/queryKeys"
import { useRoadworksProject } from "../../hooks/queries/useRoadworksProjectQuery"
import { useTaskTypes } from "../../hooks/queries/useTaskType"
import { constructTechniqueAcronym, sortTasks } from "../../utils/roadworkUtils"

function mapTaskForForm(task) {
    if (!task) {
        return {}
    }

    return {
        uuid: task.uuid,
        taskType: task.taskType,
        cost: task.cost,
        quantity: task.quantity,
        unit: task.unit,
        comment: task.comment || "",
    }
}

export function mapRoadworkForForm(roadwork) {
    if (!roadwork) {
        return {}
    }

    return {
        uuid: roadwork.uuid,
        year: roadwork.year,
        technique: roadwork.technique,
        tasks: roadwork.tasks.map(task => mapTaskForForm(task)),
    }
}

export function useSaveRoadworkTasks(setValidatedState, config) {

    const { mutateAsync: createTask } = useTaskCreate(config)
    const { mutateAsync: deleteTask } = useTaskDelete(config)
    const { mutateAsync: updateTask } = useTaskUpdate(config)
    const queryClient = useQueryClient()
    const { data: roadworkProject } = useRoadworksProject()
    const { selectedNetwork } = useNetworksStateContext()
    const { data: taskTypes } = useTaskTypes()

    const saveRoadworkTasks = useCallback(async (newRoadwork, oldRoadwork) => {
        if (oldRoadwork && newRoadwork) {
            const tasksToAdd = newRoadwork.tasks.filter(newTask => {
                return !oldRoadwork.tasks.some(oldTask => newTask.uuid === oldTask.uuid)
            }).map(v => ({ ...v, roadwork: newRoadwork.uuid }))

            const tasksToDelete = oldRoadwork.tasks.filter(oldTask => {
                return !newRoadwork.tasks.some(newTask => oldTask.uuid === newTask.uuid)
            })
            const tasksToUpdate = newRoadwork.tasks.filter(newTask =>
                oldRoadwork.tasks.some(oldTask => newTask.uuid === oldTask.uuid && !isEqual(newTask, mapTaskForForm(oldTask))),
            )
            const tasksUnchanged = newRoadwork.tasks.filter(newTask =>
                oldRoadwork.tasks.some(oldTask => newTask.uuid === oldTask.uuid && isEqual(newTask, mapTaskForForm(oldTask))),
            )

            for (const taskToDelete of tasksToDelete) {
                await deleteTask(taskToDelete)
            }
            const updatedTasks = []
            for (const taskToUpdate of tasksToUpdate) {
                const updatedTask = await updateTask(taskToUpdate)
                updatedTasks.push(updatedTask)
            }
            const createdTasks = []
            for (const taskToAdd of tasksToAdd) {
                const createdTask = await createTask(taskToAdd)
                createdTasks.push(createdTask)
            }

            if (tasksToAdd.length || tasksToDelete.length || tasksToUpdate.length) {
                queryClient.invalidateQueries({ queryKey: roadworksQueryKeys["roadwork"](oldRoadwork.uuid) })
                const newRoadwork = await queryClient.fetchQuery({ queryKey: roadworksQueryKeys["roadwork"](oldRoadwork.uuid) })
                const tasks = sortTasks([...updatedTasks, ...createdTasks, ...tasksUnchanged], taskTypes)
                const data = {
                    ...newRoadwork,
                    technique: {
                        ...newRoadwork.technique,
                        acronym: constructTechniqueAcronym(newRoadwork.technique, taskTypes),
                    },
                    tasks: tasks,
                }
                setValidatedState(data)

                const roadworksQueryKeyFilter = roadworksQueryKeys.list()
                roadworksQueryKeyFilter.push({ network: selectedNetwork.slug })
                queryClient.invalidateQueries({ queryKey: roadworksQueryKeyFilter })

                const techniquesQueryKeyFilter = roadworksQueryKeys.techniques()
                techniquesQueryKeyFilter.push({ network: selectedNetwork.slug })
                queryClient.invalidateQueries({ queryKey: techniquesQueryKeyFilter })

                const roadworksLayerQueryKeyFilter = roadworksQueryKeys["layer"](roadworkProject.layer)
                queryClient.invalidateQueries({ queryKey: roadworksLayerQueryKeyFilter })

                if (oldRoadwork.feature) {
                    const roadworkFeatureQueryKeyFilter = roadworksQueryKeys.section(oldRoadwork.feature)
                    queryClient.invalidateQueries({ queryKey: roadworkFeatureQueryKeyFilter })
                }

                const roadworksYearsStatsQueryKeyFilter = roadworksQueryKeys["years_stats"]()
                queryClient.invalidateQueries({ queryKey: roadworksYearsStatsQueryKeyFilter })

                const quartersStatsQueryKey = roadworksQueryKeys["quartersStats"]()
                queryClient.invalidateQueries({ queryKey: quartersStatsQueryKey })
            }

            if (tasksToUpdate.length) {
                tasksToUpdate.forEach(task => {
                    queryClient.invalidateQueries({ queryKey: roadworksQueryKeys["task"](task.uuid) })
                })
            }
        }
    }, [createTask, deleteTask, queryClient, roadworkProject, selectedNetwork, setValidatedState, taskTypes, updateTask])

    return ({ saveRoadworkTasks })
}

export function computeTechniqueCost(roadwork) {
    const cost = roadwork.tasks.reduce((acc, task) => {
        return acc + (parseFloat(task.cost) ?? 0)
    }, 0)

    return cost.toFixed(2).toString()
}

export function computeTechniqueCode(roadwork, taskTypes) {
    const code = sortTaskCodes(roadwork?.tasks, taskTypes)
        .reduce((acc, task, index) => {
            return `${acc}${task.taskType}${index < (roadwork?.tasks?.length - 1) ? "_" : ""}`
        }, "")

    return code
}

export function computeTechniqueImpact(roadwork, taskTypes) {
    const impact = roadwork?.tasks.reduce((acc, task) => {
        const taskType = taskTypes.find(taskType => taskType.code === task.taskType)
        return Math.max(taskType?.impact ?? 0, acc)
    }, 0)

    return impact
}

function sortTaskCodes(tasks, taskTypes) {
    return tasks?.filter(task => !!task.taskType)?.sort((taskA, taskB) => {
        const taskTypeA = taskTypes?.find(taskType => taskType.code === taskA.taskType)
        const taskTypeB = taskTypes?.find(taskType => taskType.code === taskB.taskType)
        if ((typeof taskTypeA?.impact !== "number") || (typeof taskTypeB?.impact !== "number")) {
            return 0
        }
        if (taskTypeB.impact !== taskTypeA.impact) {
            return taskTypeB.impact - taskTypeA.impact
        }
        return taskA.taskType?.localeCompare(taskB.taskType)
    })
}

export function updateRoadwork(newRoadwork, taskTypes) {

    const newImpact = newRoadwork?.tasks.reduce((acc, task) => {
        const taskType = taskTypes.find(taskType => taskType.code === task.taskType)
        return Math.max(taskType.impact, acc)
    }, 10)

    const newCost = newRoadwork?.tasks.reduce((acc, task) => {
        return acc + parseFloat(task.cost)
    }, 0)

    const newAcronym = sortTaskCodes(newRoadwork?.tasks)
        .reduce((acc, task, index) => {
            return `${acc}${task.taskType}${index < (newRoadwork?.tasks?.length - 1) ? " + " : ""}`
        }, "")

    const newCode = sortTaskCodes(newRoadwork?.tasks)
        .reduce((acc, task, index) => {
            return `${acc}${task.acronym}${index < (newRoadwork?.tasks?.length - 1) ? "_" : ""}`
        }, "")

    const newRoadworkState = {
        ...newRoadwork,
        technique: {
            ...newRoadwork?.technique,
            acronym: newAcronym,
            code: newCode,
            cost: newCost,
            impact: newImpact,
        },
    }

    const newRoadworkFeatureState = {
        technique: {
            acronym: newAcronym,
            impact: newImpact,
        },
    }

    return ({
        roadwork: newRoadworkState,
        roadworkFeature: newRoadworkFeatureState,
    })
}
