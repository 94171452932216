import { useTranslation } from "@l2r-front/l2r-i18n"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"

import * as Styled from "./TechniqueItemCard.styled"

export function TechniqueItemCardError() {

    const { t } = useTranslation(I18N_NAMESPACE)

    return <Styled.Card>
        <Styled.Error variant="H3">{t(I18N_NAMESPACE, "components.techniqueCardError.error")}</Styled.Error>
    </Styled.Card>
}