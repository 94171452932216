import { useMatch, useRoutes } from "react-router-dom"

import { DetailSegmentSelectListener, SegmentSelectListener } from "@l2r-front/l2r-map-utils"
import { MapAdapterRoad, MapAdapterBoundingBox, MapAdapterFeature } from "@l2r-front/l2r-networks"

import { APP_ROAD } from "../../../../common/constants/appRoad"
import { MODULE_ROADWORKS } from "../../../../common/constants/modules"
import { MobileRoadworksDetailPage } from "../../pages/MobileRoadworksDetailPage"
import { MobileworksPage } from "../../pages/MobileRoadworksPage"
import { MobileRoadworksSectionPage } from "../../pages/MobileRoadworksSectionPage"

import { RoadworkValidator } from "../RoadworkValidator/RoadworkValidator"

export const RoadworksMobileRoutes = () => {

    const match = useMatch(`:slugId/${APP_ROAD}/${MODULE_ROADWORKS}/:road/:roadworkUuid`)

    const roadworksMobileRoutes = useRoutes([
        {
            path: "/",
            element:
                <>
                    <MapAdapterBoundingBox />
                    <SegmentSelectListener />
                    <MobileworksPage />
                </>,
        },
        {
            path: "/:road",
            element:
                <>
                    <MapAdapterBoundingBox />
                    <MobileRoadworksDetailPage />
                    <DetailSegmentSelectListener />
                    <MapAdapterRoad />
                </>,
        },
        {
            path: "/:road/:roadworkUuid/*",
            element: <RoadworkValidator>
                <MapAdapterFeature
                    featureId={match?.params?.roadworkUuid}
                />
                <MobileRoadworksSectionPage />
            </RoadworkValidator>,
        },
    ])

    return roadworksMobileRoutes
}