import { useState, useCallback } from "react"

import { useFormikContext } from "formik"

import { useTranslation, useDateFormatter } from "@l2r-front/l2r-i18n"
import { CalendarTodayIcon } from "@l2r-front/l2r-icons"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { ClickAwayListener, CustomOpenButton, useIsMobileDevice } from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { EVENT_INFOS_DATE } from "../../constants/event"

import { MonthYearFilter } from "../MonthYearFilter"

import * as Styled from "./PeriodFilter.styled"

export const PeriodFilter = (props) => {
    const {
        className,
        id,
    } = props
    const [anchorEl, setAnchorEl] = useState(null)
    const formikState = useFormikContext()
    const isMobile = useIsMobileDevice()
    const handleChangeStart = useCallback((event) => {
        formikState.setFieldValue(EVENT_INFOS_DATE, { ...formikState.values.date, periodStartDate: event })

    }, [formikState])

    const handleChangeEnd = useCallback((event) => {
        formikState.setFieldValue(EVENT_INFOS_DATE, { ...formikState.values.date, periodEndDate: event })
    }, [formikState])

    const { t } = useTranslation(I18N_NAMESPACE)
    const dateFormatter = useDateFormatter()

    const formattedPeriodStartDate = dateFormatter(formikState.values.date.periodStartDate, "MM/yy")
    const formattedPeriodEndDate = dateFormatter(formikState.values.date.periodEndDate, "MM/yy")

    const handleFilterButtonClick = useCallback((event) => {
        const newAnchor = anchorEl ? null : event.currentTarget
        setAnchorEl(newAnchor)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setAnchorEl])

    const open = Boolean(anchorEl)
    const popperId = open ? "periodfilter-popper" : undefined

    const handleClickAway = useCallback(() => {
        setAnchorEl(null)
    }, [])

    return (
        <>
            <CustomOpenButton
                className={className}
                id={id}
                title={t(I18N_NAMESPACE, "containers.periodFilter.title")}
                icon={CalendarTodayIcon}
                value={`${formattedPeriodStartDate} - ${formattedPeriodEndDate}`}
                open={open}
                onClick={handleFilterButtonClick}
            />
            <Styled.Popper
                id={popperId}
                open={open}
                anchorEl={anchorEl}>
                <ClickAwayListener onClickAway={handleClickAway}>
                    <Styled.Paper elevation={25} isMobile={isMobile}>
                        <Styled.FilterWrapper>
                            <MonthYearFilter
                                onChangeStart={handleChangeStart}
                                onChangeEnd={handleChangeEnd}
                                periodStartDate={formikState.values.date.periodStartDate}
                                periodEndDate={formikState.values.date.periodEndDate}
                            />
                        </Styled.FilterWrapper>
                    </Styled.Paper>
                </ClickAwayListener>
            </Styled.Popper>
        </>
    )
}

PeriodFilter.propTypes = {
    className: PropTypes.string,
    id: PropTypes.string,
}