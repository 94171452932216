import { getLineLayerConfig, LINE_LAYER_SIZE } from "@l2r-front/l2r-map"
import { getContrastTextColor, selectLayerColorOpacity } from "@l2r-front/l2r-utils"

export const NOTATION_SCALE = 10
const NOTATION_STEP_COUNT = 7
const NOTATION_STEP = NOTATION_SCALE / NOTATION_STEP_COUNT

export const notationLegend = [
    {
        minValue: 0 * NOTATION_STEP,
        maxValue: 1 * NOTATION_STEP,
        color: "DPE/rouge",
        label: "containers.notationLayer.legend.scale.serious",
        shortLabel: "G",
    },
    {
        minValue: 1 * NOTATION_STEP,
        maxValue: 2 * NOTATION_STEP,
        color: "DPE/orange-foncé",
        label: "containers.notationLayer.legend.scale.verypoor",
        shortLabel: "F",
    },
    {
        minValue: 2 * NOTATION_STEP,
        maxValue: 3 * NOTATION_STEP,
        color: "DPE/orange",
        label: "containers.notationLayer.legend.scale.poor",
        shortLabel: "E",
    },
    {
        minValue: 3 * NOTATION_STEP,
        maxValue: 4 * NOTATION_STEP,
        color: "DPE/jaune",
        label: "containers.notationLayer.legend.scale.fair",
        shortLabel: "D",
    },
    {
        minValue: 4 * NOTATION_STEP,
        maxValue: 5 * NOTATION_STEP,
        color: "DPE/vert-clair",
        label: "containers.notationLayer.legend.scale.satisfactory",
        shortLabel: "C",
    },
    {
        minValue: 5 * NOTATION_STEP,
        maxValue: 6 * NOTATION_STEP,
        color: "DPE/vert",
        label: "containers.notationLayer.legend.scale.good",
        shortLabel: "B",
    },
    {
        minValue: 6 * NOTATION_STEP,
        maxValue: 7 * NOTATION_STEP,
        color: "DPE/vert-foncé",
        label: "containers.notationLayer.legend.scale.excellent",
        shortLabel: "A",
    },
]

export const getNotationLegendConfig = (t, theme) => {
    return notationLegend.map(legendItem => {
        return {
            color: theme.palette[legendItem.color]?.main,
            label: t(legendItem.label),
        }
    })
        .reverse()
}

export const getNotationLayerStyle = (gradeType, referentialColor, theme, selectedRoad = null) => {
    const legendStyles = notationLegend.flatMap((legendItem) => {
        const color = theme.palette[legendItem.color].main
        return [
            ["all", [">=", ["get", `${gradeType}`], legendItem.minValue], ["<", ["get", `${gradeType}`], legendItem.maxValue]],
            selectLayerColorOpacity(color, 0.5, selectedRoad),
        ]
    })
    const defaultColor = theme.palette[notationLegend[notationLegend.length - 1].color].main
    legendStyles.push(selectLayerColorOpacity(defaultColor, 0.5, selectedRoad))
    return getLineLayerConfig({
        "paint": {
            "line-color": [
                "case",
                ["!=", ["typeof", ["get", `${gradeType}`]], "number"],
                selectLayerColorOpacity(referentialColor, referentialColor === "#FFFFFF" ? 0.85 : 0.5, selectedRoad),
            ].concat(legendStyles),
            "line-width": LINE_LAYER_SIZE,
        },
    })
}

export const getLegendItemForGrade = (grade, theme) => {
    const legendItem = notationLegend.find((item, index) => {
        return ((index === notationLegend.length - 1) && (item.minValue <= grade) && (item.maxValue >= grade))
            || ((item.minValue <= grade) && (item.maxValue > grade))
    })
    if (legendItem) {
        return (
            {
                ...legendItem,
                textColor: getContrastTextColor(theme.palette[legendItem.color].main),
            }
        )
    }
    return null
}

export const getLegendIndexFromGrade = (grade) => {
    return notationLegend.findIndex((item, index) => {
        return ((index === notationLegend.length - 1) && (item.minValue <= grade) && (item.maxValue >= grade))
            || ((item.minValue <= grade) && (item.maxValue > grade))
    })
}