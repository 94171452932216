import { useCallback, useMemo } from "react"

import { useNavigate } from "react-router-dom"

import { useAuthenticationDispatchContext } from "@l2r-front/l2r-auth"
import { useTranslation } from "@l2r-front/l2r-i18n"
import { useUrlParams } from "@l2r-front/l2r-utils"

import { HeaderWithLogo } from "../../components/HeaderWithLogo"
import { I18N_NAMESPACE } from "../../constants/i18n"

import * as Styled from "./LoginPage.styled"

export const LoginPage = () => {
    const { login } = useAuthenticationDispatchContext()
    const navigate = useNavigate()
    const { getParams } = useUrlParams()

    const { t } = useTranslation([I18N_NAMESPACE])

    const handleLoginSuccess = useCallback((response) => {
        login(response)
        const searchParams = getParams()
        const redirectParams = Array.from(searchParams.entries())
            .filter(([key, _]) => key !== "redirect")
            .reduce((acc, [key, value]) => {
                return `${acc}&${key}=${value}`
            }, "")
        const redirectURL = searchParams.get("redirect")
        if (redirectURL) {
            navigate(`${redirectURL}${redirectParams}`)
            return
        }
        navigate("/")
    }, [getParams, login, navigate])

    const LinkToPassword = useMemo(() => {
        return (
            <Styled.LinksWrapper
                href={"/recover_password"}
                variant="text"
                id="link-to-recover_password">
                {t(I18N_NAMESPACE, "pages.loginPage.forgottenPassword")}
            </Styled.LinksWrapper>
        )

    }, [t])

    return (
        <Styled.PageWrapper>
            <HeaderWithLogo>
                <Styled.LoginForm onSuccess={handleLoginSuccess} linkToRecoverPassword={LinkToPassword} />
            </HeaderWithLogo>
        </Styled.PageWrapper>
    )
}
