import { useMemo } from "react"

import { Formik } from "formik"
import { useNavigate } from "react-router-dom"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { PropTypes, allowEmptyString } from "@l2r-front/l2r-proptypes"
import { CTAButton } from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../constants/i18n"

import { validationSchema } from "./RecoverPasswordForm.schema"
import * as Styled from "./RecoverPasswordForm.styled"

export const RecoverPasswordForm = (props) => {
    const {
        initialValues,
        onSubmit,
        className,
    } = props

    const { t } = useTranslation([I18N_NAMESPACE])
    const navigate = useNavigate()

    const formikProps = useMemo(() => {
        return {
            validationSchema: validationSchema(t),
            initialValues: initialValues,
            onSubmit: async (values, { setStatus }) => {
                try {
                    await onSubmit(values)
                } catch (error) {
                    setStatus(t(I18N_NAMESPACE, "forms.recoverPasswordForm.errors.unknown"))
                }
            },
        }
    }, [initialValues, onSubmit, t])

    return (
        <Formik {...formikProps}>
            {(formikState) => (
                <Styled.Form className={className}>
                    {formikState.status && (
                        <Styled.FormHelperText error id="submit-error">{formikState.status}</Styled.FormHelperText>
                    )}

                    <Styled.FormikTextField
                        autoFocus
                        id="email"
                        fullWidth
                        name="email"
                        type="email"
                        label={t(I18N_NAMESPACE, "forms.recoverPasswordForm.fields.email.label")}
                    />
                    <Styled.ButtonsWrapper>
                        <CTAButton onClick={() => navigate("/")} variant="outlined">
                            {t(I18N_NAMESPACE, "forms.recoverPasswordForm.actions.return")}
                        </CTAButton>
                        <Styled.FormikButtonSubmit>{t(I18N_NAMESPACE, "forms.recoverPasswordForm.actions.sendRecoverLink")}</Styled.FormikButtonSubmit>
                    </Styled.ButtonsWrapper>
                </Styled.Form>
            )}
        </Formik>
    )
}

RecoverPasswordForm.defaultProps = {
    initialValues: {
        email: "",
    },
}

RecoverPasswordForm.propTypes = {
    initialValues: PropTypes.exact({
        email: allowEmptyString(PropTypes.email.isRequired),
    }),
    onSubmit: PropTypes.func.isRequired,
    className: PropTypes.string,
}