import { useMemo } from "react"

import { Formik, Form } from "formik"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { SearchIcon } from "@l2r-front/l2r-icons"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { useIsMobileDevice } from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { FILTERS, STATUSES_FILTER, TECHNIQUES_FILTER, YEARS_FILTER } from "../../constants/filters"
import { statusesSettings, STATUSES } from "../../constants/status"
import { useRoadworksDispatchContext, useRoadworksStateContext } from "../../contexts/RoadworksContext/RoadworksContext.hooks"
import { roadworksFiltersConfig } from "../../utils/roadworksFiltersConfig"

import * as Styled from "./TechniquesFiltersForm.styled"

const defaultFormValues = {
    [YEARS_FILTER]: [],
    [TECHNIQUES_FILTER]: [],
    [STATUSES_FILTER]: [],
}

export function TechniquesFiltersForm(props) {

    const { t } = useTranslation(I18N_NAMESPACE)
    const { filters } = useRoadworksStateContext()
    const { applyFilters } = useRoadworksDispatchContext()
    const isMobile = useIsMobileDevice()

    const {
        enabledFilters,
        className,
        close,
        onSubmit,
        years,
        techniques,
    } = props

    const initialTechniques = useMemo(() => {
        if (techniques) {
            return (filters.techniques || []).map(technique => {
                const acronym = techniques.find(t => t.code === technique)?.acronym
                return { code: technique, acronym: acronym }
            })
        } else {
            return []
        }
    }, [filters, techniques])

    const initialValues = useMemo(() => {
        return ({
            years: filters?.years || [],
            statuses: filters?.statuses || [],
            techniques: initialTechniques || [],
        })

    }, [filters, initialTechniques])

    return <Formik
        className={className}
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={onSubmit}
    >
        {({ resetForm, setFieldValue }) => <Form>
            <Styled.FormWrapper>
                <Styled.InputsWrapper>
                    {
                        enabledFilters.includes(YEARS_FILTER) && years && <Styled.AutoCompleteFilter
                            defaultValue={initialValues.years}
                            disableClearable
                            getOptionLabel={option => option.toString()}
                            id="year-filters-select"
                            label={t(I18N_NAMESPACE, roadworksFiltersConfig[YEARS_FILTER].label)}
                            limitTags={2}
                            name={YEARS_FILTER}
                            options={years}
                            popupIcon={<SearchIcon />}
                            setFieldValue={setFieldValue} />
                    }
                    {
                        enabledFilters.includes(STATUSES_FILTER) && <Styled.AutoCompleteFilter
                            defaultValue={initialValues.statuses}
                            disableClearable
                            getOptionLabel={value => t(I18N_NAMESPACE, statusesSettings[value].label)}
                            id="status-filters-select"
                            label={t(I18N_NAMESPACE, roadworksFiltersConfig[STATUSES_FILTER].label)}
                            limitTags={2}
                            name={STATUSES_FILTER}
                            options={STATUSES}
                            popupIcon={<SearchIcon />}
                            setFieldValue={setFieldValue} />
                    }
                    {
                        enabledFilters.includes(TECHNIQUES_FILTER) && techniques && <Styled.AutoCompleteFilter
                            defaultValue={initialValues.techniques}
                            disableClearable
                            getOptionLabel={option => option.acronym}
                            id="technique-filters-select"
                            isOptionEqualToValue={(option, value) => option.code === value.code}
                            label={t(I18N_NAMESPACE, roadworksFiltersConfig[TECHNIQUES_FILTER].label)}
                            limitTags={2}
                            name={TECHNIQUES_FILTER}
                            options={techniques}
                            popupIcon={<SearchIcon />}
                            setFieldValue={setFieldValue} />
                    }
                </Styled.InputsWrapper >
                <Styled.ButtonsWrapper>
                    <Styled.Button
                        id="reset-filters-button"
                        color="error"
                        onClick={() => {
                            const resettedFilters = {
                                ...filters,
                                ...(enabledFilters.includes(YEARS_FILTER) && { years: defaultFormValues.years }),
                                ...(enabledFilters.includes(STATUSES_FILTER) && { statuses: defaultFormValues.statuses }),
                                ...(enabledFilters.includes(TECHNIQUES_FILTER) && { techniques: defaultFormValues.techniques }),
                            }
                            applyFilters(resettedFilters)
                            resetForm({ values: resettedFilters })
                            close()
                        }}
                        variant="text"
                    >
                        {isMobile ? t(I18N_NAMESPACE, "forms.techniquesFiltersForm.buttons.delete%small")
                            : t(I18N_NAMESPACE, "forms.techniquesFiltersForm.buttons.delete")}
                    </Styled.Button>
                    <Styled.Wrapper>
                        <Styled.Button
                            id="cancel-filters-button"
                            color="cta-bg/cta-bg-primary"
                            onClick={() => {
                                resetForm({ values: { ...initialValues } })
                                close()
                            }}
                            variant="text"
                        >
                            {t(I18N_NAMESPACE, "forms.techniquesFiltersForm.buttons.cancel")}
                        </Styled.Button>
                        <Styled.SubmitButton
                            id="submit-filters-button"
                            color="cta-bg/cta-bg-primary"
                            type="submit"
                            variant="contained"
                        >
                            {t(I18N_NAMESPACE, "forms.techniquesFiltersForm.buttons.confirm")}
                        </Styled.SubmitButton>
                    </Styled.Wrapper>
                </Styled.ButtonsWrapper>
            </Styled.FormWrapper >
        </Form >}
    </Formik >
}

TechniquesFiltersForm.propTypes = {
    enabledFilters: PropTypes.arrayOf(PropTypes.oneOf(FILTERS)).isRequired,
    className: PropTypes.string,
    close: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    years: PropTypes.arrayOf(PropTypes.number.isRequired),
}
