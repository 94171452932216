import { PropTypes } from "@l2r-front/l2r-proptypes"

import { useLoginMutation } from "../../hooks/mutations/auth/useLoginMutation"

import { LoginForm } from "./LoginForm.form"


export const LoginFormPost = (props) => {
    const {
        className,
        linkToRecoverPassword,
        onSuccess,
    } = props

    const { mutateAsync: login } = useLoginMutation({
        onSuccess,
    })

    return (
        <LoginForm
            className={className}
            linkToRecoverPassword={linkToRecoverPassword}
            onSubmit={login}
        />
    )
}

LoginFormPost.propTypes = {
    className: PropTypes.string,
    linkToRecoverPassword: PropTypes.node,
    onSuccess: PropTypes.func,
}