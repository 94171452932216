import { useMemo } from "react"

import { useMatch } from "react-router-dom"

import { MapLegend } from "@l2r-front/l2r-map"

import { useHorizontalSigningLegend } from "../../../modules/horizontalSigning/hooks/useHorizontalSigningLegend"
import { useRoadwayLegend } from "../../../modules/roadway/hooks/useRoadwayLegend"
import { useRoadworksLegend } from "../../../modules/roadworks/hooks/useRoadworksLegend"
import { APP_ROAD } from "../../constants/appRoad"
import { MODULE_ROADWAY, MODULE_ROADWORKS, MODULE_HORIZONTAL_SIGNING } from "../../constants/modules"


export function RoadMapLegend() {

    const matchRoadway = useMatch(`:slug/${APP_ROAD}/${MODULE_ROADWAY}/*`)
    const matchRoadworks = useMatch(`:slug/${APP_ROAD}/${MODULE_ROADWORKS}/*`)
    const matchHorizontalSigning = useMatch(`:slug/${APP_ROAD}/${MODULE_HORIZONTAL_SIGNING}/*`)

    const roadwayLegend = useRoadwayLegend()

    const roadworksLegend = useRoadworksLegend()

    const horizontalSigningLegend = useHorizontalSigningLegend()

    const displayedLegend = useMemo(() => {
        if (matchRoadway) {
            return roadwayLegend
        }

        if (matchRoadworks) {
            return roadworksLegend
        }

        if (matchHorizontalSigning) {
            return horizontalSigningLegend
        }

        return null

    }, [matchRoadway, matchRoadworks, roadwayLegend, roadworksLegend, matchHorizontalSigning, horizontalSigningLegend])

    if (displayedLegend) {
        return <MapLegend displayedLegends={displayedLegend} />
    }

    return []
}