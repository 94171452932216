import { useCallback, useState } from "react"

import { useNavigate, useParams } from "react-router-dom"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { Typography, useIsMobileDevice } from "@l2r-front/l2r-ui"
import { useUrlParams } from "@l2r-front/l2r-utils"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { VerticalSignsSidebarComponents } from "../../components/VerticalSignsSidebarComponents"
import { useGetVerticalSignTypeCatalogUtils } from "../../hooks/utils/useGetVerticalSignTypeCatalogUtils"

import * as Styled from "./VerticalSigningMobileContent.styled"

export const VerticalSigningMobileContent = () => {
    const { getVerticalSignTypeDepth, getVerticalSignTypeChildren } = useGetVerticalSignTypeCatalogUtils()
    const navigate = useNavigate()
    const { getParam } = useUrlParams()
    const { t } = useTranslation(I18N_NAMESPACE)
    const { road } = useParams()
    const [displayFilters, setDisplayFilters] = useState(false)
    const isMobile = useIsMobileDevice()
    const closeFilters = () => setDisplayFilters(false)

    const handleChangeFilter = useCallback((filters) => {
        const filterCodeDepth = getVerticalSignTypeDepth(filters.type)
        const children = getVerticalSignTypeChildren(filters.type)
        if (filterCodeDepth % 2 === 1 && children.length > 1) {
            const condition = getParam("verticalSigningCondition")
            navigate(`./code/${filters.type}?verticalSigningType=${filters.type}${condition ? `&verticalSigningCondition=${condition}` : ""}`)
        }
    }, [getParam, getVerticalSignTypeDepth, getVerticalSignTypeChildren, navigate])

    const toggleFilter = () => setDisplayFilters(displayFilters => !displayFilters)

    return (
        <>
            {isMobile && <Styled.VerticalSignFilters
                close={closeFilters}
                displayFilters={displayFilters}
            />}
            <Styled.BackdropFilter
                id="backdrop-filters"
                onClick={toggleFilter}
                open={displayFilters}
            />
            <Styled.Container>
                <Typography variant="H1">
                    {t(I18N_NAMESPACE, `containers.verticalSigningSidebar.${road ? "roadTitle" : "title"}`)}
                </Typography>
                <VerticalSignsSidebarComponents handleChangeTypeFilter={handleChangeFilter} toggleFilter={toggleFilter} />
            </Styled.Container>
        </>
    )
}