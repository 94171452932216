import { useGeoServerQuery } from "@l2r-front/l2r-geodata"

import { useRoadQuery } from "../../../../../common/hooks/queries/useRoadQuery"

import { networkGradingsQueryKeys } from "./queryKeys"
import { useWeightedAverageGrades } from "./useWeightedAverageGrades"

export function useFeaturesYearGrading(year, road, absStart, absEnd, config = {}) {
    const roadUrl = "network_gradings/"

    const roadQueryKey = [...networkGradingsQueryKeys["list"](year)]

    const { data: roadData, isLoading: isRoadLoading, isError: isRoadError } = useRoadQuery(
        roadUrl,
        roadQueryKey,
        { year },
        config,
    )

    const layer = roadData?.[0]?.["layer"]
    const roadParams = {
        linear_location_road: road,
        linear_location_start: absStart,
        linear_location_end: absEnd,
    }

    const featureParameters = { layer, ...roadParams }
    const featureQueryKey = [...networkGradingsQueryKeys["list"](featureParameters)]
    const featureUrl = "features/"
    const { data: featuresData, isLoading: isFeatureLoading, isError: isFeatureError } = useGeoServerQuery(
        featureUrl,
        featureQueryKey,
        featureParameters,
        {
            ...config,
            enabled: !!layer,
            geometry__isnull: false,
        })

    const averageGrades = useWeightedAverageGrades(featuresData)

    return ({
        data: averageGrades,
        isLoading: (isRoadLoading || isFeatureLoading),
        isError: (isRoadError || isFeatureError),
    })
}