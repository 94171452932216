import { PropTypes } from "@l2r-front/l2r-proptypes"

import {
    LIGHT_CONDITION_LIST,
    EVENT_TYPE_LIST,
} from "../constants/event"

import { report } from "./report"

export const incident = PropTypes.shape({
    box: PropTypes.string.isRequired,
    comment: PropTypes.string,
    date: PropTypes.string.isRequired,
    identifier: PropTypes.number.isRequired,
    isPublished: PropTypes.bool.isRequired,
    lightCondition: PropTypes.oneOf(LIGHT_CONDITION_LIST).isRequired,
    locationPoint: PropTypes.geoJsonPoint,
    reason_not_published: PropTypes.string,
    report: report.isRequired,
    triggerMain: PropTypes.shape({
        identifier: PropTypes.number.isRequired,
        isMain: PropTypes.bool.isRequired,
        timeStart: PropTypes.string.isRequired,
        timeEnd: PropTypes.string.isRequired,
        pointStart: PropTypes.geoJsonPoint.isRequired,
        pointEnd: PropTypes.geoJsonPoint.isRequired,
        ruleGpsName: PropTypes.string,
        ruleDynamicName: PropTypes.string,
    }).isRequired,
    sourceFile: PropTypes.string.isRequired,
    tags: PropTypes.arrayOf(PropTypes.string),
    timeSlot: PropTypes.arrayOf(PropTypes.string).isRequired,
    type: PropTypes.oneOf(EVENT_TYPE_LIST).isRequired,
    uuid: PropTypes.string.isRequired,
    videos: PropTypes.arrayOf(PropTypes.string).isRequired,
})
