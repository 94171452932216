import {
    useCallback,
    useState,
    useMemo,
    useEffect,
} from "react"

import { useEffectOnce } from "react-use"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { useMapDispatchContext } from "@l2r-front/l2r-map"
import { PAGE_LIMIT_DEFAULT } from "@l2r-front/l2r-query"
import { List, Typography, useTheme, useMediaQuery } from "@l2r-front/l2r-ui"
import { useNavigateWithSearchParams, useUrlParams } from "@l2r-front/l2r-utils"


import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { useSvraiContext } from "../../../../common/contexts/SvraiContext"
import { IncidentListItemSkeleton } from "../../components/IncidentListItem"
import { EVENT_TYPE_INCIDENT } from "../../constants/event"
import { PAGE_SEARCH_PARAM_PREFIX } from "../../constants/filters"
import { usePaginatedEventsQuery } from "../../hooks/queries/events/useEvents"

import { IncidentListItem } from "../IncidentListItem"

import * as Styled from "./IncidentPaginatedList.styled"


export const IncidentPaginatedList = (props) => {

    const { className } = props

    const [state, dispatch] = useSvraiContext()
    const { setLastIncidentPage } = dispatch
    const { lastIncidentPage } = state
    const { storeMapBoundingBox } = useMapDispatchContext()
    const [listRef, setListRef] = useState()
    const theme = useTheme()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"))
    const { getParam, setParam } = useUrlParams()
    const page = useMemo(() => {
        return parseInt(getParam(PAGE_SEARCH_PARAM_PREFIX)) || 1
    }, [getParam])

    const { data, isLoading, isError } = usePaginatedEventsQuery(
        page,
        { type: EVENT_TYPE_INCIDENT },
    )
    const navigate = useNavigateWithSearchParams()
    const { t } = useTranslation([I18N_NAMESPACE])

    useEffectOnce(() => {
        if (lastIncidentPage) {
            setParam(PAGE_SEARCH_PARAM_PREFIX, lastIncidentPage)
        }
    }, [setParam, lastIncidentPage])

    useEffect(() => {
        listRef?.scrollTo(0, 0)
    }, [listRef, data])

    const countIncidents = useMemo(() => {
        return data?.count
    }, [data])

    const isInitialLoading = useMemo(() => {
        return isLoading && countIncidents === undefined
    }, [isLoading, countIncidents])

    const countPages = useMemo(() => {
        if (!countIncidents === undefined) {
            return undefined
        }
        return Math.ceil(countIncidents / PAGE_LIMIT_DEFAULT)
    }, [countIncidents])

    const countItemsInCurrentPage = useMemo(() => {
        if (!countIncidents) {
            return 0
        }

        const countRemainingItems = countIncidents - (page - 1) * PAGE_LIMIT_DEFAULT
        return Math.min(PAGE_LIMIT_DEFAULT, countRemainingItems)
    }, [page, countIncidents])

    const handleChangePage = useCallback(
        (_, newPage) => {
            setLastIncidentPage(newPage)
        }, [setLastIncidentPage],
    )

    const handleIncidentListItemClick = useCallback((eventId) => {
        storeMapBoundingBox()
        navigate(eventId)
    }, [navigate, storeMapBoundingBox])

    if (isInitialLoading) {
        return (
            <Styled.ListWrapper>
                <Styled.List className={className} isMobile={isSmallScreen}>
                    <IncidentListItemSkeleton />
                </Styled.List>
            </Styled.ListWrapper>
        )
    }

    if (isError) {
        return (
            <Styled.ErrorContainer className={className} >
                <Typography variant="Small">
                    {t(I18N_NAMESPACE, "containers.incidentPaginatedList.error")}
                </Typography>
            </Styled.ErrorContainer>
        )
    }

    if (data?.count === 0) {
        return (
            <Styled.ErrorContainer className={className} >
                <Typography variant="Small">
                    {t(I18N_NAMESPACE, "containers.incidentPaginatedList.noData")}
                </Typography>
            </Styled.ErrorContainer>
        )
    }

    return (
        <Styled.ListWrapper>
            <Styled.List className={className} ref={setListRef} id="incidents-list" isMobile={isSmallScreen}>
                {isLoading && [...Array(countItemsInCurrentPage)].map(
                    (_, index) => {
                        return <IncidentListItemSkeleton key={index} />
                    },
                )}
                {data?.results.map(
                    (event, index) => {
                        return <IncidentListItem
                            key={event.uuid}
                            id={`incident-list-item-${index}`}
                            uuid={event.uuid}
                            onClick={() => handleIncidentListItemClick(event.uuid)}
                        />
                    },
                )}
            </Styled.List>
            <Styled.Pagination
                color="cta-bg/cta-bg-primary"
                count={countPages}
                id="incidents-list-pagination"
                page={page}
                onChange={handleChangePage}
                size={isSmallScreen ? "small" : "large"}
            />
        </Styled.ListWrapper>
    )
}

IncidentPaginatedList.propTypes = List.propTypes