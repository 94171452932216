import { useRoadQuery } from "../../../../../common/hooks/queries/useRoadQuery"

import { networkGradingsQueryKeys } from "./queryKeys"

export function useRelevantNetworkGradings(project, queryParameters, config = {}) {
    const url = "network_gradings/"

    const parameters = {
        ...queryParameters,
        project: project,
    }
    const { enabled = true } = config
    const queryKey = networkGradingsQueryKeys["listRelevants"](project)

    const { isLoading, isError, data, error } = useRoadQuery(
        url,
        queryKey,
        parameters,
        {
            ...config,
            enabled: !!project && enabled,
        },
    )

    const sortedRelevantNetworkGradings = data?.sort((ngA, ngB) => {
        return ngA.year - ngB.year
    })

    return { isLoading, isError, sortedRelevantNetworkGradings, error }
}