import { useCallback, useEffect, useMemo, useState } from "react"

import { useLocation, useMatch, useNavigate } from "react-router-dom"
import { useLocalStorage } from "react-use"

import { useMapDispatchContext } from "@l2r-front/l2r-map"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { useUrlParams } from "@l2r-front/l2r-utils"

import { NETWORK_ACCESS_AUTHORIZED, NETWORK_ACCESS_NOT_AUTHORIZED, forbiddenNetworkSlugs } from "../../constants/networks"
import { useNetworksQuery } from "../../hooks"

import { NetworksDispatchContext, NetworksStateContext, initialStateContext } from "./NetworksContext.context"

export const NetworksContextProvider = (props) => {
    const { children } = props

    const [networksState, setNetworksState] = useState(initialStateContext)

    const { resetMapBoundingBox } = useMapDispatchContext()
    const { getParam } = useUrlParams()
    const projectCache = getParam("projectCache")
    const navigate = useNavigate()
    const location = useLocation()

    const { data: networks, isLoading, isError } = useNetworksQuery({ projectCache: projectCache })
    const [lastConsultedNetwork, setLastConsultedNetwork] = useLocalStorage("lastConsultedNetwork", null)

    const match = useMatch("/:networkSlug/*")
    const matchNetworkSlug = useMemo(() => {
        return match?.params.networkSlug
    }, [match])

    const setSelectedNetworkSlug = useCallback((networkSlug) => {
        setNetworksState(value => ({
            ...value,
            selectedNetworkSlug: networkSlug,
        }))
    }, [setNetworksState])

    const setNetworkAccessStatus = useCallback((accessStatus) => {
        setNetworksState(value => ({
            ...value,
            networkAccessStatus: accessStatus,
        }))
    }, [setNetworksState])

    const setSelectedLinearLocations = useCallback((segment) => {
        const linearLocations = (segment?.properties?.linearLocation && JSON.parse(segment.properties.linearLocation)
            || segment?.linear_locations || null)
        setNetworksState(value => ({
            ...value,
            selectedLinearLocations: linearLocations,
        }))
    }, [setNetworksState])

    const setRoadSearched = useCallback((road) => {
        setNetworksState(value => ({
            ...value,
            roadSearched: road,
        }))
    }, [setNetworksState])

    const swapToNetwork = useCallback((newNetworkSlug, options = {}) => {
        navigate(options.redirect ?? `/${newNetworkSlug}`)
    }, [navigate])

    useEffect(() => {
        const networkSlugs = networks?.map(p => p.slug) || []

        if (networkSlugs.includes(matchNetworkSlug)) {
            setSelectedNetworkSlug(matchNetworkSlug)
            setLastConsultedNetwork(matchNetworkSlug)
            resetMapBoundingBox(true)
            setRoadSearched(null)
        }
    }, [matchNetworkSlug,
        networks,
        resetMapBoundingBox,
        setLastConsultedNetwork,
        setSelectedNetworkSlug,
        setRoadSearched])

    useEffect(() => {
        if (isLoading || isError || !networks || !!matchNetworkSlug && matchNetworkSlug === networksState.selectedNetworkSlug) {
            return
        } else {
            const networkSlugs = networks.map(p => p.slug)

            if (matchNetworkSlug && !forbiddenNetworkSlugs.includes(matchNetworkSlug)) {
                if (networkSlugs.includes(matchNetworkSlug)) {
                    swapToNetwork(matchNetworkSlug, { redirect: location })
                    return
                }
                setNetworkAccessStatus(NETWORK_ACCESS_NOT_AUTHORIZED)
                return
            }

            setNetworkAccessStatus(NETWORK_ACCESS_AUTHORIZED)
            if (!networkSlugs.length || forbiddenNetworkSlugs.includes(matchNetworkSlug)) {
                return
            }
            if (networkSlugs.includes(lastConsultedNetwork)) {
                swapToNetwork(lastConsultedNetwork)
                return
            }
            swapToNetwork(networks[0].slug)
        }
    }, [isLoading,
        isError,
        networks,
        matchNetworkSlug,
        lastConsultedNetwork,
        location,
        networksState.selectedNetworkSlug,
        setNetworkAccessStatus,
        swapToNetwork,
    ])

    const selectedNetwork = useMemo(() => {
        if (!networks || networksState.networkAccessStatus === NETWORK_ACCESS_NOT_AUTHORIZED) {
            return null
        }
        return networks.find(network => network.slug === networksState.selectedNetworkSlug)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [networks, networksState.selectedNetworkSlug])


    const dispatchValue = useMemo(() => {
        return {
            setSelectedNetworkSlug,
            setSelectedLinearLocations,
            setRoadSearched,
            swapToNetwork: swapToNetwork,
        }
    }, [setSelectedNetworkSlug, swapToNetwork, setSelectedLinearLocations, setRoadSearched])

    const stateValue = useMemo(() => {
        return {
            isError,
            isLoading,
            networks,
            selectedNetwork,
            selectedLinearLocations: networksState.selectedLinearLocations,
            networkAccessStatus: networksState.networkAccessStatus,
            roadSearched: networksState.roadSearched,
        }
    }, [isLoading,
        isError,
        networks,
        selectedNetwork,
        networksState.selectedLinearLocations,
        networksState.networkAccessStatus,
        networksState.roadSearched,
    ])

    return (
        <NetworksStateContext.Provider value={stateValue}>
            <NetworksDispatchContext.Provider value={dispatchValue}>
                {children}
            </NetworksDispatchContext.Provider>
        </NetworksStateContext.Provider>
    )
}

NetworksContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
}