import { useCallback, useRef } from "react"

import { Button } from "@mui/material"

import { PropTypes } from "@l2r-front/l2r-proptypes"

import { useIsMobileDevice } from "../../hooks/useIsMobileDevice"

import * as Styled from "./ImageField.styled"

export const ImageField = (props) => {
    const {
        alt,
        buttonLabel,
        className,
        error,
        filePreview,
        handleChange,
        helperText,
        imageSource,
        ...fileFieldProps
    } = props

    const isMobile = useIsMobileDevice()
    const fileInputRef = useRef()
    const onClick = useCallback(() => {
        return fileInputRef?.current?.click()
    }, [])
    return (
        <>
            <Styled.Wrapper className={className}>
                <Styled.Avatar
                    alt={alt}
                    src={(filePreview ?? imageSource) || undefined}
                    onClick={onClick}
                    isMobile={isMobile}
                    {...fileFieldProps}
                />

                <Button variant="contained" color={isMobile ? "objects/object-lightgrey" : "surfaces/surface-primary"} onClick={onClick}>
                    <Styled.EditIcon />
                    {buttonLabel}
                </Button>
                <Styled.HiddenInput {...fileFieldProps} type="file" accept="image/*" onChange={handleChange} ref={fileInputRef} />
            </Styled.Wrapper>
            {
                error && helperText && <Styled.FormHelperText error>{helperText}</Styled.FormHelperText>
            }
        </>
    )
}

ImageField.propTypes = {
    alt: PropTypes.string.isRequired,
    buttonColor: PropTypes.string,
    buttonLabel: PropTypes.string.isRequired,
    className: PropTypes.string,
    filePreview: PropTypes.string,
    handleChange: PropTypes.func.isRequired,
    imageSource: PropTypes.string,
}