import { useTranslation } from "@l2r-front/l2r-i18n"

import { I18N_NAMESPACE } from "../../constants/i18n"

import * as Styled from "./PhotoViewer.styled"

export const PhotoViewerError = () => {

    const { t } = useTranslation(I18N_NAMESPACE)

    return <Styled.ErrorContainer id="photo-error-message">
        <Styled.ErrorMessage variant="Small">
            {t(I18N_NAMESPACE, "containers.photoViewer.error")}
        </Styled.ErrorMessage>
    </Styled.ErrorContainer>
}