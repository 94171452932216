import { useMemo } from "react"

import { GEOJSONLINE, GEOJSONPOINT } from "../../constants/geometryIds"
import { useRoadwayStateContext } from "../../contexts/RoadwayContext"
import { useDamagesStats } from "../../hooks/queries/damages/useDamagesStats"
import { useNetworkConditionForYearlyStatus } from "../../hooks/queries/networkConditions/useNetworkConditionForYearlyStatus"

import { DamagesList } from "../DamagesList"

export function NetworkDamagesList() {
    const {
        selectedNetworkYearlyStatus,
    } = useRoadwayStateContext()

    const {
        isLoading: isLoadingNetworkCondition,
        isError: isErrorNetworkCondition,
        networkCondition,
    } = useNetworkConditionForYearlyStatus(selectedNetworkYearlyStatus)
    const damagesTypes = networkCondition?.damageTypes

    const {
        data: damagesStats,
        isLoading: isLoadingDamagesStat,
        isError: isErrorDamagesStat,
    } = useDamagesStats(
        { network_condition: networkCondition?.uuid },
        { enabled: !!networkCondition?.uuid },
    )

    const currentNetworkDamagesStats = useMemo(() => {
        if (!!damagesStats && !isErrorDamagesStat && !isLoadingDamagesStat) {
            return damagesTypes.map((damageType) => {
                return getDamageStat(damageType, damagesStats)
            })
        }
        return null
    }, [damagesTypes, damagesStats, isLoadingDamagesStat, isErrorDamagesStat])


    return <DamagesList
        damagesToDisplay={currentNetworkDamagesStats}
        isLoading={isLoadingNetworkCondition || (networkCondition && isLoadingDamagesStat)}
        isError={isErrorNetworkCondition || isErrorDamagesStat}
        noDamageTextKey="containers.roadwaySidebar.damages.noDamageForAudit" />
}

function getDamageStat(damageType, damagesStats) {

    const isPonctualStat = damageType.geometryTypeid === GEOJSONPOINT
    const isLinearStat = damageType.geometryTypeid === GEOJSONLINE
    const damageCode = damageType.code

    if (isPonctualStat) {
        const damageStat = Object.values(damagesStats.count[damageCode] || {}).reduce((acc, statCount) => acc + statCount, 0)
        return ({ damageCode, damageStat })
    } else if (isLinearStat) {
        const damageStat = Object.values(damagesStats.length[damageCode] || {}).reduce((acc, statCount) => acc + statCount, 0)
        return ({ damageCode, damageStat })
    }
    return null
}
