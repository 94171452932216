import { useTranslation } from "@l2r-front/l2r-i18n"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"

import * as Styled from "./IncidentDetailsSidebarContent.styled"

export function IncidentDetailsSidebarContentError() {
    const { t } = useTranslation([I18N_NAMESPACE])

    return <Styled.ErrorWrapper>
        <Styled.Title variant="Small">
            {t(I18N_NAMESPACE, "containers.incidentDetailsSidebar.errors.title")}
        </Styled.Title>
    </Styled.ErrorWrapper>
} 