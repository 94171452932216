import { useMemo } from "react"

import { useGeoServerRetrieveFeature } from "@l2r-front/l2r-geodata"
import { Typography } from "@l2r-front/l2r-ui"
import { formatMetersToDistance } from "@l2r-front/l2r-utils"

import { roadworksQueryKeys } from "../../hooks/queries/queryKeys"
import { computeRoadworkLength } from "../../utils/roadworkUtils"

import * as Style from "./RoadworkSectionLength.styled"

export const RoadworkSectionLength = (props) => {

    const { roadworkUuid } = props

    const { data: roadworkFeature, isLoading, isError } = useGeoServerRetrieveFeature(roadworkUuid, roadworksQueryKeys.section(roadworkUuid), { as_geojson: true })

    const roadLength = useMemo(() => {
        if (!roadworkFeature?.linear_location?.length) {
            return "-"
        }

        const lengthInMeters = computeRoadworkLength(roadworkFeature.linear_location)

        return formatMetersToDistance(lengthInMeters)
    }, [roadworkFeature])

    if (isError) {
        return null
    }

    if (isLoading) {
        return <Style.Skeleton />
    }

    return (
        <Typography id="road-detail-length" variant="Regular">{roadLength}</Typography>
    )
}