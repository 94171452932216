import { useCallback } from "react"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { TextField, TimePicker } from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"

import * as Styled from "./TimeSlotFilter.styled"

export const TimeSlotFilter = (props) => {
    const {
        startTimeValue,
        endTimeValue,
        onStartTimeValueChange,
        onEndTimeValueChange,
    } = props

    const { t } = useTranslation([I18N_NAMESPACE])


    const renderInput = useCallback((params) => {
        return <TextField {...params} />
    }, [])

    const shouldEnableTime = useCallback((timeValue, clockType) => {
        if (clockType === "hours" && timeValue % 3) {
            return true
        }

        return false
    }, [])

    return (
        <Styled.FiltersContainer>
            <TimePicker
                ampm={false}
                openTo="hours"
                views={["hours"]}
                inputFormat="HH:mm"
                mask="__:__"
                name="timeSlotStartHour"
                label={t(I18N_NAMESPACE, "components.timeSlotFilter.startHourLabel")}
                value={startTimeValue}
                onChange={onStartTimeValueChange}
                shouldDisableTime={shouldEnableTime}
                renderInput={renderInput}
                inputProps={{ readOnly: true }}
            />
            <TimePicker
                ampm={false}
                openTo="hours"
                views={["hours"]}
                inputFormat="HH:mm"
                mask="__:__"
                name="timeSlotEndHour"
                label={t(I18N_NAMESPACE, "components.timeSlotFilter.endHourLabel")}
                value={endTimeValue}
                onChange={onEndTimeValueChange}
                shouldDisableTime={shouldEnableTime}
                renderInput={renderInput}
                inputProps={{ readOnly: true }}
            />
        </Styled.FiltersContainer>
    )
}

TimeSlotFilter.propTypes = {
    startTimeValue: PropTypes.date.isRequired,
    endTimeValue: PropTypes.date.isRequired,
    onStartTimeValueChange: PropTypes.func.isRequired,
    onEndTimeValueChange: PropTypes.func.isRequired,
}