export const MINIMAL_IMPACT = 10
export const MINOR_IMPACT = 20
export const SERIOUS_IMPACT = 30
export const MAJOR_IMPACT = 40

export const otherTaskCodePrefix = "ZZZ"

export const impacts = {
    [MINIMAL_IMPACT]: {
        acronyms: "containers.networkRoadwork.legend.impacts.acronyms.localized",
        backgroundColor: "types-of-works/L",
        otherTaskCode: otherTaskCodePrefix + MINIMAL_IMPACT,
        text: "containers.networkRoadwork.legend.impacts.texts.localized",
    },
    [MINOR_IMPACT]: {
        acronyms: "containers.networkRoadwork.legend.impacts.acronyms.fullWidth",
        backgroundColor: "types-of-works/PL",
        otherTaskCode: otherTaskCodePrefix + MINOR_IMPACT,
        text: "containers.networkRoadwork.legend.impacts.texts.fullWidth",
    },
    [SERIOUS_IMPACT]: {
        acronyms: "containers.networkRoadwork.legend.impacts.acronyms.fullWidthMilling",
        backgroundColor: "types-of-works/PLR",
        otherTaskCode: otherTaskCodePrefix + SERIOUS_IMPACT,
        text: "containers.networkRoadwork.legend.impacts.texts.fullWidthMilling",
    },
    [MAJOR_IMPACT]: {
        acronyms: "containers.networkRoadwork.legend.impacts.acronyms.fullWidthStructural",
        backgroundColor: "types-of-works/PLS",
        otherTaskCode: otherTaskCodePrefix + MAJOR_IMPACT,
        text: "containers.networkRoadwork.legend.impacts.texts.fullWidthStructural",
    },
}

export const impactsOrder = {
    [MINIMAL_IMPACT]: 4,
    [MINOR_IMPACT]: 1,
    [MAJOR_IMPACT]: 2,
    [SERIOUS_IMPACT]: 3,
}
