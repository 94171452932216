import { styled } from "@l2r-front/l2r-ui"

import {
    YearGradingBadgeError as L2RYearGradingBadgeError,
    YearGradingBadgeSkeleton as L2RYearGradingBadgeSkeleton,
} from "../../components/YearGradingBadge"

import { NetworkYearGrading as L2RNetworkYearGrading } from "../NetworkYearGrading"


export const Wrapper = styled("div")({
    display: "flex",
})

export const YearGradingBadgeSkeleton = styled(L2RYearGradingBadgeSkeleton)(({ theme }) => ({
    marginRight: theme.spacing(3),
}))

export const YearGradingBadgeError = styled(L2RYearGradingBadgeError)(({ theme }) => ({
    marginRight: theme.spacing(3),
}))

export const NetworkYearGrading = styled(L2RNetworkYearGrading)(({ theme }) => ({
    marginRight: theme.spacing(3),
}))