import { useIsMobileDevice } from "@l2r-front/l2r-ui"

import { ChangePasswordFormPost } from "../../forms/ChangePasswordForm"

import * as Styled from "./AccountSettingsPasswordPage.styled"

export const AccountSettingsPasswordPage = () => {
    const isMobile = useIsMobileDevice()

    return (
        <Styled.Content isMobile={isMobile}>
            <ChangePasswordFormPost />
        </Styled.Content>
    )
}