import React, { useCallback, useMemo } from "react"

import { useLocation, useMatch, useNavigate } from "react-router-dom"
import urlJoin from "url-join"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { ViewModuleRoundedIcon } from "@l2r-front/l2r-icons"
import { useMapDispatchContext } from "@l2r-front/l2r-map"
import { useNetworksStateContext } from "@l2r-front/l2r-networks"
import { Select } from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../constants/i18n"
import { useGetActiveModules } from "../../hooks/useGetActiveModules"

import * as Styled from "./ModuleSelector.styled"

export const ModuleSelector = (props) => {

    const { t } = useTranslation(I18N_NAMESPACE)
    const { selectedNetwork } = useNetworksStateContext()
    const { storeMapBoundingBox } = useMapDispatchContext()
    const navigate = useNavigate()
    const match = useMatch("/:selectedNetwork/:app/:module/*")
    const selectedApp = match?.params?.app
    const selectedModule = match?.params?.module
    const appsWithActiveModules = useGetActiveModules()
    const location = useLocation()

    const selectedValue = useMemo(() => {
        if (!(selectedApp && selectedModule)) {
            return "/"
        }
        return [`/${selectedApp}`, selectedModule].join("/")
    }, [selectedApp, selectedModule])

    const modules = useMemo(() => {
        const sortedApps = Object.values(appsWithActiveModules).sort((a, b) => a.index - b.index)
        return sortedApps.map(app => {
            const sortedModules = Object.values(app.modules).sort((a, b) => a.index - b.index)
            const appModules = sortedModules.map(module => {
                const modulePath = urlJoin("/", app.path ?? "", module.path)
                const Icon = module.icon
                return <Styled.MenuItem key={module.label} value={modulePath} id={`module-${module.id}`}>
                    {module.icon ? <Styled.IconWrapper>
                        <Icon color={app.color} />
                    </Styled.IconWrapper> : <Styled.ColorIcon color={app.color} />}
                    <Styled.OptionText>
                        {t(app.i18nNamespace, module.label)}
                    </Styled.OptionText>
                </Styled.MenuItem>
            })

            return [
                app.label && <Styled.ListSubheader variant="Regular">{t(app.i18nNamespace, app.label)}</Styled.ListSubheader>,
                appModules,
            ]
        })
    }, [t, appsWithActiveModules])

    const renderFunction = useCallback((values) => {
        return values?.[1]
    }, [])

    const title = useMemo(() => {
        const selectedAppLabel = appsWithActiveModules?.[selectedApp]?.label
        const selectedI18nNamespace = appsWithActiveModules?.[selectedApp]?.i18nNamespace
        return selectedAppLabel ? t(selectedI18nNamespace, selectedAppLabel) : ""
    }, [selectedApp, t, appsWithActiveModules])

    const changeSelectedModule = useCallback((e) => {
        const previousPath = match?.params?.["*"].length ? match?.params?.["*"] : ""
        storeMapBoundingBox()
        return navigate(`/${selectedNetwork.slug}${e?.target?.value}/${previousPath}${location?.search ? location.search : ""}`)
    }, [location, match, navigate, selectedNetwork, storeMapBoundingBox])


    return <Styled.Select
        title={title}
        value={selectedValue}
        icon={ViewModuleRoundedIcon}
        onChange={changeSelectedModule}
        renderFunction={renderFunction}
        {...props}
    >
        {modules}
    </Styled.Select>
}

ModuleSelector.propTypes = Select.propTypes
