import { useCallback } from "react"

import { useHorizontalSignTypes } from "../queries/horizontalSigning/useHorizontalSignTypes"

function findCodeIndex(code, catalog, depth) {
    const codeIndex = catalog.findIndex(item => item.code === code)

    if (codeIndex >= 0) {
        return depth
    } else {
        for (let item of catalog) {
            const codeDepth = findCodeIndex(code, item.children, depth + 1)
            if (codeDepth >= 0) {
                return codeDepth
            }
        }

        return -1
    }
}

export function findHorizontalSignTypeByCode(code, catalog) {
    const horizontalSignType = catalog.find(item => item.code === code)

    if (horizontalSignType) {
        return horizontalSignType
    } else {
        for (let item of catalog) {
            const type = findHorizontalSignTypeByCode(code, item.children)
            if (type) {
                return type
            }
        }

        return null
    }
}

export function getFinalHorizontalSignTypes(catalog) {
    
    const getLeafTypes = (types) => {
        let leafTypes = []

        types.forEach(type => {
            if (!type.children?.length) {
                leafTypes.push(type)
            } else {
                leafTypes = leafTypes.concat(getLeafTypes(type.children))
            }
        })

        return leafTypes
    }

    return getLeafTypes(catalog)
}


export function useGetHorizontalSignTypeCatalogUtils() {
    const { data: horizontalSignTypes } = useHorizontalSignTypes()

    const getHorizontalSignTypeDepth = useCallback((code) => {
        if (!horizontalSignTypes) {
            return -1
        }

        return findCodeIndex(code, horizontalSignTypes, 0)
    }, [horizontalSignTypes])

    const getHorizontalSignTypeChildren = useCallback((code) => {
        if (!horizontalSignTypes) {
            return []
        }

        const horizontalSignType = findHorizontalSignTypeByCode(code, horizontalSignTypes)
        return horizontalSignType ? horizontalSignType.children : []
    }, [horizontalSignTypes])

    return {
        getHorizontalSignTypeDepth,
        getHorizontalSignTypeChildren,
    }
}