import { useMemo } from "react"

import { Formik } from "formik"
import { useNavigate } from "react-router-dom"

import { useAuthenticationDispatchContext } from "@l2r-front/l2r-auth"
import { useTranslation } from "@l2r-front/l2r-i18n"
import { CTAButton } from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../constants/i18n"

import * as Styled from "./CguForm.styled"

export const CguForm = (props) => {

    const {
        className,
        initialValues,
        post,
    } = props

    const { t } = useTranslation([I18N_NAMESPACE])
    const navigate = useNavigate()
    const { logout } = useAuthenticationDispatchContext()

    const formikProps = useMemo(() => {
        return {
            initialValues: initialValues || { hasTermsAccepted: false },
            onSubmit: async (values, actions) => {
                await post(values)
                actions.setSubmitting(false)
            },
        }
    }, [initialValues, post])

    return (
        <Formik {...formikProps}>
            {(formikState) => (
                <Styled.Form className={className}>
                    {formikState.status && (
                        <Styled.FormHelperText error id="submit-error">{formikState.status}</Styled.FormHelperText>
                    )}
                    <Styled.FormikCheckbox
                        label={t(I18N_NAMESPACE, "forms.cgu.fields.acceptCgu")}
                        type="checkbox"
                        name="hasTermsAccepted"
                    />
                    <Styled.SubmitWrapper>
                        <CTAButton variant="outlined" onClick={() => {
                            logout()
                            navigate("/login")
                        }}>
                            {t(I18N_NAMESPACE, "forms.cgu.actions.return")}
                        </CTAButton>
                        <Styled.FormikButtonSubmit disabled={!formikState?.values?.hasTermsAccepted}>
                            {t(I18N_NAMESPACE, "forms.cgu.actions.continue")}
                        </Styled.FormikButtonSubmit>
                    </Styled.SubmitWrapper>
                </Styled.Form>
            )}
        </Formik>
    )
}