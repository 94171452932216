import { PAGE_LIMIT_DEFAULT } from "@l2r-front/l2r-query"

import { useSvraiDispatchContext } from "../../../../../common/contexts/SvraiContext"
import { useSvraiQuery } from "../../../../../common/hooks/queries/useSvraiQuery"

import { eventsQueryKeys } from "./queryKeys"

export function useGeojsonEventsQuery(queryParameters, config = {}) {
    const url = "events/"
    const { getFiltersQueryParams } = useSvraiDispatchContext()
    const parameters = {
        ...queryParameters,
        ...getFiltersQueryParams(),
        as_geojson: true,
    }
    const { enabled = true } = config
    const queryKey = eventsQueryKeys["geoJSON"](parameters)
    return useSvraiQuery(
        url,
        queryKey,
        parameters,
        {
            config,
            enabled: !!parameters.date_from && !!parameters.date_to && enabled,
        },
    )
}

export function usePaginatedEventsQuery(
    page = 1,
    queryParameters,
    config = {},
) {
    const url = "events/"
    const offset = (page - 1) * PAGE_LIMIT_DEFAULT
    const { getFiltersQueryParams } = useSvraiDispatchContext()
    const filtersParams = { ...getFiltersQueryParams() }
    const parameters = { ...queryParameters, ...filtersParams, offset, limit: PAGE_LIMIT_DEFAULT }
    const queryKey = eventsQueryKeys["paginatedList"](parameters, page)
    const { enabled = true } = config

    return useSvraiQuery(
        url,
        queryKey,
        parameters,
        {
            config,
            enabled: !!parameters.date_from && !!parameters.date_to && enabled,
        },
    )
}
