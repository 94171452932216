import { useCallback } from "react"

import { useNavigate } from "react-router-dom"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { useNetworksDispatchContext } from "@l2r-front/l2r-networks"
import { SidebarContent } from "@l2r-front/l2r-ui"
import { useUrlParams } from "@l2r-front/l2r-utils"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { NETWORK_ASSESMENT_TYPE_URL_PARAM, NETWORK_GRADING_URL_PARAM } from "../../../../common/constants/urlParams"
import { useRoadwayDispatchContext } from "../../contexts/RoadwayContext"

import { RoadwayDetailSidebarContent } from "../RoadwayDetailSidebarContent"

export function RoadwayDetailSidebar() {

    const { t } = useTranslation([I18N_NAMESPACE])
    const { getParam } = useUrlParams()
    const navigate = useNavigate()
    const { setSelectedSegment } = useRoadwayDispatchContext()
    const { setRoadSearched } = useNetworksDispatchContext()

    const navigateBack = useCallback(() => {
        const networkGrading = getParam(NETWORK_GRADING_URL_PARAM)
        const currentNetworkAssesmentType = getParam(NETWORK_ASSESMENT_TYPE_URL_PARAM)

        setSelectedSegment(null)
        setRoadSearched(null)
        navigate(`..?${NETWORK_GRADING_URL_PARAM}=${networkGrading}&${NETWORK_ASSESMENT_TYPE_URL_PARAM}=${currentNetworkAssesmentType}`)
    }, [getParam, navigate, setSelectedSegment, setRoadSearched])

    return (
        <SidebarContent
            id="roadwayDetail-sidebar"
            title={t(I18N_NAMESPACE, "containers.roadwayDetailSidebar.title")}
            onBack={navigateBack}
        >
            <RoadwayDetailSidebarContent />
        </SidebarContent>

    )
}
