import { GpsNotFixedIcon, IncidentIcon, SpeedRoundedIcon } from "@l2r-front/l2r-icons"
import { useHandleBack } from "@l2r-front/l2r-utils"

import { L2rAppSvrai } from "../../app"

import { ProjectContextProvider } from "../contexts/ProjectContext"
import { getModulesBreadcrumb } from "../utils/getModulesBreadcrumb"

import { I18N_NAMESPACE } from "./i18n"
import { MODULE_GPS_ZONES, MODULE_INCIDENTS, MODULE_SPEED } from "./modules"

export const APP_SAFETY = "safety"


export const appSafety = {
    index: 3,
    color: "cta-bg/cta-bg-primary",
    component: <L2rAppSvrai />,
    desktopCustomLayoutRoutes: [`/${APP_SAFETY}/${MODULE_INCIDENTS}/:eventId/analysis`],
    externalContexts: <ProjectContextProvider />,
    i18nNamespace: I18N_NAMESPACE,
    label: "app.label",
    modules: {
        incidents: {
            index: 0,
            icon: IncidentIcon,
            id: "INCIDENTS",
            label: "app.modules.incidents",
            path: MODULE_INCIDENTS,
            useHandleBack: () => useHandleBack(getModulesBreadcrumb),
        },
        gpsZones: {
            index: 1,
            icon: GpsNotFixedIcon,
            id: "GPS_ZONES",
            label: "app.modules.gpsZones",
            path: MODULE_GPS_ZONES,
        },
        speed: {
            index: 2,
            icon: SpeedRoundedIcon,
            id: "SPEED",
            label: "app.modules.speed",
            path: MODULE_SPEED,
        },
    },
    path: APP_SAFETY,
}