import { useNetworksStateContext } from "@l2r-front/l2r-networks"

import { useRoadworksQuery } from "../../../../common/hooks/queries/useRoadworksQuery"

import { roadworksQueryKeys } from "./queryKeys"

export function useRoadworksProject(
    queryParameters,
    config = {},
) {
    const { enabled = true } = config
    const { selectedNetwork } = useNetworksStateContext()
    const url = `projects/${selectedNetwork?.slug}/`
    const queryKey = roadworksQueryKeys["project"](queryParameters)

    return useRoadworksQuery(
        url,
        queryKey,
        queryParameters,
        {
            ...config,
            enabled: !!selectedNetwork?.slug && enabled,
        },
    )
}
