import {useCallback, useMemo, useRef, useState} from "react"

import { Form, Formik } from "formik"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { Typography, useIsMobileDevice } from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { usePasswordFormModal } from "../../hooks/usePasswordFormModal"

import { validationSchema } from "./ChangePasswordForm.schema"
import * as Styled from "./ChangePasswordForm.styled"

export const ChangePasswordForm = (props) => {

    const {
        autoFocus,
        className,
        onSubmit,
    } = props

    const isMobile = useIsMobileDevice()
    const { t } = useTranslation(I18N_NAMESPACE)
    const [errors, setErrors] = useState(null)

    const onError = useCallback((errors) => {
        const errorsArray = Object.keys(errors).reduce((accArray, key) => {
            return accArray.concat(errors[key])
        }, [])
        setErrors(errorsArray.join("\n"))
    }, [setErrors])

    const formikRef = useRef(null)

    const onSubmitPasswordAuthorisation = async (values) => {
        await onSubmit(values)
        formikRef.current?.resetForm()
    }

    const { modal, setFormValues } = usePasswordFormModal(
        onSubmitPasswordAuthorisation,
        onError,
        t(I18N_NAMESPACE, "forms.changeEmailForm.modal.title"),
        t(I18N_NAMESPACE, "forms.changeEmailForm.modal.desc"),
    )

    const formikProps = useMemo(() => {
        return {
            validationSchema: validationSchema(t),
            initialValues: {
                confirmNewPassword: "",
                newPassword: "",
            },
            onSubmit: (values, { setStatus, setSubmitting }) => {
                setStatus(null)
                setErrors(null)
                setFormValues(values)
                setSubmitting(false)
            },
        }
    }, [setFormValues, t])

    return <Styled.Wrapper isMobile={isMobile}>
        <Typography variant="H2">{t(I18N_NAMESPACE, "forms.changePasswordForm.title")}</Typography>
        <Styled.PasswordConstraintsDesc variant="Regular">{t(I18N_NAMESPACE, "forms.recoverPasswordDoneForm.fields.password.helper")}</Styled.PasswordConstraintsDesc>
        <Formik innerRef={formikRef} {...formikProps}>
            {(formikState) => {
                return <Form className={className}>
                    {(formikState.status || errors) && (
                        <Styled.FormHelperText error>{formikState.status || errors}</Styled.FormHelperText>
                    )}
                    <Styled.FormikTextField
                        autoFocus={!!autoFocus}
                        fullWidth
                        variant="outlined"
                        label={t(I18N_NAMESPACE, "forms.changePasswordForm.fields.newPassword.label")}
                        labelVariant="H3"
                        margin="normal"
                        name="newPassword"
                        type="password"
                    />
                    <Styled.FormikTextField
                        fullWidth
                        variant="outlined"
                        label={t(I18N_NAMESPACE, "forms.changePasswordForm.fields.confirmNewPassword.label")}
                        labelVariant="H3"
                        margin="normal"
                        name="confirmNewPassword"
                        type="password"
                    />
                    <Styled.SubmitWrapper isMobile={isMobile}>
                        <Styled.FormikButtonSubmit isMobile={isMobile}>
                            {t(I18N_NAMESPACE, "forms.changeEmailForm.actions.submit")}
                        </Styled.FormikButtonSubmit>
                    </Styled.SubmitWrapper>
                </Form>
            }}
        </Formik>
        {modal}
    </Styled.Wrapper>
}

ChangePasswordForm.propTypes = {
    className: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
}
