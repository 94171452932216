import { useMemo } from "react"

import { useRoutes, Navigate } from "react-router-dom"

import { DetailSegmentSelectListener, SegmentSelectListener } from "@l2r-front/l2r-map-utils"
import { MapAdapterBoundingBox, MapAdapterRoad } from "@l2r-front/l2r-networks"
import { useUrlParams, NavigateWithParams } from "@l2r-front/l2r-utils"

import { HorizontalSignSelectListener } from "../../containers/HorizontalSignSelectListener"

import { HorizontalSignValidator } from "../HorizontalSignValidator/HorizontalSignValidator"

export const HorizontalSigningDesktopRoutes = () => {
    const { getParams } = useUrlParams()

    const horizontalSigningRoutes = useMemo(() => {
        const searchParams = getParams()
        return [
            {
                path: "/",
                element: <>
                    <MapAdapterBoundingBox />
                    <SegmentSelectListener />
                </>,
            },
            {
                path: "/:road",
                element: <>
                    <MapAdapterRoad />
                    <DetailSegmentSelectListener />
                </>,
            },
            {
                path: "/:road/:signId",
                element: <HorizontalSignValidator>
                    <HorizontalSignSelectListener />
                </HorizontalSignValidator>,
            },
            {
                path: "/code/*",
                element: <Navigate replace to={`../?${searchParams}`} />,
            },
            {
                path: "/:road/code/*",
                element: <NavigateWithParams replace to={`../:road?${searchParams}`} />,
            },
        ]
    }, [getParams])

    return useRoutes(horizontalSigningRoutes)
}