import { Trans, useGetLocaleFromList } from "@l2r-front/l2r-i18n"

import { cguLocales, getCguUrl } from "../../constants/cgu"
import { I18N_NAMESPACE } from "../../constants/i18n"

import * as Styled from "./LegalFooter.styled"

export function LegalFooter(props) {

    const { className } = props

    const currentLang = useGetLocaleFromList(cguLocales, cguLocales[0])

    return (
        <Styled.Container className={className}>
            <Trans namespace={I18N_NAMESPACE} i18nKey="components.legalFooter.label">
                "Logiroad - 2023 Copyright - <a target="_blank" rel="noreferrer" href={getCguUrl(currentLang)}>Toto</a>"
            </Trans>
        </Styled.Container>
    )
}