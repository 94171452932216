import { useCallback, useMemo } from "react"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { useAlertsDispatchContext } from "@l2r-front/l2r-query"
import { CTAButton, StepIcon, Typography, useIsMobileDevice } from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { useReportContext } from "../../contexts/ReportContext"

import { ReportStep } from "../ReportStep"

import * as Styled from "./Report.styled"


export const Report = () => {

    const { t } = useTranslation(I18N_NAMESPACE)
    const [state, dispatch] = useReportContext()
    const { setAlert, setSnackbar } = useAlertsDispatchContext()

    const {
        onNextStep,
        onPreviousStep,
        setCurrentStepIndex,
        toggleDisplayInput,
        updateReportStep,
    } = dispatch

    const {
        currentStepIndex,
        displayInput,
        error,
        event,
        formValues,
        reportStepsConfig,
        currentStepData,
    } = state

    const toggleKey = reportStepsConfig?.[currentStepIndex]?.toggle?.toggleKey
    const isMobile = useIsMobileDevice()
    const configDefaultToggleState = reportStepsConfig?.[currentStepIndex]?.toggle?.defaultToggleState
    const stepConfig = reportStepsConfig[currentStepIndex]
    const nextStepTranslation = `components.reportStep.buttons.nextStep${isMobile ? "%small" : ""}`
    const previousStepTranslation = `components.reportStep.buttons.previousStep${isMobile ? "%small" : ""}`

    const stepsThatWillBeClosed = useMemo(() => {
        const toggleState = currentStepData?.values?.[toggleKey]
        const toggleIsChecked = (toggleState ?? configDefaultToggleState)
        const toggleConfigWhenActive = reportStepsConfig[currentStepIndex]?.toggle?.active
        const toggleWillCloseReport = toggleIsChecked ? toggleConfigWhenActive === "end"
            : toggleConfigWhenActive === "next"

        if (toggleWillCloseReport) {
            return reportStepsConfig.map((_, index) => {
                return index > currentStepIndex
            })
        }
        return reportStepsConfig.map(() => {
            return false
        })
    }, [configDefaultToggleState, currentStepIndex, reportStepsConfig, currentStepData, toggleKey])

    const stepIcon = useCallback((index, stepIsClosed) => {

        const lastValidatedStepIndex = event?.report?.reportSteps.length - 1
        const isCurrentStepValidated = index < lastValidatedStepIndex || stepIsClosed && lastValidatedStepIndex === index
        const isActive = index === currentStepIndex
        const closedStep = stepIsClosed && !isActive
        const willBeClosedStep = stepsThatWillBeClosed?.[index]
        const completed = index < currentStepIndex

        const icon = (isCurrentStepValidated || completed) ? "checkIcon"
            : (closedStep || willBeClosedStep) ? "cancelIcon"
                : "numberIcon"

        return <StepIcon
            active={isActive}
            color="cta-bg/cta-bg-primary"
            disabled={index > event?.report?.reportSteps.length - 1}
            stepNumber={index + 1}
            icon={icon}
            onClick={() => setCurrentStepIndex(index)}
        />
    }, [currentStepIndex, event, setCurrentStepIndex, stepsThatWillBeClosed])

    const stepButtonClicked = useCallback(async (index) => {
        if (displayInput && formValues?.[stepConfig.type] !== currentStepData?.values?.content) {
            await updateReportStep({
                values: {
                    ...(toggleKey && { [toggleKey]: currentStepData?.values?.[toggleKey] ?? configDefaultToggleState }),
                    content: formValues?.[reportStepsConfig[currentStepIndex].type],
                },
                isDraft: currentStepData?.identifier === event?.report?.reportSteps.length,
            })
        }
        toggleDisplayInput(false)
        setCurrentStepIndex(index)
    }, [event, configDefaultToggleState, currentStepIndex, displayInput, formValues, reportStepsConfig, setCurrentStepIndex, stepConfig, currentStepData, toggleDisplayInput, toggleKey, updateReportStep])

    const isEndingAnalysis = useMemo(() => {
        const isLastConfigStep = currentStepIndex === (reportStepsConfig.length - 1)
        const stepConfig = reportStepsConfig[currentStepIndex]

        if (isLastConfigStep) {
            return true
        }

        const toggleValueActive = currentStepData?.values?.[toggleKey] ?? configDefaultToggleState

        return toggleValueActive ? stepConfig?.toggle?.active === "end"
            : stepConfig?.toggle?.active === "next"

    }, [currentStepData, currentStepIndex, configDefaultToggleState, reportStepsConfig, toggleKey])

    const isLastStep = useMemo(() => currentStepIndex === event?.report?.reportSteps?.length - 1,
        [currentStepIndex, event],
    )

    const nextButtonLabel = useMemo(() => {
        return event?.report?.isClosed && isLastStep ? isMobile ? t(I18N_NAMESPACE, "components.reportStep.buttons.finish")
            : null
            : !isEndingAnalysis ? t(I18N_NAMESPACE, nextStepTranslation)
                : t(I18N_NAMESPACE, "components.reportStep.buttons.finish")
    }, [event, isEndingAnalysis, nextStepTranslation, isLastStep, isMobile, t])

    const handleSubmit = useCallback(async (formikState) => {
        const stepConfig = reportStepsConfig[currentStepIndex]
        const isCurrentStep = (event?.report?.reportSteps.length - 1) === currentStepIndex
        await updateReportStep({
            values: {
                content: formikState?.[stepConfig.type] || "",
                ...(toggleKey && { [toggleKey]: currentStepData?.values?.[toggleKey] ?? configDefaultToggleState }),
            },
            ...(isCurrentStep && { isDraft: true }),
        })
    }, [currentStepData, currentStepIndex, configDefaultToggleState, event, reportStepsConfig, toggleKey, updateReportStep])

    const isCurrentStep = useMemo(() => {
        return (event?.report?.reportSteps.length - 1) === currentStepIndex
    }, [currentStepIndex, event])

    const handleComplete = useCallback(() => {
        setAlert({
            alertText: t(I18N_NAMESPACE, `containers.reportSteps.${stepConfig.type}.confirms.text`),
            callback: () => {
                updateReportStep({ isDraft: false })
                setSnackbar({
                    label: t(I18N_NAMESPACE, "containers.reportSteps.snackbar.save"),
                    severity: "success",
                    anchorOrigin: {
                        horizontal: "left",
                        vertical: "bottom",
                    },
                })
            },
            severity: "warning",
        })
    }, [setAlert, setSnackbar, stepConfig, t, updateReportStep])

    const handleNext = useCallback(async () => {
        const stepConfig = reportStepsConfig[currentStepIndex]
        const nextStepExists = (event?.report?.reportSteps.length - 1) >= currentStepIndex + 1

        if (displayInput || !nextStepExists) {
            await updateReportStep({
                values: {
                    content: formValues?.[stepConfig.type] || "",
                    ...(toggleKey && { [toggleKey]: currentStepData?.values?.[toggleKey] ?? configDefaultToggleState }),
                },
                isDraft: isEndingAnalysis,
            })
        }
        toggleDisplayInput(false)

        if (isEndingAnalysis) {
            handleComplete()
            return
        }
        onNextStep()
    }, [configDefaultToggleState, currentStepData, currentStepIndex, displayInput, event, formValues, handleComplete, isEndingAnalysis, onNextStep, reportStepsConfig, toggleDisplayInput, toggleKey, updateReportStep])

    const handleBack = async () => {
        if (displayInput) {
            const currentToggleDataValue = currentStepData?.values?.[toggleKey]
            const toggleNewValue = currentToggleDataValue ?? configDefaultToggleState

            if (formValues?.[stepConfig.type] !== currentStepData?.values?.content) {
                await updateReportStep({
                    values: {
                        content: formValues?.[stepConfig.type],
                        ...(toggleKey && { [toggleKey]: toggleNewValue }),
                    },
                    isDraft: currentStepData?.identifier === event?.report?.reportSteps.length,
                })
            }
        }
        toggleDisplayInput(false)
        onPreviousStep()
    }

    if (error) {
        return <Styled.ReportStepWrapper isMobile={isMobile}>
            <Styled.ErrorMessage variant="Small">
                {t(I18N_NAMESPACE, "containers.reportSteps.error")}
            </Styled.ErrorMessage>
        </Styled.ReportStepWrapper>
    }

    return <Styled.ReportStepWrapper isMobile={isMobile}>
        {
            isMobile && <Styled.MobileTitle>
                <Styled.Badge badgeContent={currentStepIndex + 1} color="cta-bg/cta-bg-primary" />
                <Typography variant="H4">{t(I18N_NAMESPACE, reportStepsConfig[currentStepIndex].title)}</Typography>
            </Styled.MobileTitle>
        }
        {!isMobile && <Styled.Stepper
            activeStep={currentStepIndex}
            alternativeLabel
            id="stepper"
        >
            {reportStepsConfig?.map((stepConfig, index) => {
                const reportIsClosed = event?.report?.isClosed
                const stepIsClosed = reportIsClosed && (index >= currentStepIndex)
                const isAfterReportLastStep = index > (event?.report?.reportSteps.length - 1)

                return (
                    <Styled.Step key={stepConfig.title} completed={index < currentStepIndex}>
                        <Styled.StepButton
                            disabled={isAfterReportLastStep}
                            icon={stepIcon(index, stepIsClosed)}
                            onClick={() => stepButtonClicked(index)}
                            pointer={isAfterReportLastStep ? "initial" : "pointer"}
                        >
                            <Styled.StepLabel
                                activestep={index === currentStepIndex ? 1 : 0}
                                color="inherit"
                                StepIconComponent={() => null}
                            >
                                {t(I18N_NAMESPACE, stepConfig.title)}
                            </Styled.StepLabel>
                        </Styled.StepButton>
                    </Styled.Step>

                )
            })}
        </Styled.Stepper>}
        <ReportStep
            displayToggle={!!(reportStepsConfig?.[currentStepIndex]?.toggle && isCurrentStep)}
            handleBack={handleBack}
            handleNext={handleNext}
            handleSubmit={handleSubmit}
            nextButtonLabel={nextButtonLabel}
            previousButtonDisabled={currentStepIndex === 0}
            stepConfig={reportStepsConfig[currentStepIndex]}
        />
        {
            isMobile && <Styled.MobileStepper
                id="mobile-stepper"
                variant="dots"
                steps={reportStepsConfig.length}
                position="static"
                activeStep={currentStepIndex}
                color="cta-bg/cta-bg-primary"
                sx={{ maxWidth: 400, flexGrow: 1 }}
                nextButton={
                    <CTAButton
                        id="next-button"
                        size="small"
                        onClick={handleNext}
                        disabled={!nextButtonLabel || event?.report?.isClosed && isLastStep}
                    >
                        <Typography variant="Regular">
                            {nextButtonLabel}
                        </Typography>
                    </CTAButton>
                }
                backButton={
                    <CTAButton
                        disabled={currentStepIndex === 0}
                        id="back-button"
                        onClick={handleBack}
                        size="small"
                    >
                        <Typography variant="Regular">
                            {t(I18N_NAMESPACE, previousStepTranslation)}
                        </Typography>
                    </CTAButton>
                }
            />
        }
    </Styled.ReportStepWrapper>
}
