import { PropTypes } from "@l2r-front/l2r-proptypes"
import { useTheme } from "@l2r-front/l2r-ui"

import { getDisplayedFigures } from "../../../../common/utils/getDisplayedFigures"
import { getLegendItemForGrade } from "../../constants/getNotationConfig"

import * as Styled from "./YearGradingBadge.styled"

export function YearGradingBadge(props) {

    const {
        className,
        id,
        figuresCount,
        grade,
        year,
    } = props

    const theme = useTheme()

    const gradeIsNull = !grade && grade !== 0
    const legendItemForGrade = getLegendItemForGrade(grade, theme)
    const backgroundColor = gradeIsNull ? theme.palette["objects/object-grey"].main : theme.palette[legendItemForGrade.color].main
    const textColor = gradeIsNull ? "black" : legendItemForGrade.textColor
    const displayedGrade = gradeIsNull ? "-" : getDisplayedFigures(grade, figuresCount)

    return <Styled.Wrapper
        backgroundColor={backgroundColor}
        className={className}
        id={id}
    >
        <Styled.Text
            color={textColor}
            variant="Small"
        >
            {year}
        </Styled.Text>
        <Styled.Text
            color={textColor}
            variant="H4"
        >
            {displayedGrade}
        </Styled.Text>
    </Styled.Wrapper>
}

YearGradingBadge.defaultProps = {
    figuresCount: 2,
}

YearGradingBadge.propTypes = {
    className: PropTypes.string,
    id: PropTypes.string,
    grade: PropTypes.number,
    year: PropTypes.number.isRequired,
}