import { useTranslation } from "@l2r-front/l2r-i18n"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { ListItem } from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { INCIDENT_STATUS_LIST, INCIDENT_TYPE_LIST } from "../../constants/event"

import { IncidentPublicationIcon } from "../IncidentPublicationIcon"
import { IncidentStatusIcon } from "../IncidentStatusIcon"
import { IncidentTypeIcon } from "../IncidentTypeIcon"

import * as Styled from "./IncidentListItem.styled"

export const IncidentListItem = (props) => {
    const {
        identifier,
        date,
        type,
        status,
        isPublished,
        reasonNotPublished,
        userIsStaff,
        onClick,
        ...listItemProps
    } = props

    const { t } = useTranslation([I18N_NAMESPACE])

    return (
        <Styled.ListItemButton {...listItemProps} onClick={onClick}>
            <Styled.ListItem>
                <Styled.ListItemHeader>
                    <Styled.TypeIcon>
                        <IncidentTypeIcon id="incident-type-pin" type={type} />
                    </Styled.TypeIcon>
                    <Styled.PrimaryTypography id="incident-identifier" variant="H4">
                        {t(I18N_NAMESPACE, "components.incidentListItem.primary") + identifier}
                    </Styled.PrimaryTypography>
                </Styled.ListItemHeader>
                <Styled.SecondaryTypography id="incident-date" variant="Small">
                    {date}
                </Styled.SecondaryTypography>
            </Styled.ListItem>
            <Styled.IconWrapper>
                <Styled.StatusIcon>
                    <IncidentStatusIcon id="incident-status-icon" status={status} />
                </Styled.StatusIcon>
                {userIsStaff && <Styled.StatusIcon>
                    <IncidentPublicationIcon
                        id="incident-publish-status-icon"
                        isPublished={isPublished}
                        reasonNotPublished={reasonNotPublished}
                    />
                </Styled.StatusIcon>
                }
            </Styled.IconWrapper>
        </Styled.ListItemButton>
    )
}

IncidentListItem.defaultProps = {
    isPublished: false,
}

IncidentListItem.propTypes = {
    ...ListItem.propTypes,
    identifier: PropTypes.number.isRequired,
    date: PropTypes.string.isRequired,
    type: PropTypes.oneOf(INCIDENT_TYPE_LIST).isRequired,
    status: PropTypes.oneOf(INCIDENT_STATUS_LIST).isRequired,
    isPublished: PropTypes.bool,
    reasonNotPublished: PropTypes.string,
    userIsStaff: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
}