import { useIsMobileDevice } from "@l2r-front/l2r-ui"

import { ProfileFormPost } from "../../forms/ProfileForm"

import * as Styled from "./AccountSettingsProfilePage.styled"

export const AccountSettingsProfilePage = () => {
    const isMobile = useIsMobileDevice()

    return (
        <Styled.Content isMobile={isMobile}>
            <ProfileFormPost />
        </Styled.Content>
    )
}