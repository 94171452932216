import { useCallback, useMemo } from "react"

import { useParams } from "react-router-dom"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { SidebarContent } from "@l2r-front/l2r-ui"
import { useNavigateWithSearchParams } from "@l2r-front/l2r-utils"


import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { useRoadworkWithTasks } from "../../hooks/queries/useRoadwork"

import { RoadworkSectionSidebarContent } from "../RoadworkSectionSidebarContent"

export function RoadworkSectionSidebar() {

    const { t } = useTranslation([I18N_NAMESPACE])
    const navigate = useNavigateWithSearchParams()
    const { road, roadworkUuid } = useParams()

    const navigateBack = useCallback(() => {
        navigate(`../${road}`)
    }, [navigate, road])

    const { data: roadwork } = useRoadworkWithTasks(roadworkUuid, road)

    const sidebarTitle = useMemo(() => {
        if (roadwork && !roadwork.tasks.length) {
            return t(I18N_NAMESPACE, "containers.roadworkSectionSidebar.titleCreate")
        }
        return t(I18N_NAMESPACE, "containers.roadworkSectionSidebar.title")
    }, [roadwork, t])

    return (
        <SidebarContent
            id="works-technique-sidebar"
            onBack={navigateBack}
            title={sidebarTitle}
        >
            <RoadworkSectionSidebarContent />
        </SidebarContent>
    )
}
