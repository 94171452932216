import { ArrowBackIcon } from "@l2r-front/l2r-icons"
import { PropTypes } from "@l2r-front/l2r-proptypes"

import { useIsMobileDevice } from "../../hooks/useIsMobileDevice"

import * as Styled from "./SidebarContent.styled"

export function SidebarContent(props) {

    const {
        id,
        title,
        titleSideComponent,
        onBack,
        children,
        className,
    } = props

    const isMobile = useIsMobileDevice()

    return (
        <Styled.Container className={className}>
            <Styled.TitleContainer isMobile={isMobile}>
                {onBack && !isMobile &&
                    <Styled.Button id={`${id}-return-button`} color="objects/object-black" onClick={onBack}>
                        <ArrowBackIcon color="objects/object-black" />
                    </Styled.Button>
                }
                <Styled.Title id={`${id}-title`} variant="H1">{title}</Styled.Title>
                {titleSideComponent &&
                    <Styled.TitleSideComponent>{titleSideComponent}</Styled.TitleSideComponent>
                }
            </Styled.TitleContainer>
            {children}
        </Styled.Container>
    )
}

SidebarContent.propTypes = {
    className: PropTypes.string,
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    tileSideComponent: PropTypes.element,
    onBack: PropTypes.func,
    children: PropTypes.node,
}