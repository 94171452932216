import { useToggle } from "react-use"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { PropTypes } from "@l2r-front/l2r-proptypes"

import { I18N_NAMESPACE } from "../../constants/i18n"

import { CTAButton } from "../CTAButton/CTAButton"

import * as Styled from "./TruncableText.styled"

export function TruncableText(props) {
    const {
        children,
        maxCharactersCount,
        ...otherProps
    } = props

    const [showMore, toggleShowMore] = useToggle(false)
    const needToShowMore = children.length > maxCharactersCount

    const { t } = useTranslation(I18N_NAMESPACE)

    return (
        <Styled.Container {...otherProps}>
            {showMore ? children : children.substring(0, maxCharactersCount)}
            {needToShowMore && <CTAButton variant="link" type="button" onClick={toggleShowMore}>
                {t(I18N_NAMESPACE, showMore ? "components.truncableText.seeLess" : "components.truncableText.seeMore")}
            </CTAButton>}
        </Styled.Container>
    )
}

TruncableText.propTypes = {
    maxCharactersCount: PropTypes.number,
}

TruncableText.defaultProps = {
    maxCharactersCount: 250,
}