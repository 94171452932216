import { useTranslation } from "@l2r-front/l2r-i18n"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"

import * as Styled from "./TechniquesFiltersForm.styled"


export function TechniquesFiltersFormError() {

    const { t } = useTranslation(I18N_NAMESPACE)

    return <Styled.Error>
        {t(I18N_NAMESPACE, "forms.techniquesFiltersForm.error")}
    </Styled.Error>
}