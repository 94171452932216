import { useMemo } from "react"

import { useMatch, useRoutes } from "react-router-dom"

import { useIsMobileDevice } from "@l2r-front/l2r-ui"

import { APP_ROAD } from "../../../../common/constants/appRoad"
import { MODULE_HORIZONTAL_SIGNING } from "../../../../common/constants/modules"
import { HorizontalSignDetailsSidebar } from "../../containers/HorizontalSignDetailsSidebar"
import { HorizontalSigningSidebar } from "../../containers/HorizontalSigningSidebar"
import { useHorizontalSigningStateContext } from "../../contexts/HorizontalSigningContext"

import { HorizontalSignValidator } from "../HorizontalSignValidator"

import * as Styled from "./HorizontalSigningSidebarRouter.styled"

export function HorizontalSigningSidebarRouter() {

    const { ascendantCodes, descendantCodes } = useHorizontalSigningStateContext()
    const isMobile = useIsMobileDevice()
    const matchHorizontalSigningDetail = useMatch(`:slugId/${APP_ROAD}/${MODULE_HORIZONTAL_SIGNING}/:road/:signId`)

    const sidebarRoutes = useMemo(() => ([
        { path: "/", element: <HorizontalSigningSidebar /> },
        { path: "/:road", element: <HorizontalSigningSidebar /> },
        { path: "/:road/:signId", element: <HorizontalSignValidator><HorizontalSignDetailsSidebar /></HorizontalSignValidator> },
    ]), [])

    const sidebarCount = useMemo(() => {
        const hasAdditionalSidebar = ascendantCodes.length % 2 === 1 || descendantCodes.length > 1
        return matchHorizontalSigningDetail ? 1 : Math.floor(ascendantCodes.length / 2) + (hasAdditionalSidebar ? 1 : 0)
    }, [ascendantCodes, descendantCodes, matchHorizontalSigningDetail])

    return <Styled.Sidebar shadowed={!isMobile && sidebarCount >= 2}>{useRoutes(sidebarRoutes)}</Styled.Sidebar>
}
