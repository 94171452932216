import { useCallback, useMemo } from "react"

import { useGeoServerRetrieveFeature, getFeatureCenterPoint } from "@l2r-front/l2r-geodata"

import { ImageTypeSwitch } from "../../components/ImageTypeSwitch"
import { IMAGE_COLLECTION_TYPE_PHOTO, IMAGE_COLLECTION_TYPE_PANO } from "../../constants/imageCollectionsTypes"
import { useImageCollectionStateContext, useImageCollectionDispatchContext } from "../../contexts/ImageCollectionContext"

import { PanoramicViewer } from "../PanoramicViewer/PanoramicViewer"
import { PhotoViewer } from "../PhotoViewer"
import { StreetView } from "../StreetView"

import * as Styled from "./ImageViewer.styled"

export const ImageViewer = () => {

    const { imageCollection, isLoadingImageCollections, triggeringFeatureId, selectedImageCollectionType } = useImageCollectionStateContext()
    const { setSelectedImageCollectionType, isImageCollectionAvailable } = useImageCollectionDispatchContext()

    const {
        data: triggeringFeature,
        isLoading: isLoadingTriggeringFeature,
    } = useGeoServerRetrieveFeature(triggeringFeatureId)

    const initialPosition = useMemo(() => {
        if (triggeringFeature) {
            const featureCenter = getFeatureCenterPoint(triggeringFeature)
            return featureCenter.geometry.coordinates
        }

        return null
    }, [triggeringFeature])

    const onTypeChange = useCallback((type) => {
        setSelectedImageCollectionType(type)
    }, [setSelectedImageCollectionType])

    return (
        <>
            <Styled.Container>
                {selectedImageCollectionType === IMAGE_COLLECTION_TYPE_PHOTO ?
                    <PhotoViewer initialPosition={initialPosition} date={imageCollection?.date} />
                    : selectedImageCollectionType === IMAGE_COLLECTION_TYPE_PANO ?
                        <PanoramicViewer initialPosition={initialPosition} date={imageCollection?.date} />
                        : <StreetView initialPosition={initialPosition} />
                }
                {isImageCollectionAvailable() && <Styled.TopContainer>
                    <ImageTypeSwitch
                        id="image-type-switch"
                        currentImageCollectionType={selectedImageCollectionType}
                        onChange={onTypeChange}
                        imageCollectionType={imageCollection.type}
                    />
                </Styled.TopContainer>
                }
            </Styled.Container>
            {(isLoadingImageCollections || isLoadingTriggeringFeature) &&
                <Styled.LoadingContainer>
                    <Styled.Skeleton />
                </Styled.LoadingContainer>}
        </>
    )
}