import { useMemo, useEffect, useState, useCallback } from "react"

import { useQueryClient } from "@tanstack/react-query"
import { useParams } from "react-router-dom"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { Typography } from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { eventsQueryKeys } from "../../hooks/queries/events/queryKeys"
import { useEventQuery } from "../../hooks/queries/events/useEvent"

import * as Styled from "./IncidentVideo.styled"

export const IncidentVideo = (props) => {

    const {
        className,
        height,
    } = props

    const [videoRef, setVideoRef] = useState(null)
    const { eventId } = useParams()

    const { data: incident, isLoading } = useEventQuery(eventId)
    const { t } = useTranslation(I18N_NAMESPACE)
    const queryClient = useQueryClient()

    const infoMessage = useMemo(() => {
        const message = isLoading ? t(I18N_NAMESPACE, "containers.incidentVideo.loading") : t(I18N_NAMESPACE, "containers.incidentVideo.error")
        return (
            <Styled.ErrorContainer>
                <Typography variant="Small">
                    {message}
                </Typography>
            </Styled.ErrorContainer>
        )
    }, [isLoading, t])

    const handleVideoLoadError = useCallback((e) => {
        e.stopPropagation()
        queryClient.invalidateQueries({ queryKey: eventsQueryKeys["list"]() })
        queryClient.invalidateQueries({ queryKey: eventsQueryKeys["details"]() })
        queryClient.refetchQueries({ queryKey: eventsQueryKeys["detail"](eventId) })
    }, [queryClient, eventId])

    const videoSourcesList = useMemo(() => {
        if (!incident?.videos || !incident?.videos.length || !videoRef) {
            return null
        }

        let sourcesList = []
        incident.videos.forEach(video => {
            const videoFormatRegex = /\.([0-9a-z]+)(?:[?#]|$)/i
            const videoFormatMatches = video.match(videoFormatRegex)
            if (!videoFormatMatches || videoFormatMatches.length < 2) {
                return
            }

            const videoFormat = videoFormatMatches[1]
            const videoType = `video/${videoFormat}`
            if (videoRef.canPlayType(videoType)) {
                sourcesList.push(<source key={video} src={video} type={videoType} onError={handleVideoLoadError} />)
            }
        })

        return sourcesList
    }, [handleVideoLoadError, incident, videoRef])

    const mustDisplayVideo = useMemo(() => {
        return videoSourcesList && videoSourcesList.length > 0
    }, [videoSourcesList])

    useEffect(() => {
        videoRef?.load()

    }, [videoRef])

    return (
        <Styled.Wrapper className={className} height={height} >
            <Styled.Video
                ref={setVideoRef}
                preload="auto"
                controls
                disablePictureInPicture
                controlsList="nodownload"
                style={{ display: mustDisplayVideo ? "block" : "none" }}
            >
                {videoSourcesList}
            </Styled.Video>
            {!mustDisplayVideo && infoMessage}
        </Styled.Wrapper>
    )
}