import { useTranslation } from "@l2r-front/l2r-i18n"
import { Typography } from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { MobileAccountSettingsMenuItem } from "../../components/MobileAccountSettingsMenuItem"

import * as Styled from "./MobileAccountSettingsHomePage.styled"

export const MobileAccountSettingsHomePage = () => {
    const { t } = useTranslation(I18N_NAMESPACE)

    return (
        <Styled.AccountSettingsCategory>
            <Typography variant="H4">{t(I18N_NAMESPACE, "pages.accountSettings.profile.label")}</Typography>
            <Styled.AccountSettingsList>
                <MobileAccountSettingsMenuItem
                    label={t(I18N_NAMESPACE, "pages.accountSettings.profile.accountInfoLabel")}
                    desc={t(I18N_NAMESPACE, "pages.accountSettings.profile.accountInfoDesc")}
                    to="profile"
                />
                <MobileAccountSettingsMenuItem
                    label={t(I18N_NAMESPACE, "pages.accountSettings.profile.accountEmailLabel")}
                    desc={t(I18N_NAMESPACE, "pages.accountSettings.profile.accountEmailDesc")}
                    to="email"
                />
                <MobileAccountSettingsMenuItem
                    label={t(I18N_NAMESPACE, "pages.accountSettings.profile.accountPasswordLabel")}
                    desc={t(I18N_NAMESPACE, "pages.accountSettings.profile.accountPasswordDesc")}
                    to="password"
                />
            </Styled.AccountSettingsList>
        </Styled.AccountSettingsCategory>
    )
}