import { useCallback, useMemo } from "react"

import { useParams } from "react-router-dom"

import { useMyUserContext } from "@l2r-front/l2r-auth"
import { useTranslation } from "@l2r-front/l2r-i18n"
import { SidebarContent } from "@l2r-front/l2r-ui"
import { useNavigateWithSearchParams } from "@l2r-front/l2r-utils"


import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { IncidentPublicationIcon } from "../../components/IncidentPublicationIcon"
import { useEventQuery } from "../../hooks/queries/events/useEvent"

import { IncidentDetailsSidebarContent } from "../IncidentDetailsSidebarContent"

import * as Styled from "./IncidentDetailsSidebar.styled"

export function IncidentDetailsSidebar(props) {

    const { eventId } = useParams()
    const { user } = useMyUserContext()
    const { data: incident } = useEventQuery(eventId)
    const { t } = useTranslation([I18N_NAMESPACE])
    const navigate = useNavigateWithSearchParams()

    const title = useMemo(() => {
        return t(I18N_NAMESPACE, "components.incidentDetails.title") + (incident ? ` ${incident.identifier}` : "")
    }, [incident, t])

    const publicationComponent = useMemo(() => {
        if (user && user.isStaff && incident) {
            return (
                <Styled.PublicationWrapper>
                    <IncidentPublicationIcon isPublished={incident.isPublished} reasonNotPublished={incident.reasonNotPublished} />
                </Styled.PublicationWrapper>
            )
        } else {
            return null
        }
    }, [user, incident])

    const navigateBack = useCallback(() => {
        return navigate("..")
    }, [navigate])

    return (
        <SidebarContent
            id="incident-details"
            title={title}
            titleSideComponent={publicationComponent}
            onBack={navigateBack}
        >
            <IncidentDetailsSidebarContent {...props} />
        </SidebarContent>

    )
}
