import { PropTypes } from "@l2r-front/l2r-proptypes"
import { formatMetersToDistance } from "@l2r-front/l2r-utils"

import { GEOJSONPOINT } from "../../constants/geometryIds"

import * as Styled from "./DamagesStat.styled"

export function DamagesStat(props) {

    const {
        damageType,
    } = props

    const isPonctualStat = damageType.geometryTypeid === GEOJSONPOINT
    const stat = useCorrectUnit(isPonctualStat, damageType.damageStat)

    return <Styled.Text id="damage-stat">{stat}</Styled.Text>
}

DamagesStat.propTypes = {
    damageType: PropTypes.shape({
        cause: PropTypes.string.isRequired,
        code: PropTypes.string.isRequired,
        damageStat: PropTypes.number,
        geometryTypeid: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        parent: PropTypes.string,
    }).isRequired,
}

function useCorrectUnit(isPonctualStat, value) {

    if (value === undefined || value === null) {
        return null
    } else if (isPonctualStat) {
        return value
    } else {
        return formatMetersToDistance(value)
    }
}