import { PropTypes } from "@l2r-front/l2r-proptypes"

import { IncidentFiltersFormPost } from "../../forms/IncidentFiltersForm/IncidentFiltersForm.post"

import * as Styled from "./IncidentFilters.styled"

export const IncidentFilters = (props) => {

    const {
        className,
        close,
        displayed,
    } = props

    return <div className={className}>
        <Styled.Collapse in={displayed}>
            <IncidentFiltersFormPost close={close} />
        </Styled.Collapse>
    </div>
}

IncidentFilters.propTypes = {
    className: PropTypes.string,
    close: PropTypes.func.isRequired,
    displayed: PropTypes.bool.isRequired,
}